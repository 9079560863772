import React, { useState } from "react";
//HOC CSS
import styles from "./styles";
// reach router
import { navigate } from "@reach/router";
//material ui
import { withStyles, Container, Typography,
  Grid, Button, TextField } from "@material-ui/core";

//firebase
import { auth } from "../../firebase";

function Signup(props) {
  const { classes } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [serverError, setServerError] = useState(false);

  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={6}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-email"
                  label="User Name (Email)"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className={classes.field}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  fullWidth
                  id="outlined-password"
                  label="Password"
                  type="password"
                  autoComplete="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  className={classes.field}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  fullWidth
                  id="outlined-passwordConfirmation"
                  label="Confirm Password"
                  type="password"
                  autoComplete="password"
                  value={passwordConfirmation}
                  onChange={e => setPasswordConfirmation(e.target.value)}
                  className={classes.field}
                  variant="filled"
                />
              </Grid>

              <Grid container spacing={5} direction="row" alignItems="center" justifyContent="flex-end">
                {serverError ? (
                  <Typography className={classes.errorText} component="h5" variant="h6">
                    Problem Signing Up
                  </Typography>
                ) : null}
                <Grid item>
                  <Button type="reset" variant="outlined" size="large" className={classes.button} href="/login">
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit" variant="outlined" size="large" className={classes.button} onClick={submitSignup}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );

  function formIsValid() {
    if (password === passwordConfirmation) {
      return true;
    }
  }

  async function submitSignup() {
    if (!formIsValid()) {
      setServerError("Passwords do not match");
      return;
    }

    await auth.createUserWithEmailAndPassword(email, password).then(
      () => {
        navigate("/dashboard");
        window.location.reload();
      },
      authErr => {
        console.log("Failed to create user: ", authErr);
        setServerError("Failed to add user");
      }
    );
  }
}
export default withStyles(styles)(Signup);
