import React, { useState, useContext, lazy, Suspense } from "react";

// reach router
import { navigate } from '@reach/router';

//HOC CSS
import styles from "./styles";

//material ui
import {
  withStyles, Container, CircularProgress, Typography,
  ImageList, ImageListItem, ListItem,
  ListItemText, Modal, Backdrop
} from "@material-ui/core";

import { VariableSizeGrid } from 'react-window';

//firebase
import { firestore } from "../../firebase";

import { ResultFieldSettingContext } from "../../contexts/resultFieldSettingProvider";
import { PLANITEM_DELIVERY_CONTEXT } from "../../contexts/planItem_delivery_Provider";
import { PLANITEM_TODO_CONTEXT } from "../../contexts/planItem_todo_Provider";
import { PLANITEM_N2A_CONTEXT } from "../../contexts/planItem_n2a_Provider";
import { PLANITEM_DELIVERY_CONTEXT_CHI } from "../../contexts/planItem_delivery_Provider_chi";
import { PLANITEM_TODO_CONTEXT_CHI } from "../../contexts/planItem_todo_Provider_chi";
import { PLANITEM_N2A_CONTEXT_CHI } from "../../contexts/planItem_n2a_Provider_chi";

const PlanItemExport = lazy(() => import('./planItem_export'));

const DisplayForm = lazy(() => import('../displayForm/displayForm'));

const panelHeight = 300;

function RoutingPanelItem(props) {
  const classes = props.classes;
  const { resultFieldSetting } = useContext(ResultFieldSettingContext);

  const containerList_all = props.planItems;

  const containerList = containerList_all !== undefined ? containerList_all.filter(function (obj) {
    let titleList = obj.shortDetail.split("-");
    let isCHI = titleList[0].indexOf("CHI") !== -1;
    return obj.invoiced !== true && (resultFieldSetting.view === "CHI" ? isCHI : !isCHI);
  }) : undefined;

  const handleClick = (routingContainerState, item, props) => {
    switch (routingContainerState) {
      case 't':
        fetchOrder('t', item.d_Ref, item.cIndex, props, item.shortDetail);
        break;
      case 'n':
      case 'd':
        fetchOrder(routingContainerState, item.d_Ref, item.cIndex, props, item.shortDetail);
        break;
      default:
        navigate("/operation/" + item.d_Ref);
    }
  }

  async function fetchOrder(routingContainerState, ref, itemIndex, props, shortDetail) {
    props.handleToggle();
    let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
    await firestore
      .collection("deliverOrders" + branchLabel)
      .doc(ref)
      .get()
      .then(deliveryOrderSnapshot => {
        if (deliveryOrderSnapshot.exists) {
          var deliverOrderDoc = deliveryOrderSnapshot.data();
          deliverOrderDoc.ref = ref;
          switch (routingContainerState) {
            case 't':
            case 'd':
            case 'n':
              props.handleClose();
              props.setFormType("editDeiveryOrder");
              props.setCollectionItem(deliverOrderDoc);
              props.setCollectionItemIndex(itemIndex);
              props.setCollectionItemType(routingContainerState);
              props.setCollectionItemShortDetail(shortDetail);
              props.setOpen(true);
              break;
            default:
              break;
          }
        }
        props.handleClose();
      })
      .catch(err => {
        console.log("Error getting deliverOrders", err);
        props.handleClose();
      });
  }

  function getRowHeight(index) {
    if (containerList[index].shortDetail === undefined) return 50;
    const linesize = 85;
    let size = containerList[index].shortDetail.length;
    let line = Math.floor(size / linesize) + ((size % linesize) > 0 ? 1 : 0);
    return line * 25;
  }

  function getDateString(t_date, isChi, needTime) {
    if (isValid(t_date)) {
      const dateString = t_date.toDate().toLocaleString("en-US", { timeZone: isChi ? "America/Chicago" : "America/New_York" });
      const dateItem = dateString.split(",");
      return needTime ? dateString : dateItem[0];
    }
    return "";
  }

  function getValeuString(value) {
    return (value === "" || value === " ") ? "NA" : value;
  }

  function isValid(value) {
    return value !== null && value !== undefined;
  }

  function RenderHeader(props) {
    const routingContainerState = props.routingContainerState;
    const isChi = resultFieldSetting.view === "CHI";
    return (
      <div style={{ maxWidth: props.panelWidth }}>
        {
          routingContainerState === "d" ? (
            <div >
              <ListItem component="div" dense={true}>
                <ListItemText component="div" secondary={"Description"} className={classes.listHeaderText} />
                <ListItemText component="div" secondary={"PickUp-Empty(Date)"} className={classes.listItemText1} />
                <ListItemText component="div" secondary={"Container Note"} className={classes.listItemText2} />
              </ListItem>
            </div>
          ) :
            routingContainerState === "t" ? (
              <div >
                <ListItem component="div" dense={true}>
                  <ListItemText component="div" secondary={"Description"} className={classes.listHeaderText} />
                  <ListItemText component="div" secondary={"LFD-ApptD-PickUpDriver"} className={classes.listItemText1} />
                  <ListItemText component="div" secondary={"Container Comment"} className={classes.listItemText2} />
                </ListItem>
              </div>
            ) :
              routingContainerState === "n" ? (
                <div >
                  <ListItem component="div" dense={true}>
                    <ListItemText component="div" secondary={"Description"} className={classes.listHeaderText} />
                    <ListItemText component="div" secondary={"LastFreeDay"} className={classes.listItemText1} />
                    <ListItemText component="div" secondary={"Appointment Date"} className={classes.listItemText1} />
                  </ListItem>
                </div>
              ) :
                (routingContainerState === "e" && isChi) ? (
                  <div >
                    <ListItem component="div" dense={true}>
                      <ListItemText component="div" secondary={"Description"} className={classes.listHeaderText} />
                      <ListItemText component="div" secondary={"PickUp Number"} className={classes.listItemText2} />
                    </ListItem>
                  </div>
                ) :
                  <ListItem component="div" dense={true}>
                    <ListItemText component="div" secondary={"Description"} />
                  </ListItem>

        }
      </div>
    );
  }

  function render(props) {
    const { rowIndex, index, data, style } = props;
    const parentProps = data.props;
    const itemIndex = index === undefined ? rowIndex : index;
    const routingContainerState = parentProps.routingContainerState;
    data.containerList[itemIndex].shortDetail = data.containerList[itemIndex].shortDetail.replace("OWNER-", "OWNER_");
    const itemInfo = data.containerList[itemIndex].shortDetail.split("-");
    const yardIndex_OWN = itemInfo.indexOf("OWN");
    const yardIndex_CUSTOMER = itemInfo.indexOf("CUSTOMER");
    const isChi = resultFieldSetting.view === "CHI";
    //containerNote for empty panel
    const containerNote = (yardIndex_OWN !== -1 || yardIndex_CUSTOMER !== -1) ? itemInfo[itemInfo.length - 1] : "NA(need to Resave)";
    const containerComment = isValid(data.containerList[itemIndex].containerComment) ? data.containerList[itemIndex].containerComment : "NA(need to Resave)";
    const containerColTodo = (data.containerList[itemIndex].lastFreeDay ? getDateString(data.containerList[itemIndex].lastFreeDay, isChi) : "NA")
      + "-" + (data.containerList[itemIndex].apptDate ? getDateString(data.containerList[itemIndex].apptDate, isChi) : "NA")
      + "-" + (isValid(data.containerList[itemIndex].pickupDriver) ? getValeuString(data.containerList[itemIndex].pickupDriver) : "NA(need to Resave)");
    const containerColEmpty = (data.containerList[itemIndex].pickUpDate ? getValeuString(getDateString(data.containerList[itemIndex].pickUpDate, isChi)) : "NA")
      + "-" + (isValid(data.containerList[itemIndex].emptyReadyDate) ? getValeuString(getDateString(data.containerList[itemIndex].emptyReadyDate, isChi, true)) : "NA");

    const containerColN2a = (data.containerList[itemIndex].apptDate ? getValeuString(getDateString(data.containerList[itemIndex].apptDate, isChi)) : "NA");
    const containerColLFD = (data.containerList[itemIndex].lastFreeDay ? getValeuString(getDateString(data.containerList[itemIndex].lastFreeDay, isChi)) : "NA");
    const containerColETA = (data.containerList[itemIndex].pickUpNumber ? getValeuString(data.containerList[itemIndex].pickUpNumber) : "NA");


    const containerShort = data.containerList[itemIndex].shortDetail.replace(("-" + containerNote), "");
    const crossOutToDo = data.containerList[itemIndex].toDoDone === true;
    const crossOutN2A = data.containerList[itemIndex].apptDone === true;
    const pickUpDateFilled = data.containerList[itemIndex].pickUpDate !== undefined && data.containerList[itemIndex].pickUpDate !== null && data.containerList[itemIndex].pickUpDate !== "";

    const n2a_done = data.containerList[itemIndex].apptDone === true;
    const n2a_req = data.containerList[itemIndex].apptReq === true;
    const hold = data.containerList[itemIndex].hold === true;
    const outSourceSystem = data.containerList[itemIndex].driver === "OUTSOURCE_SYSTEM";

    return (
      <div>
        {
          routingContainerState === "d" ? (
            <div>
              <ListItem button
                onClick={e => handleClick(routingContainerState, data.containerList[itemIndex], parentProps)}
                style={style}
                key={data.containerList[itemIndex].id}
                className={itemIndex % 2 === 0 ? classes.listItem : classes.listItem1}
              >
                <ListItemText primary={`${containerShort}`} className={classes.listItemText} />
                <ListItemText secondary={`${containerColEmpty}`} className={classes.listItemText1} />
                <ListItemText secondary={`${containerNote}`} className={classes.listItemText2} />
              </ListItem>
            </div>
          ) :
            routingContainerState === "t" ? (
              <div >
                <ListItem button
                  onClick={e => handleClick(routingContainerState, data.containerList[itemIndex], parentProps)}
                  style={style}
                  key={data.containerList[itemIndex].id}
                  className={itemIndex % 2 === 0 ? classes.listItem : classes.listItem1}
                >
                  <ListItemText primary={`${data.containerList[itemIndex].shortDetail}`}
                    className={(crossOutToDo ? classes.listItemText_crossOut : classes.listItemText) + " " +
                      (pickUpDateFilled ? classes.listItemText_B : "") + " " +
                      (outSourceSystem ? classes.listItemText_HIGHLIGHT :
                        (hold ? classes.listItemText_YELLOW :
                        (n2a_done ? classes.listItemText_BLUE :
                          (n2a_req ? classes.listItemText_GREEN : classes.listItemText_ORANGE))))
                    } />
                  <ListItemText secondary={`${containerColTodo}`} className={classes.listItemText1} />
                  <ListItemText secondary={`${containerComment}`} className={classes.listItemText2} />
                </ListItem>
              </div>
            ) :
              routingContainerState === "n" ? (
                <div >
                  <ListItem button
                    onClick={e => handleClick(routingContainerState, data.containerList[itemIndex], parentProps)}
                    style={style}
                    key={data.containerList[itemIndex].id}
                    className={itemIndex % 2 === 0 ? classes.listItem : classes.listItem1}
                  >
                    <ListItemText primary={`${data.containerList[itemIndex].shortDetail}`}
                      className={(crossOutN2A ? classes.listItemText_crossOut : classes.listItemText) + " " +
                        (pickUpDateFilled ? classes.listItemText_B : "") + " " +
                        (outSourceSystem ? classes.listItemText_HIGHLIGHT :
                          (hold ? classes.listItemText_YELLOW :
                          (n2a_done ? classes.listItemText_BLUE :
                            (n2a_req ? classes.listItemText_GREEN : classes.listItemText_ORANGE))))} />
                    <ListItemText secondary={`${containerColLFD}`} className={classes.listItemText1} />
                    <ListItemText secondary={`${containerColN2a}`} className={classes.listItemText1} />
                  </ListItem>
                </div>
              ) :
                (routingContainerState === "e" && isChi) ? (
                  <div >
                    <ListItem button
                      onClick={e => handleClick(routingContainerState, data.containerList[itemIndex], parentProps)}
                      style={style}
                      key={data.containerList[itemIndex].id}
                      className={itemIndex % 2 === 0 ? classes.listItem : classes.listItem1}
                    >
                      <ListItemText primary={`${data.containerList[itemIndex].shortDetail}`} className={classes.listItemText} />
                      <ListItemText secondary={`${containerColETA}`} className={classes.listItemText2} />
                    </ListItem>
                  </div>
                ) :
                  <ListItem button
                    onClick={e => handleClick(routingContainerState, data.containerList[itemIndex], parentProps)}
                    style={style}
                    key={data.containerList[itemIndex].id}
                    className={itemIndex % 2 === 0 ? classes.listItem : classes.listItem1}
                  >
                    <ListItemText primary={`${data.containerList[itemIndex].shortDetail}`} />
                  </ListItem>

        }
      </div>
    );
  }

  const panelWidth = window.innerWidth;
  return (
    <div className={classes.root}>
      <ImageList className={classes.gridList} cols={1} rowHeight={panelHeight}>
        <ImageListItem className={classes.gridTile2}>
          {<RenderHeader routingContainerState={props.routingContainerState} panelWidth={panelWidth} className={classes.panel} ></RenderHeader>}
          {
            containerList === undefined ?
              <CircularProgress></CircularProgress> :
              (containerList.length !== 0 ?
                <VariableSizeGrid
                  className={classes.panel}
                  height={panelHeight}
                  columnCount={1}
                  columnWidth={() => panelWidth}
                  rowHeight={getRowHeight}
                  rowCount={containerList.length}
                  itemData={{ containerList: containerList, props: props }}
                  width={panelWidth}
                  classes={classes}
                >
                  {render}
                </VariableSizeGrid> :
                <Typography className={classes.tableRowText} >EMPTY</Typography>)
          }
        </ImageListItem>
      </ImageList>
    </div>
  );
}

function PlanItem2(props) {
  // classes porps for styling
  const { classes } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const { resultFieldSetting } = useContext(ResultFieldSettingContext);

  const [open, setOpen] = React.useState(false);
  const [formType, setFormType] = useState();
  const [collectionItem, setCollectionItem] = useState();
  const [collectionItemIndex, setCollectionItemIndex] = useState();
  const [collectionItemType, setCollectionItemType] = useState();
  const [collectionItemShortDetail, setCollectionItemShortDetail] = useState();
  const routingContainerState = props.routingContainerState;

  let isCHI = resultFieldSetting.view === "CHI";
  const delivery_todo_planItems = useContext(isCHI ? PLANITEM_TODO_CONTEXT_CHI : PLANITEM_TODO_CONTEXT);
  const delivery_planItems = useContext(isCHI ? PLANITEM_DELIVERY_CONTEXT_CHI : PLANITEM_DELIVERY_CONTEXT);
  const n2a_planItems = useContext(isCHI ? PLANITEM_N2A_CONTEXT_CHI : PLANITEM_N2A_CONTEXT);

  const [openBusy, setOpenBusy] = React.useState(false);

  const handleClose = () => {
    setOpenBusy(false);
  };
  const handleToggle = () => {
    setOpenBusy(!openBusy);
  };


  const displayFormProps = {
    classes,
    formType,
    setFormType,
    setOpen,
    collectionItem,
    collectionItemIndex,
    collectionItemType,
    collectionItemShortDetail,
  };

  let planItems_TOP, planItems_BOTTOM;
  if (routingContainerState === "delivery") {
    planItems_TOP = delivery_planItems !== undefined ? delivery_planItems.filter(function (obj) {
      return obj.shortDetail.indexOf("-CUSTOMER-") === -1;
    }) : undefined;

    planItems_BOTTOM = delivery_planItems !== undefined ? delivery_planItems.filter(function (obj) {
      return obj.shortDetail.indexOf("-CUSTOMER-") !== -1;
    }).sort(
      (a, b) => {
        if (a.shortDetail.split("-")[1] < b.shortDetail.split("-")[1]) {
          return -1;
        }
        if (a.shortDetail.split("-")[1] > b.shortDetail.split("-")[1]) {
          return 1;
        }

        // names must be equal
        return 0;
      }
    ) : undefined;
  } else if (routingContainerState === "todo") {
    const now = new Date();
    const today = new Date(now.getFullYear() + "/" + (now.getMonth() + 1) + "/" + now.getDate());
    const todayString = today.toDateString();
    planItems_TOP = delivery_todo_planItems !== undefined ? delivery_todo_planItems.filter(function (obj) {
      return obj.toDoDay.toDate().toDateString() === todayString;
    }) : undefined;

    planItems_BOTTOM = delivery_todo_planItems !== undefined ? delivery_todo_planItems.filter(function (obj) {
      return obj.toDoDay.toDate().toDateString() !== todayString;
    }) : undefined;
  } else if (routingContainerState === "n2a") {
    const now = new Date();
    const today = new Date(now.getFullYear() + "/" + (now.getMonth() + 1) + "/" + now.getDate());
    const todayString = today.toDateString();
    planItems_TOP = n2a_planItems !== undefined ? n2a_planItems.filter(function (obj) {
      return obj.apptDate.toDate().toDateString() === todayString || obj.apptDate.toDate() < today;
    }) : undefined;

    planItems_BOTTOM = n2a_planItems !== undefined ? n2a_planItems.filter(function (obj) {
      return obj.apptDate.toDate() > today && obj.apptDate.toDate().toDateString() !== todayString;
    }) : undefined;
  }



  return (
    <div className={classes.root}>
      <Container maxWidth={false}>
        <Suspense fallback={<div>Loading...</div>}>
          <PlanItemExport
            name={props.routingContainerState === "delivery" ? "Empty" : (props.routingContainerState === "todo" ? "Todo" : (props.routingContainerState === "ETA" ? "ETA" : "N2A"))}
            planItems={props.routingContainerState === "delivery" ? delivery_planItems : (props.routingContainerState === "todo" ? delivery_todo_planItems : n2a_planItems)}
          />
        </Suspense>
        {props.routingContainerState === "n2a" ?
          <div>
            <RoutingPanelItem
              classes={classes}
              collection={"containers"}
              planItems={planItems_TOP}
              routingContainerState={props.routingContainerState[0]}
              handleToggle={handleToggle}
              handleClose={handleClose}
              setFormType={setFormType}
              setOpen={setOpen}
              setCollectionItem={setCollectionItem}
              setCollectionItemIndex={setCollectionItemIndex}
              setCollectionItemType={setCollectionItemType}
              setCollectionItemShortDetail={setCollectionItemShortDetail}
              panelHeight={panelHeight * 2 / 3}
            />
            <RoutingPanelItem
              classes={classes}
              collection={"containers"}
              planItems={planItems_BOTTOM}
              routingContainerState={props.routingContainerState[0]}
              handleToggle={handleToggle}
              handleClose={handleClose}
              setFormType={setFormType}
              setOpen={setOpen}
              setCollectionItem={setCollectionItem}
              setCollectionItemIndex={setCollectionItemIndex}
              setCollectionItemType={setCollectionItemType}
              setCollectionItemShortDetail={setCollectionItemShortDetail}
              panelHeight={panelHeight * 2 / 3 * 2}
            />
          </div>
          : (
            <div>
              <RoutingPanelItem
                classes={classes}
                collection={"containers"}
                planItems={planItems_TOP}
                routingContainerState={props.routingContainerState[0]}
                handleToggle={handleToggle}
                handleClose={handleClose}
                setFormType={setFormType}
                setOpen={setOpen}
                setCollectionItem={setCollectionItem}
                setCollectionItemIndex={setCollectionItemIndex}
                setCollectionItemType={setCollectionItemType}
                setCollectionItemShortDetail={setCollectionItemShortDetail}
                panelHeight={panelHeight}
              />
              <RoutingPanelItem
                classes={classes}
                collection={"containers"}
                planItems={planItems_BOTTOM}
                routingContainerState={props.routingContainerState[0]}
                handleToggle={handleToggle}
                handleClose={handleClose}
                setFormType={setFormType}
                setOpen={setOpen}
                setCollectionItem={setCollectionItem}
                setCollectionItemIndex={setCollectionItemIndex}
                setCollectionItemType={setCollectionItemType}
                setCollectionItemShortDetail={setCollectionItemShortDetail}
                panelHeight={panelHeight}
              />
            </div>
          )
        }


      </Container>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <div style={modalStyle} className={classes.paper}>
            <DisplayForm {...displayFormProps} />
          </div>
        </Suspense>
      </Modal>

      <Backdrop className={classes.backdrop} open={openBusy} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default withStyles(styles)(PlanItem2);

//Styles for modal
function getModalStyle() {
  return {
    backgroundColor: "#8db4e2",
    width: "80%",
    //right: "100%",

    position: "relative",
    left: "10%",
    top: "25%",
    transform: `translate(0%, 0%)`,
    outline: 0
  };
}

