const styles = theme => ({
  noAccountHeader: {
    width: '100%',
  },
  signUpLink: {
    width: '100%',
    textDecoration: 'none',
    color: '#303f9f',
    fontWeight: 'bolder',
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
  },
  gretting: {
    marginTop: '100px',
    marginLeft: '48px',
  },
  grettingText: {
    color: '#0070C0',
    fontWeight: 'bold'
  },
  text: {
    marginTop: '50px',
    [theme.breakpoints.up('md')]: {
      marginTop: '40px',
      marginLeft: '100px',
    },
  },
});

export default styles;
