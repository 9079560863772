import React, { Component, createContext } from "react";
import { firestore } from "../firebase";

export const PLANITEM_N2A_CONTEXT = createContext();

class PlanItemN2AProvider extends Component {
  state = { n2a_planItems: undefined };

  unsubscribe = null;

  componentDidMount = () => {
    this.unsubscribe = firestore.collection("containers_ny")
      .where("state", "==", "a")
      .onSnapshot(snapshot => {
        const containerObjs = snapshot.docs.map(doc => {
          return { id: doc.id, routingContainerState: "n2a", ...doc.data() };
        });

        let notInvoicedList = containerObjs.filter(function (obj) {
          return obj.invoiced !== true;
        });

        notInvoicedList.sort(function (a, b) {
          let aDate = a.apptDate.toDate().getFullYear() + "-" + (a.apptDate.toDate().getMonth() + 1) + "-" + a.apptDate.toDate().getDate()
          let bDate = b.apptDate.toDate().getFullYear() + "-" + (b.apptDate.toDate().getMonth() + 1) + "-" + b.apptDate.toDate().getDate()
          if (aDate === bDate) {
            return a.shortDetail.split("-")[0].localeCompare(b.shortDetail.split("-")[0]);
          }
          else {
            return a.apptDate._compareTo(b.apptDate);
          }

        });

        this.setState({ n2a_planItems: notInvoicedList });
      });
  };

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  render() {
    const { n2a_planItems } = this.state;
    const { children } = this.props;

    return <PLANITEM_N2A_CONTEXT.Provider value={n2a_planItems}>{children}</PLANITEM_N2A_CONTEXT.Provider>;
  }
}

export default PlanItemN2AProvider;
