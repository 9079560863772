const styles = theme => ({
  root: {
    width: "97%",
    height: "100%",
    padding: theme.spacing(1),
    "& .MuiTypography-body1": {
      fontFamily:"Consolas",
    }
  },
  listItem1:{
    backgroundColor: "#bab6b6",
  },
  listItemText_crossOut:{
    width:"60%",
    maxWidth: "800px !important",
    textDecoration: "line-through"
  },
  listItemText:{
    width:"60%",
    maxWidth: "800px !important",
  },
  listHeaderText:{
    width:"80%",
    maxWidth: "800px !important",
  },
  listItemText_GREEN:{
    backgroundColor: "#a0dba9 !important",
  },
  listItemText_YELLOW:{
    backgroundColor: "#7F6000 !important",
  },
  listItemText_HIGHLIGHT:{
    backgroundColor: "#e7f525 !important",
  },
  listItemText_BLUE:{
    backgroundColor: "#74baf7 !important",
  },
  listItemText_ORANGE:{
    backgroundColor: "#FF8300 !important",
  },
  listItemText_WHITE:{
    color : "white"
  },
  listItemText_BERGENDY:{
      color : "#8B0000"
  },
  listItemText_B:{
    width:"60%",
    maxWidth: "800px !important",
    "& .MuiListItemText-primary": {
      fontWeight: "bold !important",
      color : "#8B0000 !important"
    }
  },
  listItemText2: {
    width:"20%",
    maxWidth: "300px !important",
    textAlign: 'right',
  },
  listItemText1: {
    width:"20%",
    maxWidth: "300px !important",
    textAlign: 'left',
    marginLeft: "20px",
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridTile: {
    minWidth: "300px !important",
    border: "1px solid rgba(0,0,0,0.23)",
    height: "70vh !important",
  },
  gridTile2: {
    minWidth: "1000px !important",
    border: "1px solid rgba(0,0,0,0.23)",
  },
  panel:{
    width: "100% !important",
    height: "96% !important"
  },
  panel_calender:{
    marginTop: "20px",
  },
  tab: {
    marginTop: "10px",
    fontSize: "20px"
  },
  tabLabel: {
    fontSize: "20px",
    fontWeight: "700"
  },
  headerText: {
    fontSize: "20px",
    fontWeight: "700"
  },
  tabContent: {
    height: "80vh",
    overflow: "auto !important",
  },
  container: {
    minHeight: "100vh",
    overflow: "scroll"
  },
  label: {
    color: "black",
    fontSize: "19px",
    fontWeight: "700",
    marginTop: "25px"
  },
  button: {
    marginTop: "20px",
    fontWeight: "700",
    backgroundColor: "#bab6b6",
    color: "#0070C0"
  },
  tableRowText: {
    textAlign: "center"
  },
  errorText: {
    color: "red",
    textAlign: "center",
    marginTop: "20px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 1000,
  },
});

export default styles;
