const styles = theme => ({
  root: {
    "& .rbc-time-slot": {
      minHeight: "50px",
    },
    "& .rbc-time-header ": {
      height:"100%",
      marginRight: "0px !important",
      overflowY: "auto",
      overflowX: "auto",
      "& .rbc-row.rbc-row-resource": {
        backgroundColor: "white",
      },
      "& .rbc-day-bg.rbc-today": {
        backgroundColor: "rgba(255, 255, 255, 0.3)",
      },
    },
    "& .rbc-day-slot .rbc-event": {
      /* position: relative; */
    },
    "& .rbc-time-header-gutter": {
       position: "relative",
    },
    "& .rbc-month-day-show-more": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
      zIndex: "4",
      fontWeight: "bold",
      fontSize: "85%",
      height: "auto",
      lineHeight: "normal",
      display: "block",
      overflow: "hidden",
    },
    width: "97%",
    height: "100%",
    padding: theme.spacing(1),
  },
  listItem1: {
    backgroundColor: "#bab6b6",
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridTile: {
    minWidth: "300px !important",
    border: "1px solid rgba(0,0,0,0.23)",
    height: "80vh !important",
  },
  gridTile2: {
    minWidth: "1200px !important",
    border: "1px solid rgba(0,0,0,0.23)",
  },
  panel: {
    width: "100% !important",
    height: "96% !important"
  },
  panel_calender: {
  },
  tab: {
    marginTop: "10px",
    fontSize: "20px"
  },
  tabLabel: {
    fontSize: "20px",
    fontWeight: "700"
  },
  headerText: {
    fontSize: "20px",
    fontWeight: "700"
  },
  tabContent: {
    height: "80vh",
    overflow: "auto !important",
  },
  container: {
    minHeight: "100vh",
    overflow: "scroll"
  },
  label: {
    color: "black",
    fontSize: "19px",
    fontWeight: "700",
    marginTop: "25px"
  },
  button: {
    marginTop: "20px",
    fontWeight: "700",
    backgroundColor: "#bab6b6",
    color: "#0070C0"
  },
  errorText: {
    color: "red",
    textAlign: "center",
    marginTop: "20px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 1000,
  },
});

export default styles;
