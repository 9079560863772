import React from "react";

let initialState = {
  ref: "",
  cid: "",
  createBy: "",
  createdAt: "",
  lastModifiedBy: "",
  lastModifiedAt: "",
  branch: "",
  branchRef:"",
  masterBL: "",
  customer: "",
  customerRef:"",
  deliveryTo: "",
  deliveryRef: "",
  portOfOrigin: "",
  portAddressRef: "",
  carrier: "",
  carrierRef: "",
  location: "",
  locationAddressRef: "",
  packages:"",
  export:"",
  articale:"",
  invoiced:"",
  weight:"",
  comment: "",
  mod: 0
};

const DeliveryOrderContext = React.createContext(null);

function reducer(state, action) {
  switch (action.type) {
    case "UPDATE_DELIVERY_INFO":
      const deliveryOrderInfo = action.deliveryOrderInfo;
      const deliveryOrderField = action.deliveryOrderField;

      return {
        ...state,
        [deliveryOrderField]: deliveryOrderInfo
      };

    case "ADD_NEW_DELIVERY":
      return {
        ref: action.ref,
        cid: action.cid,
        createBy: action.createBy,
        createdAt: action.createdAt,
        lastModifiedBy: action.lastModifiedBy,
        lastModifiedAt: action.lastModifiedAt,
        branch: action.branch,
        branchRef: action.branchRef,
        masterBL: action.masterBL,
        customer: action.customer,
        customerRef: action.customerRef,
        deliveryTo: action.deliveryTo,
        deliveryRef: action.deliveryRef,
        portOfOrigin: action.portOfOrigin,
        portAddressRef: action.portAddressRef,
        carrier: action.carrier,
        carrierRef: action.carrierRef,
        location: action.location,
        locationAddressRef: action.locationAddressRef,
        packages: action.packages,
        export: action.export,
        articale: action.articale,
        invoiced: action.invoiced,
        weight: action.weight,
        comment: action.comment,
        mod:action.mod
      };

    case "DELIVERY_RESET":
      return {
        ref: "",
        cid: "",
        createBy: "",
        createdAt: "",
        lastModifiedBy: "",
        lastModifiedAt: "",
        branch: "",
        branchRef:"",
        masterBL: "",
        customer: "",
        customerRef:"",
        deliveryTo: "",
        deliveryRef: "",
        portOfOrigin: "",
        portAddressRef: "",
        carrier: "",
        carrierRef: "",
        location: "",
        locationAddressRef: "",
        packages:"",
        export:"",
        articale:"",
        invoiced:"",
        weight:"",
        comment: "",
        mod:0
      };
    default:
      return state;
  }
}

function DeliveryOrderProvider(props) {
  let [deliveryOrder, dispatchDeliveryOrder] = React.useReducer(reducer, initialState);
  let value = { deliveryOrder, dispatchDeliveryOrder };

  return <DeliveryOrderContext.Provider value={value}>{props.children}</DeliveryOrderContext.Provider>;
}

let DeliveryOrderConsumer = DeliveryOrderContext.Consumer;

export { DeliveryOrderContext, DeliveryOrderProvider, DeliveryOrderConsumer };
