import React from "react";

const label = {
  cid:"Reference #",
  docRef: "docRef",
  appointmentDate: "Appt. Date",
  appointmentTime: "Appt. Time",
  branch: "Branch",
  carrier: "Carrier",
  container: "Container #",
  containerType: "Cont. Type",
  chassisInfo:"Chassis Info",
  customer: "Customer",
  driver: "Delivery Driver",
  deliveryTo:"Delivery To",
  deliveryDate: "Delivery Date",
  eta: "ETA",
  lastFreeDay: "Last Free Day",
  toDoDay: "To Do Day",
  location: "Location",
  masterBL: "MasterBL",
  pickUpDate: "Pickup Date",
  pickUpNumber: "Pickup #",
  portOfOrigin: "Port Of Origin",
  returnDate: "Return Date",
  createDate: "Create Date",
  comment: "Comment",
  packages:"Packeages",
  export:"Export",
  articale:"Articale",
  invoiced:"Invoiced",
  weight:"Weight",
  sealNumber: "Seal Number",
  outSource: "Out Source",
  overWeight: "Over Weight",
  prePull: "Pre Pull",
  storage: "Storage",
  pickupDriver: "Pickup Driver",
  returnDriver: "Return Driver",
  cLocation: "Container Location",
  storageDay: "Stroage Day",
  chassisDay: "Chassis Day",
  bobTail: "BobTail",
  chassisSplit: "Chassis Split",
  chassisSplitFee: "Chassis SplitDay",
  containerComment: "Cont. Comment",
  shortDetail: "Description",
  billNote: "Bill Note",
  apptNote: "Appt. Note",
  freeChassis: "Free Chassis",
  apptDone: "N2Appt. Confirm",
  apptReq: "N2Appt. Reqested",
  hold: "Hold",
  emptyReadyDate: "Empty Ready Date",
};

let initialState = {
  view: "NY",
  columnSetting: "",
  cid: true,
  docRef: "",
  appointmentDate: "",
  appointmentTime: "",
  branch: "",
  carrier: "",
  container: "",
  containerType: "",
  chassisInfo:"",
  customer: "",
  driver: "",
  deliveryTo:"",
  deliveryDate: "",
  eta: "",
  lastFreeDay: "",
  toDoDay: "",
  location: "",
  masterBL: "",
  pickUpDate: "",
  pickUpNumber: "",
  portOfOrigin: "",
  returnDate: "",
  createDate: "",
  comment: "",
  packages:"",
  export:"",
  articale:"",
  invoiced:"",
  weight:"",
  sealNumber: "",
  outSource: "",
  overWeight: "",
  prePull: "",
  storage: "",
  pickupDriver: "",
  returnDriver: "",
  cLocation: "",
  storageDay: "",
  chassisDay: "",
  bobTail:"",
  chassisSplit: "",
  chassisSplitFee: "",
  containerComment: "",
  shortDetail: "",
  billNote: "",
  apptNote: "",
  freeChassis: "",
  apptDone: "",
  apptReq: "",
  hold: "",
  emptyReadyDate: "",
  label:label,
};

const ResultFieldSettingContext = React.createContext(null);

function reducer(state, action) {
  switch (action.type) {
    case "UPDATE_RESULTFIELD":
      const resultFieldInfo = action.resultFieldInfo;
      const resultField = action.resultField;

      return {
        ...state,
        [resultField]: resultFieldInfo
      };

    case "ADD_NEW_RESULTFIELD":
      return {
        view: action.view,
        columnSetting: action.columnSetting,
        branch: action.branch,
        docRef: action.docRef,
        cid: true,
        containerType: action.containerType,
        chassisInfo: action.chassisInfo,
        customer: action.customer,
        deliveryTo: action.deliveryTo,
        masterBL: action.masterBL,
        container: action.container,
        location: action.location,
        eta: action.eta,
        lastFreeDay: action.lastFreeDay,
        toDoDay: action.toDoDay,
        pickUpDate: action.pickUpDate,
        appointmentDate: action.appointmentDate,
        deliveryDate: action.deliveryDate,
        returnDate: action.returnDate,
        carrier: action.carrier,
        pickUpNumber: action.pickUpNumber,
        driver: action.driver,
        portOfOrigin: action.portOfOrigin,
        appointmentTime: action.appointmentTime,
        createDate: action.createDate,
        comment: action.comment,
        packages: action.packages,
        export: action.export,
        articale: action.articale,
        invoiced: action.invoiced,
        weight: action.weight,
        sealNumber: action.sealNumber,
        outSource: action.outSource,
        overWeight: action.overWeight,
        prePull: action.prePull,
        storage: action.storage,
        pickupDriver: action.pickupDriver,
        returnDriver: action.returnDriver,
        cLocation: action.cLocation,
        storageDay: action.storageDay,
        chassisDay: action.chassisDay,
        bobTail: action.bobTail,
        chassisSplit: action.chassisSplit,
        chassisSplitFee: action.chassisSplitFee,
        containerComment: action.containerComment,
        shortDetail: action.shortDetail,
        billNote: action.billNote,
        apptNote: action.apptNote,
        freeChassis: action.freeChassis,
        apptDone: action.apptDone,
        apptReq: action.apptReq,
        hold: action.hold,
        emptyReadyDate: action.emptyReadyDate,
        label:label,
      };

    case "RESULTFIELD_RESET":
      return {
        view: "NY",
        cid: true,
        docRef: "",
        appointmentDate: "",
        appointmentTime: "",
        branch: "",
        carrier: "",
        container: "",
        containerType: "",
        chassisInfo:"",
        customer: "",
        driver: "",
        deliveryTo:"",
        deliveryDate: "",
        eta: "",
        lastFreeDay: "",
        toDoDay: "",
        location: "",
        masterBL: "",
        pickUpDate: "",
        pickUpNumber: "",
        portOfOrigin: "",
        returnDate: "",
        createDate: "",
        comment: "",
        packages:"",
        export:"",
        articale:"",
        invoiced:"",
        weight:"",
        sealNumber: "",
        outSource: "",
        overWeight: "",
        prePull: "",
        storage: "",
        pickupDriver: "",
        returnDriver: "",
        cLocation: "",
        storageDay: "",
        chassisDay: "",
        bobTail:"",
        chassisSplit: "",
        chassisSplitFee: "",
        containerComment: "",
        shortDetail: "",
        billNote: "",
        apptNote: "",
        freeChassis: "",
        apptDone: "",
        apptReq: "",
        hold: "",
        emptyReadyDate: "",
        label:label,
      };
    default:
      return state;
  }
}

function ResultFieldSettingProvider(props) {
  let [resultFieldSetting, dispatchResultFieldSetting] = React.useReducer(reducer, initialState);
  let value = { resultFieldSetting, dispatchResultFieldSetting };

  return <ResultFieldSettingContext.Provider value={value}>{props.children}</ResultFieldSettingContext.Provider>;
}

let ResultFieldSettingConsumer = ResultFieldSettingContext.Consumer;

export { ResultFieldSettingContext, ResultFieldSettingProvider, ResultFieldSettingConsumer };
