const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 3),
    marginTop: "10px",
    overflow: "auto"
  },
  containerBox: {
    marginTop: "10px",
    border: "1px solid #FFFFFF",
    height: "70vh",
    overflow: "auto"
  },

  paper: {
    position: "absolute",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  tab: {
    border: "1px solid white",
    fontSize: "20px"
  },
  tabContent: {
    border: "1px solid white",
    overflow: "auto"
  },
  table: {
    marginTop: "40px",
    minWidth: 650,
    height: "100px",
    whiteSpace: 'nowrap',
    overflow: "auto",
    border: "1px solid white"
  },
  tableRowText: {
    fontWeight: "600"
  },
  tableRowCell: {
    border: "1px solid white"
  },
  tableCell: {
    border: "none",
    borderRight: "1px solid white"
  },

  container: {
    minHeight: "100vh",

    overflow: "scroll"
  },
  label: {
    color: "black",
    fontSize: "20px",
    fontWeight: "700",
    marginTop: "25px"
  },
  field: {
  },
  button: {
    marginTop: "20px",
    fontWeight: "700",
    backgroundColor: "#bab6b6",
    color: "#0070C0"
  },
  signUpLink: {
    textDecoration: "none"
  },
  errorText: {
    marginTop: "20px",
    color: "red",
    textAlign: "center"
  }
});

export default styles;
