const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 3),
    marginTop: "10px",
    "& .MuiFilledInput-root": {
      background: "rgb(232, 241, 250)"
    },
    "& .MuiInput-root": {
      background: "rgb(232, 241, 250)"
    },
    "& .MuiInputBase-root.Mui-disabled": {
      background: "#8db4e2",
      color: 'black',
    }
  },
  containerBox: {
    marginTop: "10px",
    border: "1px solid rgba(0,0,0,0.23)",
    height: "80vh",
    overflow: "auto"
  },
  fromSection:{
    padding: "10px"
  },
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 1000,
  },
  tab: {
    border: "1px solid white",
    fontSize: "20px"
  },
  tabContent: {
    border: "1px solid white",
    overflow: "auto"
  },
  table: {
    marginTop: "40px",
    minWidth: 650,
    height: "100px",

    overflow: "auto",
    border: "1px solid rgba(0,0,0,0.23)",
    whiteSpace: 'nowrap',
  },
  tableCell: {
    borderRight: "1px solid rgba(0,0,0,0.23)",
    padding: "0px",
    margin: "0px"
  },
  tableCell1: {
    padding: "0px",
    width: "5px",
  },
  container: {
    "&Container": {
      border: "1px solid rgba(0,0,0,0.23)",
      padding: "5px",
    },
    minHeight: "100vh",
    overflow: "scroll"
  },
  label: {
    "&Container": {
      color: "black",
    },
    color: "black",
    fontSize: "20px",
    fontWeight: "700",
    marginTop: "25px"
  },
  rightlabel: {
    color: "black",
    fontSize: "20px",
    fontWeight: "700",
    marginTop: "25px",
    marginLeft: "50px"
  },
  field: {
    "&disabled": {
      backgroundColor: "#8db4e2"
    },
    "&additionalTextField": {
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      borderRadius: '4px',
      margin: "5px",
    },
    //backgroundColor: "#a8dbff",
    margin: "5px",
    borderRadius: '4px',
  },
  containerField: {
    width: "100%",
    backgroundColor: "#FFFFFF",
  },
  button1: {
    "&edit": {
      marginTop: "20px",
      fontWeight: "700"
    },
    marginTop: "20px",
    fontWeight: "700",
    backgroundColor: "#bab6b6",
    color: "#750000"
  },
  button: {
    "&edit": {
      marginTop: "20px",
      fontWeight: "700"
    },
    marginTop: "20px",
    fontWeight: "700",
    backgroundColor: "#bab6b6",
    color: "#0070C0"
  },
  signUpLink: {
    textDecoration: "none"
  },
  errorText: {
    marginTop: "20px",
    color: "red",
    textAlign: "center"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

export default styles;
