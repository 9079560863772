import React, { useContext, useState, useEffect, useCallback, lazy, Suspense } from "react";

//HOC CSS
import styles from "./styles";
//material ui
import {
  withStyles, Container, Grid, Typography,
  Button, TextField, InputAdornment,
  Modal, Backdrop, CircularProgress, Dialog,
  DialogActions, DialogContent, DialogContentText, Checkbox,
  DialogTitle
} from "@material-ui/core";
import ContactsIcon from "@material-ui/icons/Contacts";
import EditIcon from '@material-ui/icons/Edit';

import { navigate } from '@reach/router';

//firebase
import firebase from "firebase/app";
import { firestore } from "../../firebase";
//current user context hook
import { CurrentUserContext } from "../../contexts/currentUserProvider";
import { ContainerContext } from "../../contexts/containerProvider";
import { DeliveryOrderContext } from "../../contexts/deliveryOrderProvider";
import { SearchResultContext } from "../../contexts/searchResultProvider";
import { ResultFieldSettingContext } from "../../contexts/resultFieldSettingProvider";

import Helper from '../helper.js';

const ContainerTable = lazy(() => import('../operationContainerTable/operationContainerTable'));
const DisplayForm = lazy(() => import('../displayForm/displayForm'));

function DeliveryOrderForm(props) {
  const classes = props.classes;
  const { resultFieldSetting } = useContext(ResultFieldSettingContext);
  const { deliveryOrder, dispatchDeliveryOrder } = useContext(DeliveryOrderContext);
  const [branchSuggestions, setBranchSuggestions] = useState([]);
  const [customerSuggestions, setCustomerSuggestions] = useState([]);
  const [deliverySuggestions, setDeliverySuggestions] = useState([]);
  const [portSuggestions, setPortSuggestions] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [carrierSuggestions, setCarrierSuggestions] = useState([]);

  return (
    <Container className={classes.fromSection} maxWidth={false}>
      <Grid container spacing={1} direction="row" justifyContent="flex-end">
        {/* left section */}
        <Grid item xs={12} md={6} className={classes.formSection}>
          <Grid container spacing={1} direction="row" justifyContent="flex-start">
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled={false}
                id="operation-referenceNumber-input"
                label="Reference #"
                type="text"
                value={Helper.getContent(deliveryOrder.cid)}
                onChange={e => dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "cid", deliveryOrderInfo: e.target.value })}
                onKeyDown={(e) => {
                  props.setInEditMode(false);
                  if (e.key === "Enter") {
                    props.setServerMessage(false);
                    props.setEditMessage(false);
                    props.findOrder(deliveryOrder.cid);
                    props.setDisableForm(true);
                    props.setInEditMode(true);
                  }
                }}
                className={classes.field}
                InputLabelProps={{ shrink: true }}
                variant="filled"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled={props.disableForm}
                id="operation-branch-input"
                label="Branch"
                type="text"
                value={Helper.getContent(deliveryOrder.branch)}

                margin="normal"
                onChange={e => {
                  dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "branch", deliveryOrderInfo: e.target.value.replace(/^\s+|\s+$/g, "") });
                }}
                onClick={e => {
                  Helper.handleInfoQuery("branch", setBranchSuggestions, null, resultFieldSetting.view);
                }}
                inputProps={{
                  list: "branch"
                }}
                className={classes.field}
                InputLabelProps={{ shrink: true }}
                variant={props.disableForm ? "filled" : "filled"}
              />
              <datalist id="branch">
                {branchSuggestions.map(suggest => (
                  <option key={suggest[1]}>{suggest[0]}</option>
                ))}
              </datalist>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={props.disableForm}
                id="filled-multiline-static-text-customerAddress"
                label="Customer"
                type="text"
                value={Helper.getContent(deliveryOrder.customer)}
                onChange={e => {
                  dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "customer", deliveryOrderInfo: e.target.value.replace(/^\s+|\s+$/g, "") });
                }}
                onClick={e => {
                  Helper.handleInfoQuery("customerAddress", setCustomerSuggestions, null, resultFieldSetting.view);
                }}
                onBlur={e => {
                  props.updateCustomerAddress();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    props.updateCustomerAddress();
                  }
                }}
                InputProps={{
                  className: props.disableForm ? classes.field + "disabled" : "",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disabled={props.disableForm}
                        onClick={() => {
                          props.setOpen(true);
                          props.setCollection("customerAddress");
                          props.setFormMessage(false);
                          props.setFormType("addressBook");
                        }}
                      >
                        <ContactsIcon />
                      </Button>
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  list: "custumer"
                }}
                className={classes.field}
                InputLabelProps={{ shrink: true }}
                variant={props.disableForm ? "filled" : "filled"}
              />
              <datalist id="custumer">
                {customerSuggestions.map(suggest => (
                  <option key={suggest[1]}>{suggest[0]}</option>
                ))}
              </datalist>
              <TextField
                id="filled-multiline-static-custumer"
                multiline
                fullWidth
                minRows="4"
                value={props.customerAddressDisplay}
                className={classes.field + "additionalTextField"}
                disabled={true}
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <div>
                      <InputAdornment position="start">
                        <Button
                          disabled={props.disableForm || deliveryOrder.customer === undefined || deliveryOrder.customer === ""}
                          size="small"
                          onClick={() => {
                            props.setOpen(true);
                            props.setFormMessage(false);
                            props.setFormType("editAddressFormOperation");
                            props.setCollection("customerAddress");
                            props.setAddressObject(props.customerAddressObj);
                          }}
                        >
                          <EditIcon />
                        </Button>
                      </InputAdornment>
                    </div>
                  )
                }}
              />

            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={props.disableForm}
                id="filled-multiline-static-text-portAddress"
                label="Location"
                type="text"
                value={Helper.getContent(deliveryOrder.location)}
                margin="normal"
                onChange={e => {
                  dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "location", deliveryOrderInfo: e.target.value.replace(/^\s+|\s+$/g, "") });
                }}
                onClick={e => {
                  Helper.handleInfoQuery("locationAddress", setLocationSuggestions, null, resultFieldSetting.view);
                }}
                onBlur={e => {
                  props.updateLocationAddress();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    props.updateLocationAddress();
                  }
                }}
                InputProps={{
                  className: props.disableForm ? classes.field + "disabled" : "",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disabled={props.disableForm}
                        onClick={() => {
                          props.setOpen(true);
                          props.setCollection("locationAddress");
                          props.setFormMessage(false);
                          props.setFormType("addressBook");
                        }}
                      >
                        <ContactsIcon />
                      </Button>
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  list: "location"
                }}
                className={classes.field}
                InputLabelProps={{ shrink: true }}
                variant={props.disableForm ? "filled" : "filled"}
              />
              <datalist id="location">
                {locationSuggestions.map(suggest => (
                  <option key={suggest[1]}>{suggest[0]}</option>
                ))}
              </datalist>
              <TextField id="filled-multiline-static-location"
                multiline fullWidth
                minRows="4"
                value={props.locationAddressDisplay}
                className={classes.field + "additionalTextField"}
                disabled={true}
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <div>
                      <InputAdornment position="start">
                        <Button
                          disabled={props.disableForm || deliveryOrder.location === undefined || deliveryOrder.location === ""}
                          size="small"
                          onClick={() => {
                            props.setOpen(true);
                            props.setFormMessage(false);
                            props.setFormType("editAddressFormOperation");
                            props.setCollection("locationAddress");
                            props.setAddressObject(props.locationAddressObj);
                          }}
                        >
                          <EditIcon />
                        </Button>
                      </InputAdornment>
                    </div>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* right section */}
        <Grid item xs={12} md={6} className={classes.formSection}>
          <Grid container spacing={1} direction="row" justifyContent="flex-start">
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={props.disableForm}
                id="filled-multiline-static-text-deliveryAddress"
                label="Delivery TO"
                type="text"
                value={Helper.getContent(deliveryOrder.deliveryTo)}
                margin="normal"
                onChange={e => {
                  dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "deliveryTo", deliveryOrderInfo: e.target.value.replace(/^\s+|\s+$/g, "") });
                }}
                onClick={e => {
                  Helper.handleInfoQuery("deliveryAddress", setDeliverySuggestions, null, resultFieldSetting.view);
                }}
                onBlur={e => {
                  props.updateDeliveryAddress();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    props.updateDeliveryAddress();
                  }
                }}
                InputProps={{
                  className: props.disableForm ? classes.field + "disabled" : "",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disabled={props.disableForm}
                        onClick={() => {
                          props.setOpen(true);
                          props.setCollection("deliveryAddress");
                          props.setFormMessage(false);
                          props.setFormType("addressBook");
                        }}
                      >
                        <ContactsIcon />
                      </Button>
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  list: "delivery"
                }}
                className={classes.field}
                InputLabelProps={{ shrink: true }}
                variant={props.disableForm ? "filled" : "filled"}
              />
              <datalist id="delivery">
                {deliverySuggestions.map(suggest => (
                  <option key={suggest[1]}>{suggest[0]}</option>
                ))}
              </datalist>
              <TextField id="filled-multiline-static-delivery"
                multiline
                fullWidth
                minRows="6"
                value={props.deliveryAddressDisplay}
                className={classes.field + "additionalTextField"}
                disabled={true}
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <div>
                      <InputAdornment position="start">
                        <Button
                          disabled={props.disableForm || deliveryOrder.deliveryTo === undefined || deliveryOrder.deliveryTo === ""}
                          size="small"
                          onClick={() => {
                            props.setOpen(true);
                            props.setFormMessage(false);
                            props.setFormType("editAddressFormOperation");
                            props.setCollection("deliveryAddress");
                            props.setAddressObject(props.deliveryAddressObj);
                          }}
                        >
                          <EditIcon />
                        </Button>
                      </InputAdornment>
                    </div>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled={props.disableForm}
                id="operation-portOfOrgin-input"
                label="Port Of Origin"
                type="text"
                value={Helper.getContent(deliveryOrder.portOfOrigin)}
                margin="normal"
                onChange={e => {
                  dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "portOfOrigin", deliveryOrderInfo: e.target.value.replace(/^\s+|\s+$/g, "") });
                }}
                onClick={e => {
                  Helper.handleInfoQuery("portAddress", setPortSuggestions, null, resultFieldSetting.view);
                }}
                InputProps={{
                  className: props.disableForm ? classes.field + "disabled" : "",
                  shrink: "true",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disabled={props.disableForm}
                        onClick={() => {
                          props.setOpen(true);
                          props.setCollection("portAddress");
                          props.setFormMessage(false);
                          props.setFormType("addressBook");
                        }}
                      >
                        <ContactsIcon />
                      </Button>
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  list: "portOfOrigin"
                }}
                className={classes.field}
                variant={props.disableForm ? "filled" : "filled"}
              />
              <datalist id="portOfOrigin">
                {portSuggestions.map(suggest => (
                  <option key={suggest[1]}>{suggest[0]}</option>
                ))}
              </datalist>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled={props.disableForm}
                id="operation-carrier-input"
                label="Carrier"
                type="text"
                value={Helper.getContent(deliveryOrder.carrier)}
                margin="normal"
                onChange={e => {
                  dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "carrier", deliveryOrderInfo: e.target.value.replace(/^\s+|\s+$/g, "") });
                }}
                onClick={e => {
                  Helper.handleInfoQuery("carriers", setCarrierSuggestions, null, resultFieldSetting.view);
                }}
                InputProps={{
                  className: props.disableForm ? classes.field + "disabled" : "",
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        disabled={props.disableForm}
                        onClick={() => {
                          props.setOpen(true);
                          props.setCollection("carriers");
                          props.setFormMessage(false);
                          props.setFormType("addressBook");
                        }}
                      >
                        <ContactsIcon />
                      </Button>
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  list: "carrier"
                }}
                className={classes.field}
                InputLabelProps={{ shrink: true }}
                variant={props.disableForm ? "filled" : "filled"}
              />
              <datalist id="carrier">
                {carrierSuggestions.map(suggest => (
                  <option key={suggest[1]}>{suggest[0]}</option>
                ))}
              </datalist>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                disabled={props.disableForm}
                id="operation-masterBL-input"
                label="Master BL"
                type="text"
                value={deliveryOrder.masterBL}
                onChange={e => dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "masterBL", deliveryOrderInfo: e.target.value.replace(/^\s+|\s+$/g, "") })}
                className={classes.field}
                InputLabelProps={{ shrink: true }}
                variant={props.disableForm ? "filled" : "filled"}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                disabled={props.disableForm}
                id="operation-weight-input"
                label="Weight"
                type="text"
                value={deliveryOrder.weight}
                onChange={e => dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "weight", deliveryOrderInfo: e.target.value.replace(/^\s+|\s+$/g, "") })}
                className={classes.field}
                InputLabelProps={{ shrink: true }}
                variant={props.disableForm ? "filled" : "filled"}
              />

            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                disabled={props.disableForm}
                id="operation-packages-input"
                label="NO. Of Packages"
                type="text"
                value={deliveryOrder.packages}
                onChange={e => dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "packages", deliveryOrderInfo: e.target.value.replace(/^\s+|\s+$/g, "") })}
                className={classes.field}
                InputLabelProps={{ shrink: true }}
                variant={props.disableForm ? "filled" : "filled"}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                disabled={props.disableForm}
                id="operation-articale-input"
                label="Description of Articles"
                type="text"
                value={deliveryOrder.articale}
                onChange={e => dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "articale", deliveryOrderInfo: e.target.value.replace(/^\s+|\s+$/g, "") })}
                className={classes.field}
                InputLabelProps={{ shrink: true }}
                variant={props.disableForm ? "filled" : "filled"}
              />
            </Grid>
            <Grid item xs={3}>
              <Checkbox
                disabled={props.disableForm}
                checked={!!deliveryOrder.export}
                onChange={e => dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "export", deliveryOrderInfo: e.target.checked })}
              />
              <Typography variant="button">Is Export</Typography>

            </Grid>

          </Grid>

        </Grid>
        {/* bottom section */}
        <Grid item md={12} className={classes.formSection}>
          <TextField
            fullWidth
            disabled={props.disableForm}
            id="operation-comment-input"
            label="Comment"
            type="text"
            value={Helper.getContent(deliveryOrder.comment)}
            onChange={e => dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "comment", deliveryOrderInfo: e.target.value })}
            className={classes.field}
            InputLabelProps={{ shrink: true }}
            variant={props.disableForm ? "filled" : "filled"}
          />
        </Grid>

      </Grid>
    </Container>
  );
}

function arraysEqual(a, b) {
  if (a === b) return -1;
  if (a == null || b == null) return 0;
  if (a.length !== b.length) return 0;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return i;
  }
  return -1;
}

function findDifference(obj1, obj2) {
  const diffKeys = [];
  const skipKeys = ["branch", "lastModifiedAt", "lastModifiedBy", "deliveryToLast", "deliveryToLast1" ,
                    "carrierLast", "carrierLast1", "createBy", "createdAt", "mod", "shortDetail",
                    "lastEdit", "cid", "state"];
  for (const key in obj1) {
    if (!skipKeys.includes(key)) {
      if (key in obj2) {
        if (obj1[key] instanceof Array || obj2[key] instanceof Array) {
          const keyIndex = arraysEqual(obj1[key], obj2[key]);
          if (keyIndex !== -1) {
            diffKeys.push(key + "_" + keyIndex);
          }
        }else if (obj1[key] instanceof Object || obj2[key] instanceof Object) {
          // Object in the db is address
          if (obj1[key].label !== obj2[key].label) {
            diffKeys.push(key);
          }
        } else {
          if (obj1[key] !== obj2[key]) {
            diffKeys.push(key);
          }
        }
      } else {
        diffKeys.push(key);
      }
    }
  }
  for (const key in obj2) {
    if (!skipKeys.includes(key)) {
      if (key in obj1) {
        if (obj1[key] instanceof Array || obj2[key] instanceof Array) {
          const keyIndex = arraysEqual(obj1[key], obj2[key]);
          if (keyIndex !== -1) {
            if (!diffKeys.includes(key + "_" + keyIndex)) {
                  diffKeys.push(key + "_" + keyIndex);
                }
          }
        } else if (obj1[key] instanceof Object || obj2[key] instanceof Object) {
          // Object in the db is address
          if (obj1[key].label !== obj2[key].label) {
            if (!diffKeys.includes(key)) {
              diffKeys.push(key);
            }
          }
        }else {
          if (obj1[key] !== obj2[key]) {
            if (!diffKeys.includes(key)) {
              diffKeys.push(key);
            }
          }
        }
      }else {
        if (!diffKeys.includes(key)) {
          diffKeys.push(key);
        }
      }
    }
  }
  return diffKeys;
}

// Operation components
function Operation(props) {
  const currentUser = useContext(CurrentUserContext);
  // classes porps for styling
  const { classes } = props;
  // model hooks for intial styling and open/close
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  // hook for disabling froms
  const [disableForm, setDisableForm] = React.useState(true);
  // hook for different display and firestore collections on the modal
  const [formType, setFormType] = React.useState("");
  const [collection, setCollection] = useState("");
  const [inEditMode, setInEditMode] = useState(false);
  // hook for Delivery order info
  const [customerAddressObj, setCustomerAddressObj] = useState("");
  const [deliveryAddressObj, setDeliveryAddressObj] = useState("");
  const [locationAddressObj, setLocationAddressObj] = useState("");
  const [customerAddressDisplay, setCustomerAddressDisplay] = useState("");
  const [deliveryAddressDisplay, setDeliveryAddressDisplay] = useState("");
  const [locationAddressDisplay, setLocationAddressDisplay] = useState("");
  const [serverMessage, setServerMessage] = useState(false);
  const [serverMessageOpen, setServerMessageOpen] = useState(false);
  const [editConfirmOpen, setEditConfirmOpen] = useState(false);
  const [editMessage, setEditMessage] = useState("");
  const [formMessage, setFormMessage] = useState(false);
  const [containerCheck, setContainerCheck] = useState(true);

  //address
  const [addressObject, setAddressObject] = useState();

  const { resultFieldSetting } = useContext(ResultFieldSettingContext);

  //container recontext
  const { container, dispatchContainer } = useContext(ContainerContext);
  const [containerIndex, setContainerIndex] = useState("");

  const { deliveryOrder, dispatchDeliveryOrder } = useContext(DeliveryOrderContext);
  const { dispatchSearchResult } = useContext(SearchResultContext);

  const [openLoading, setOpenLoading] = React.useState(false);

  const handleLoadingClose = () => {
    setOpenLoading(false);
  };
  const handleLoadingToggle = useCallback(() => {
    setOpenLoading(!open);
  }, [open]);

  const resetDOForm = useCallback(() => {
    dispatchDeliveryOrder({ type: "DELIVERY_RESET" });
    dispatchContainer({ type: "CONTAINER_RESET" });
    setCustomerAddressDisplay("");
    setDeliveryAddressDisplay("");
    setLocationAddressDisplay("");
    setCustomerAddressObj("");
    setDeliveryAddressObj("");
    setLocationAddressObj("");
  }, [dispatchDeliveryOrder, dispatchContainer]);


  const checkContainer = useCallback(async (containerInfo) => {
    setContainerCheck(true);
    if (containerInfo.containerRefernce.length !== containerInfo.containerNumber.length) {
      if (containerInfo.containerNumber.length > containerInfo.containerRefernce.length) {
        setServerMessage("Failed To Update Delivery Order: Missing Container Info. Please contact IT");
        setServerMessageOpen(true);
        return setContainerCheck(false);
      }
      setServerMessage("Update Delivery Order: Restore partical container info. Please reSave");
      let refList = containerInfo.containerRefernce;
      let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
      var snapshot;
      for (let i = 0; i < refList.length; i++) {
        let elementRef = refList[i];
        snapshot = await firebase
          .firestore()
          .collection("containers" + branchLabel)
          .doc(elementRef)
          .get();


        if (snapshot.exists) {
          var containerData = snapshot.data();
          containerData.containerRefernce = elementRef;
          dispatchContainer({ type: "UPDATE_CONTAINER", container: containerData, index: i });
        } else {
          setServerMessage("Failed To Update Delivery Order: Missing Container Info from reference. Please contact IT");
          setServerMessageOpen(true);
          return setContainerCheck(false);
        }
      }
    }
    return true;
  }, [dispatchContainer, resultFieldSetting.view]);

  function getAddressObject(addressObject) {
    if (addressObject === undefined || addressObject === "") return "";
    return (((addressObject.addressName !== undefined) ? (addressObject.addressName === "" ? addressObject.addressName : (addressObject.addressName + "\n")) : (addressObject.name + "\n")) + addressObject.address1 + "\n" +
      ((addressObject.address2 !== undefined && addressObject.address2) !== "" ? addressObject.address2 + "\n" : "") +
      ((addressObject.addressCity !== undefined && addressObject.addressCity !== "") ? addressObject.addressCity : "")
      + " " + ((addressObject.addressState !== undefined && addressObject.addressState !== "") ? (", " + addressObject.addressState) : "")
      + " " + ((addressObject.addressZip !== undefined && addressObject.addressZip !== "") ? addressObject.addressZip + "\n" : "\n") +
      ((addressObject.addressPhone !== undefined && addressObject.addressPhone !== "") ? "TEL:" + addressObject.addressPhone + "\n" : "") +
      ((addressObject.addressEmail !== undefined && addressObject.addressEmail !== "") ? addressObject.addressEmail + "\n" : "") +
      ((addressObject.addressNote !== undefined && addressObject.addressNote !== "") ? addressObject.addressNote + "\n" : "") +
      ((addressObject.addressNote2 !== undefined && addressObject.addressNote2 !== "") ? ("**** " + addressObject.addressNote2 + " ****\n") : ""));
  };

  useEffect(() => {
    if (customerAddressObj !== undefined && customerAddressObj !== null) {
      setCustomerAddressDisplay(getAddressObject(customerAddressObj));
    }
    if (deliveryAddressObj !== undefined && deliveryAddressObj !== null) {
      setDeliveryAddressDisplay(getAddressObject(deliveryAddressObj));
    }
    if (locationAddressObj !== undefined && locationAddressObj !== null) {
      setLocationAddressDisplay(getAddressObject(locationAddressObj));
    }
  }, [customerAddressObj, deliveryAddressObj, locationAddressObj]);


  const updateCopy = useCallback((deliverOrderDoc, deliveryOrderSnapshotId) => {
    let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
    let customerAddressBookQuery = Helper.addressQueryBuilder("customerAddress" + branchLabel, deliverOrderDoc.customer);
    let deliveryAddressBookQuery = Helper.addressQueryBuilder("deliveryAddress" + branchLabel, deliverOrderDoc.deliveryTo);
    let locationAddressBookQuery = Helper.addressQueryBuilder("locationAddress" + branchLabel, deliverOrderDoc.location);

    Promise.all([customerAddressBookQuery, deliveryAddressBookQuery, locationAddressBookQuery]).then(function (values) {
      let customerAddressBookSnapshot = values[0];
      let deliveryAddressBookSnapshot = values[1];
      let locationAddressSnapshot = values[2];
      let customerAddressLabel = Helper.getLabel(deliverOrderDoc.customer);
      let deliveryToAddressLabel = Helper.getLabel(deliverOrderDoc.deliveryTo);
      let locationAddressLabel = Helper.getLabel(deliverOrderDoc.location);

      let customerAddressobj = "";
      if (customerAddressBookSnapshot !== undefined && customerAddressBookSnapshot.exists) {
        customerAddressLabel = customerAddressBookSnapshot.data().name;
        customerAddressobj = customerAddressBookSnapshot.data();
        customerAddressobj.id = customerAddressBookSnapshot.id;
      }
      setCustomerAddressObj(customerAddressobj);

      let deliveryToAddressObj = "";
      if (deliveryAddressBookSnapshot !== undefined && deliveryAddressBookSnapshot.exists) {
        deliveryToAddressLabel = deliveryAddressBookSnapshot.data().name;
        deliveryToAddressObj = deliveryAddressBookSnapshot.data();
        deliveryToAddressObj.id = deliveryAddressBookSnapshot.id;
      }
      setDeliveryAddressObj(deliveryToAddressObj);

      let locationAddressObj = "";
      if (locationAddressSnapshot !== undefined && locationAddressSnapshot.exists) {
        locationAddressLabel = locationAddressSnapshot.data().name;
        locationAddressObj = locationAddressSnapshot.data();
        locationAddressObj.id = locationAddressSnapshot.id;
      }
      setLocationAddressObj(locationAddressObj);

      dispatchDeliveryOrder({
        type: "ADD_NEW_DELIVERY",
        ref: deliveryOrderSnapshotId,
        cid: deliverOrderDoc.cid,
        createBy: Helper.getContent(deliverOrderDoc.createBy),
        createdAt: Helper.getContent(deliverOrderDoc.createdAt.toDate()),
        lastModifiedBy: Helper.getContent(deliverOrderDoc.lastModifiedBy),
        lastModifiedAt: Helper.getContent(deliverOrderDoc.lastModifiedAt ? deliverOrderDoc.lastModifiedAt.toDate() : ""),
        branch: Helper.getLabel(deliverOrderDoc.branch),
        branchRef: Helper.getRef(deliverOrderDoc.branch),
        masterBL: Helper.getContent(deliverOrderDoc.masterBL),
        customer: customerAddressLabel,
        customerRef: Helper.getRef(deliverOrderDoc.customer),
        deliveryTo: deliveryToAddressLabel,
        deliveryRef: Helper.getRef(deliverOrderDoc.deliveryTo),
        portOfOrigin: Helper.getLabel(deliverOrderDoc.portOfOrigin),
        portAddressRef: Helper.getRef(deliverOrderDoc.portOfOrigin),
        carrier: Helper.getLabel(deliverOrderDoc.carrier),
        carrierRef: Helper.getRef(deliverOrderDoc.carrier),
        location: locationAddressLabel,
        locationAddressRef: Helper.getRef(deliverOrderDoc.location),
        comment: Helper.getContent(deliverOrderDoc.comment),
        packages: Helper.getContent(deliverOrderDoc.packages),
        export: Helper.getBool(deliverOrderDoc.export),
        articale: Helper.getContent(deliverOrderDoc.articale),
        invoiced: Helper.getContent(deliverOrderDoc.invoiced),
        weight: Helper.getContent(deliverOrderDoc.weight),
        mod: deliverOrderDoc.mod ? deliverOrderDoc.mod : 0
      });

      let containerArrayLength = deliverOrderDoc.c_Ref !== undefined ? deliverOrderDoc.c_Ref.length : 0;

      let containerInfor = {
        type: "SET_CONTAINER_INFO",
        number: Helper.getArray(deliverOrderDoc.c_Num, containerArrayLength),
        containerNumber: Helper.getArray(deliverOrderDoc.c_Num, containerArrayLength),
        containerRefernce: Helper.getArray(deliverOrderDoc.c_Ref, containerArrayLength),
        containerType: Helper.getArray(deliverOrderDoc.c_Type, containerArrayLength),
        chassisInfo: Helper.getArray(deliverOrderDoc.c_Chassis, containerArrayLength),
        pickUpNumber: Helper.getArray(deliverOrderDoc.c_PickUpNum, containerArrayLength),
        eta: Helper.getArray(deliverOrderDoc.c_Eta, containerArrayLength),
        pickUpDate: Helper.getArray(deliverOrderDoc.c_PickUpDate, containerArrayLength),
        deliveryDate: Helper.getArray(deliverOrderDoc.c_DeliveryDate, containerArrayLength),
        lastFreeDay: Helper.getArray(deliverOrderDoc.c_LastFree, containerArrayLength),
        toDoDay: Helper.getArray(deliverOrderDoc.c_toDoDay, containerArrayLength),
        toDoDone: Helper.getArray(deliverOrderDoc.c_toDoDone, containerArrayLength),
        returnDate: Helper.getArray(deliverOrderDoc.c_Return, containerArrayLength),
        apptDate: Helper.getArray(deliverOrderDoc.c_ApptDate, containerArrayLength),
        apptTime: Helper.getArray(deliverOrderDoc.c_ApptTime, containerArrayLength),
        driver: Helper.getArray(deliverOrderDoc.c_Driver, containerArrayLength),
        sealNumber: Helper.getArray(deliverOrderDoc.c_sealNumber, containerArrayLength),
        outSource: Helper.getArray(deliverOrderDoc.c_outSource, containerArrayLength),
        overWeight: Helper.getArray(deliverOrderDoc.c_overWeight, containerArrayLength),
        prePull: Helper.getArray(deliverOrderDoc.c_prePull, containerArrayLength),
        storage: Helper.getArray(deliverOrderDoc.c_storage, containerArrayLength),
        pickupDriver: Helper.getArray(deliverOrderDoc.c_pickupDriver, containerArrayLength),
        returnDriver: Helper.getArray(deliverOrderDoc.c_returnDriver, containerArrayLength),
        cLocation: Helper.getArray(deliverOrderDoc.c_cLocation, containerArrayLength),
        storageDay: Helper.getArray(deliverOrderDoc.c_storageDay, containerArrayLength),
        chassisDay: Helper.getArray(deliverOrderDoc.c_chassisDay, containerArrayLength),
        bobTail: Helper.getArray(deliverOrderDoc.c_bobTail, containerArrayLength),
        chassisSplit: Helper.getArray(deliverOrderDoc.c_chassisSplit, containerArrayLength),
        chassisSplitFee: Helper.getArray(deliverOrderDoc.c_chassisSplitFee, containerArrayLength),
        containerComment: Helper.getArray(deliverOrderDoc.c_containerComment, containerArrayLength),
        billNote: Helper.getArray(deliverOrderDoc.c_billNote, containerArrayLength),
        apptNote: Helper.getArray(deliverOrderDoc.c_apptNote, containerArrayLength),
        freeChassis: Helper.getArray(deliverOrderDoc.c_freeChassis, containerArrayLength),
        apptDone: Helper.getArray(deliverOrderDoc.c_apptDone, containerArrayLength),
        apptReq: Helper.getArray(deliverOrderDoc.c_apptReq, containerArrayLength),
        hold: Helper.getArray(deliverOrderDoc.c_hold, containerArrayLength),
        emptyReadyDate: Helper.getArray(deliverOrderDoc.c_emptyReadyDate, containerArrayLength),
        selected: Array(deliverOrderDoc.c_Num ? deliverOrderDoc.c_Num.length : 0).fill(true)
      };

      dispatchContainer(containerInfor);
      checkContainer(containerInfor);
      setInEditMode(true);
      handleLoadingClose();
    });
  }, [dispatchContainer, dispatchDeliveryOrder, resultFieldSetting.view, checkContainer])


  useEffect(() => {
    if (props.docRef !== undefined && deliveryOrder.ref !== props.docRef) {
      handleLoadingToggle();
      resetDOForm()
      let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
      firestore
        .collection("deliverOrders" + branchLabel)
        .doc(props.docRef)
        .get()
        .then(deliveryOrderSnapshot => {
          if (deliveryOrderSnapshot.exists) {
            var deliverOrderDoc = deliveryOrderSnapshot.data();
            updateCopy(deliverOrderDoc, deliveryOrderSnapshot.id);
          } else {
            handleLoadingClose();
          }
        })
        .catch(err => {
          console.log("Error getting deliverOrders", err);
          handleLoadingClose();
        });
    }
  }, [props.docRef, deliveryOrder.ref, resultFieldSetting.view, updateCopy, handleLoadingToggle, resetDOForm]);

  const handleClose = () => {
    setServerMessageOpen(false);
  };
  const handleEditClose = () => {
    setEditConfirmOpen(false);
  };

  //props passing along to the child components
  const deliverOrderFormProps = {
    classes,
    customerAddressDisplay,
    deliveryAddressDisplay,
    locationAddressDisplay,
    inEditMode,
    disableForm,
    setFormType,
    setOpen,
    setCollection,
    setFormMessage,
    setInEditMode,
    setServerMessage,
    setEditMessage,
    findOrder,
    setDisableForm,
    collection,
    setAddressObject,
    updateCustomerAddress,
    updateDeliveryAddress,
    updateLocationAddress,
    customerAddressObj,
    deliveryAddressObj,
    locationAddressObj
  };
  const containerTableProps = {
    classes,
    deliveryAddressDisplay,
    locationAddressDisplay,
    setFormType,
    setOpen,
    inEditMode,
    disableForm,
    setCollection,
    setContainerIndex,
    setAddressObject
  };

  const displayFormProps = {
    classes,
    setFormMessage,
    formMessage,
    setServerMessage,
    setEditMessage,
    containerIndex,
    setCollection,
    setDisableForm,
    setInEditMode,
    formType,
    setFormType,
    setOpen,
    setCustomerAddressObj,
    setDeliveryAddressObj,
    setLocationAddressObj,
    updateCustomerAddress,
    updateDeliveryAddress,
    updateLocationAddress,
    addressObject,
    setAddressObject,
    disableForm,
    collection,
  };

  return (
    <div className={classes.root}>
      <DeliveryOrderContext.Provider value={{ deliveryOrder, dispatchDeliveryOrder }}>
        <Container maxWidth="xl">
          <Grid container spacing={1} direction="row" justifyContent="space-between">
            <Grid item xs>
              <Grid container spacing={2} direction="row-reverse" alignItems="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={(currentUser && currentUser.status !== "active") || !disableForm}
                    color="inherit"
                    className={classes.button1}
                    onClick={() => {
                      setServerMessage(false);
                      setEditMessage(false);
                      createNewOrderFromBranch(props);
                    }}
                  >
                    New
                  </Button>
                </Grid>
                <Grid item>
                  {Error ? (
                    <div>
                      <Typography className={classes.errorText} component="h5" variant="h6">
                        {serverMessage}
                      </Typography>
                      <Dialog open={serverMessageOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title"> Operation </DialogTitle>
                        <DialogContent>
                          <DialogContentText>{serverMessage}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>OK</Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  ) : null}
                </Grid>
                <Grid item>
                  {
                    <div>
                      <Typography className={classes.errorText} component="h5" variant="h6">
                        {editMessage}
                      </Typography>
                      <Dialog open={editConfirmOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title"> Information </DialogTitle>
                        <DialogContent>
                          <DialogContentText>{editMessage}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleEditClose}>OK</Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container spacing={2} direction="row-reverse" >
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={disableForm}
                    color="inherit"
                    className={classes.button}
                    onClick={() => {
                      setServerMessage(false);
                      setEditMessage(false);
                      submitUpdateOrder();
                      setDisableForm(true);
                    }}
                  >
                    Save
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={disableForm}
                    color="inherit"
                    className={classes.button}
                    onClick={() => {
                      setServerMessage(false);
                      setEditMessage(false);
                      setOpen(true);
                      setFormType("deleteConfirm");
                    }}
                  >
                    DELETE
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={deliveryOrder.ref === "" || (currentUser && currentUser.status !== "active") || !disableForm || !inEditMode}
                    color="inherit"
                    className={classes.button}
                    onClick={() => {
                      setServerMessage(false);
                      setEditMessage(false);
                      setDisableForm(false);
                      setInEditMode(true);
                    }}
                  >
                    EDIT
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    className={classes.button}
                    onClick={() => {
                      setServerMessage(false);
                      setEditMessage(false);
                      findOrder(deliveryOrder.cid);
                      setDisableForm(true);
                      setInEditMode(true);
                    }}
                  >
                    FIND
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} direction="row" alignItems="flex-end" justifyContent="space-between">
            <Grid item>
              <Typography variant="caption">{deliveryOrder.createdAt && deliveryOrder.createdAt !== "" ? "Created by " + deliveryOrder.createBy + " at " + deliveryOrder.createdAt : ""}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{deliveryOrder.lastModifiedAt && deliveryOrder.lastModifiedAt !== "" ? "Last Modified by " + deliveryOrder.lastModifiedBy + " at " + deliveryOrder.lastModifiedAt : ""}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.containerBox}>
            <DeliveryOrderContext.Provider value={{ deliveryOrder, dispatchDeliveryOrder }}>
              <DeliveryOrderForm {...deliverOrderFormProps} />
            </DeliveryOrderContext.Provider>
            <ContainerContext.Provider value={{ container, dispatchContainer }}>
              <Suspense fallback={<div>Loading...</div>}>
                <ContainerTable {...containerTableProps} />
              </Suspense>

            </ContainerContext.Provider>
          </Grid>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <div style={modalStyle} className={classes.paper}>
                <DisplayForm {...displayFormProps} />
              </div>
            </Suspense>
          </Modal>

          <Backdrop className={classes.backdrop} open={openLoading} >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Container>
      </DeliveryOrderContext.Provider>
    </div>
  );

  async function createNewOrderFromBranch() {
    let branch = resultFieldSetting.view;
    let docTable = "ny";
    let prefix = "";
    let branchRef = firestore.doc("branch/E2D8WttTME9E7MBNKl1w"); // NY
    let branchLabel = "NEW YORK";

    if (branch === "NY") {
      prefix = "";
      docTable = "ny";
      branchRef = firestore.doc("branch/E2D8WttTME9E7MBNKl1w"); // NY
      branchLabel = "NEW YORK"
    } else if (branch === "CHI") {
      prefix = "CHI-";
      docTable = "chi";
      branchRef = firestore.doc("branch/0URx0kH1tWS04GrpnWEI"); // CHI
      branchLabel = "CHICAGO";
    }
      const cityRef = firestore.collection('cid').doc(docTable);
      try {
        await firestore.runTransaction(async (t) => {
          const doc = await t.get(cityRef);
          const cid = prefix + pad(doc.data().value);
          const increment = firebase.firestore.FieldValue.increment(1);

          t.update(cityRef, { value: increment });
          creatOrder(cid, branchLabel, branchRef)
        });

      } catch (e) {
        setServerMessage("Failed To Create New Delivery Order from Existing Id:" + e);
        setServerMessageOpen(true);
      }
  }
  async function creatOrder(cid, branchLabelName, branchRef) {
    const deliverOrderObj = {
      cid: cid,
      branch: { label: branchLabelName, ref: branchRef },
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      createBy: currentUser.email,
      lastModifiedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      lastModifiedBy: currentUser.email
    };

    let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
    await Helper.addQueryBuilder("deliverOrders" + branchLabel, deliverOrderObj,
      function (doc) {
        navigate("/operation/" + doc.id);
      },
      function (dbErr) {
        setServerMessage("Failed To Create New Delivery Order:" + dbErr);
        setServerMessageOpen(true);
      });

  }

  function pad(number) {
    var s = String(number);
    while (s.length < 5) {
      s = "0" + s;
    }
    return s;
  }

  async function findOrder(cid) {
    let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
    firestore
      .collection("deliverOrders" + branchLabel)
      .where("cid", "==", cid).limit(1)
      .get()
      .then(deliveryOrderSnapshot => {
        if (deliveryOrderSnapshot.empty) {
          setServerMessage("Cannot find Delivery Order " + cid);
        } else {
          navigate("/operation/" + deliveryOrderSnapshot.docs[0].id);
        }
      })
      .catch(err => {
        setServerMessage("Cannot find Delivery Order");
        setServerMessageOpen(true);
      });
  }

  async function updateAddress(collection, reference, label, setAddressObject) {
    let retRef = undefined;
    if (label && label !== "") {
      let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
      collection += branchLabel;
      let notFound = true;
      let addressQuery = Helper.collectQueryBuilder(collection);
      await addressQuery.then(
        (querySnapshot) => {
          querySnapshot.forEach(doc => {
            if (label === doc.data().name) {
              notFound = false;
              let customerRef = firestore.doc((collection + "/") + doc.id);
              let obj = doc.data();
              obj.id = doc.id;
              if (setAddressObject) {
                setAddressObject(obj);
              }
              dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: reference, deliveryOrderInfo: customerRef });
              retRef = customerRef;
            }
          });
          if (notFound) {
            setServerMessage("Failed To Update Address: not a valid customer");
            setServerMessageOpen(true);
          }
        });
    }
    return retRef ? retRef : "";
  }


  async function updateCustomerAddress() {
    setCustomerAddressDisplay("");
    return updateAddress("customerAddress", "customerRef", deliveryOrder.customer, setCustomerAddressObj);
  }

  async function updateDeliveryAddress() {
    setDeliveryAddressDisplay("");
    return updateAddress("deliveryAddress", "deliveryRef", deliveryOrder.deliveryTo, setDeliveryAddressObj);
  }

  async function updateLocationAddress() {
    setLocationAddressDisplay("");
    return updateAddress("locationAddress", "locationAddressRef", deliveryOrder.location, setLocationAddressObj);
  }

  async function submitUpdateOrder() {
    var branchRef = null;
    if (deliveryOrder.branch && deliveryOrder.branch !== "") {
      let notFound = true;
      var snapshot = await firebase
        .firestore()
        .collection("branch")
        .get();
      snapshot.docs.map(doc => {
        if (deliveryOrder.branch === doc.data().name) {
          notFound = false;
          branchRef = firestore.doc(`branch/` + doc.id);
          dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "branchRef", deliveryOrderInfo: branchRef });
        }
        return doc.data().name;
      });
      if (notFound) {
        setServerMessage("Failed To Update Delivery Order: not a valid branch");
        setServerMessageOpen(true);
        return false;
      }
    }

    var customerRef = await updateCustomerAddress();
    var deliveryRef = await updateDeliveryAddress();
    var locationAddressRef = await updateLocationAddress();
    var portAddressRef = await updateAddress("portAddress", "portAddressRef", deliveryOrder.portOfOrigin);
    var carrierRef = await updateAddress("carriers", "carrierRef", deliveryOrder.carrier);

    checkContainer(container);

    handleLoadingToggle();
    let shortDetailLabel = "";
    if (deliveryAddressObj !== undefined && deliveryOrder.deliveryTo === deliveryAddressObj.name) {
      shortDetailLabel = deliveryAddressObj.addressCity + "/" + deliveryAddressObj.addressState;
    }
    var deliverOrderObj = {
      cid: deliveryOrder.cid,
      lastModifiedBy: currentUser.email,
      lastModifiedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      branch: { label: deliveryOrder.branch, ref: branchRef },
      masterBL: deliveryOrder.masterBL,
      comment: deliveryOrder.comment,
      packages: deliveryOrder.packages,
      export: Helper.getBool(deliveryOrder.export),
      articale: deliveryOrder.articale,
      invoiced: deliveryOrder.invoiced,
      weight: deliveryOrder.weight,
      customer: { label: deliveryOrder.customer, ref: customerRef },
      deliveryTo: { label: deliveryOrder.deliveryTo, shortDetail: shortDetailLabel, ref: deliveryRef },
      portOfOrigin: { label: deliveryOrder.portOfOrigin, ref: portAddressRef },
      carrier: { label: deliveryOrder.carrier, ref: carrierRef },
      location: { label: deliveryOrder.location, ref: locationAddressRef },
      c_Ref: container.containerRefernce,
      c_Num: container.containerNumber,
      c_Chassis: container.chassisInfo,
      c_Type: container.containerType,
      c_Eta: container.eta,
      c_PickUpDate: container.pickUpDate,
      c_DeliveryDate: container.deliveryDate,
      c_LastFree: container.lastFreeDay,
      c_toDoDay: container.toDoDay,
      c_toDoDone: container.toDoDone,
      c_Return: container.returnDate,
      c_PickUpNum: container.pickUpNumber,
      c_ApptDate: container.apptDate,
      c_ApptTime: container.apptTime,
      c_Driver: container.driver,
      c_sealNumber: container.sealNumber,
      c_outSource: container.outSource,
      c_overWeight: container.overWeight,
      c_prePull: container.prePull,
      c_storage: container.storage,
      c_pickupDriver: container.pickupDriver,
      c_returnDriver: container.returnDriver,
      c_cLocation: container.cLocation,
      c_storageDay: container.storageDay,
      c_chassisDay: container.chassisDay,
      c_bobTail: container.bobTail,
      c_chassisSplit: container.chassisSplit,
      c_chassisSplitFee: container.chassisSplitFee,
      c_containerComment: container.containerComment,
      c_billNote: container.billNote,
      c_apptNote: container.apptNote,
      c_freeChassis: container.freeChassis,
      c_apptDone: container.apptDone,
      c_apptReq: container.apptReq,
      c_hold: container.hold,
      c_emptyReadyDate: container.emptyReadyDate,
      mod: deliveryOrder.mod + 1,
      lastEdit: "operation",
    };


    let containerRef = deliverOrderObj.c_Num;
    for (let index = 0; index < containerRef.length; index++) {
      if (deliverOrderObj.c_PickUpDate[index] !== undefined && deliverOrderObj.c_PickUpDate[index] !== "") {
        let noApptDate = deliverOrderObj.c_ApptDate[index] === undefined || deliverOrderObj.c_ApptDate[index] === "";
        let noPrePull = deliverOrderObj.c_prePull[index] !== true;
        let noStorage = deliverOrderObj.c_storage[index] !== true;
        if (noApptDate && (noPrePull && noStorage)) {
          setServerMessage("PickUp Date with Missing Appt Date or PrePull/Storage for container# " + containerRef[index]);
          handleLoadingClose();
          return false;
        }
        if (deliverOrderObj.c_pickupDriver[index] === undefined || deliverOrderObj.c_pickupDriver[index] === "") {
          setServerMessage("PickUp Date with Missing Pickup Driver for container# " + containerRef[index]);
          handleLoadingClose();
          return false;
        }
      }
      if (deliverOrderObj.c_DeliveryDate[index] !== undefined && deliverOrderObj.c_DeliveryDate[index] !== "") {
        let message = ""
        if (deliverOrderObj.c_Driver[index] === undefined || deliverOrderObj.c_Driver[index] === "") {
          message += "Delivery driver";
          handleLoadingClose();
          return false;
        }
        if (deliverOrderObj.c_Chassis[index] === undefined || deliverOrderObj.c_Chassis[index] === "") {
          message += (message === "" ? "" : ",") + "Chassis Info";
        }
        if (message !== "") {
          setServerMessage("Delivery Date with Missing " + message + " for container# " + containerRef[index]);
          handleLoadingClose();
          return false;
        }
      }
      if (deliverOrderObj.c_Return[index] !== undefined && deliverOrderObj.c_Return[index] !== "") {
        if (deliverOrderObj.c_returnDriver[index] === undefined || deliverOrderObj.c_returnDriver[index] === "") {
          setServerMessage("Return Date with Missing Return Driver for container# " + containerRef[index]);
          handleLoadingClose();
          return false;
        }
      }
    }


    let needToCommit = true;
    var batch = firestore.batch();
    let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
    var doRef = firestore.collection("deliverOrders" + branchLabel).doc(deliveryOrder.ref);
    var auditDiff = "";

    let checkVersion = await doRef.get()
      .then(deliveryOrderSnapshot => {
        if (deliveryOrderSnapshot.empty) {
          setServerMessage("Cannot find Delivery Order from server");
          setServerMessageOpen(true);
          handleLoadingClose();
          return false;
        } else {
          let deliverOrderDoc = deliveryOrderSnapshot.data();
          let modVersion = deliverOrderDoc.mod;
          deliverOrderObj.cid = deliverOrderDoc.cid;
          dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "cid", deliveryOrderInfo: deliverOrderObj.cid });
          if (modVersion !== undefined && deliverOrderObj.mod <= modVersion) {
            setServerMessage("Cannot save outdated Delivery Order, please refresh");
            setServerMessageOpen(true);
            handleLoadingClose();
            return false;
          }
          auditDiff = findDifference(deliverOrderDoc, deliverOrderObj);
          if (deliverOrderDoc.deliveryTo === undefined ||
            deliverOrderObj.deliveryTo === undefined ||
            deliverOrderDoc.deliveryTo.label !== deliverOrderObj.deliveryTo.label){
              setEditConfirmOpen(true);
              setEditMessage("(Delivery TO update detected)");
              if (deliverOrderDoc.deliveryToLast !== undefined){
                deliverOrderObj.deliveryToLast1 = { by : deliverOrderDoc.deliveryToLast.by, at: deliverOrderDoc.deliveryToLast.at , last: deliverOrderDoc.deliveryToLast.last};
              }
              deliverOrderObj.deliveryToLast = { by : currentUser.email, at: firebase.firestore.Timestamp.fromDate(new Date()) , last: deliverOrderDoc.deliveryTo !== undefined ? deliverOrderDoc.deliveryTo.label : "", lastEdit: "o"};
          }
          if (deliverOrderDoc.carrier === undefined ||
            deliverOrderObj.carrier === undefined ||
            deliverOrderDoc.carrier.label !== deliverOrderObj.carrier.label){
              setEditConfirmOpen(true);
              setEditMessage("(Carrier update detected)");
              if (deliverOrderDoc.carrierLast !== undefined){
                deliverOrderObj.carrierLast1 = { by : deliverOrderDoc.carrierLast.by, at: deliverOrderDoc.carrierLast.at , last: deliverOrderDoc.carrierLast.last};
              }
              deliverOrderObj.carrierLast = { by : currentUser.email, at: firebase.firestore.Timestamp.fromDate(new Date()) , last: deliverOrderDoc.carrier !== undefined ? deliverOrderDoc.carrier.label : "" ,lastEdit: "o"};
          }
        }
        return true;
      })
      .catch(err => {
        setServerMessage("Cannot find Delivery Order from server");
        setServerMessageOpen(true);
        handleLoadingClose();
        return false;
      });

    try {
      container.containerRefernce.forEach((element, index) => {
        const emptyReadyDateString = Helper.getIndex(container.emptyReadyDate, index).split("T");
        let containerObj = {
          ref: element,
          d_Ref: deliveryOrder.ref,
          number: container.containerNumber[index],
          eta: Helper.getDateTime(Helper.getIndex(container.eta, index), undefined, deliverOrderObj.branch),
          pickUpDate: Helper.getDateTime(Helper.getIndex(container.pickUpDate, index), undefined, deliverOrderObj.branch),
          deliveryDate: Helper.getDateTime(Helper.getIndex(container.deliveryDate, index), undefined, deliverOrderObj.branch),
          lastFreeDay: Helper.getDateTime(Helper.getIndex(container.lastFreeDay, index), undefined, deliverOrderObj.branch),
          toDoDay: Helper.getDateTime(Helper.getIndex(container.toDoDay, index), undefined, deliverOrderObj.branch),
          returnDate: Helper.getDateTime(Helper.getIndex(container.returnDate, index), undefined, deliverOrderObj.branch),
          apptDate: Helper.getDateTime(Helper.getIndex(container.apptDate, index), Helper.getIndex(container.apptTime, index), deliverOrderObj.branch),
          driver: Helper.getContent(Helper.getIndex(container.driver, index)),
          pickUpNumber: Helper.getContent(Helper.getIndex(container.pickUpNumber, index)),
          pickupDriver: Helper.getContent(Helper.getIndex(container.pickupDriver, index)),
          toDoDone: Helper.getBool(Helper.getIndex(container.toDoDone, index)),
          containerComment: Helper.getContent(Helper.getIndex(container.containerComment, index)),
          billNote: Helper.getContent(Helper.getIndex(container.billNote, index)),
          apptNote: Helper.getContent(Helper.getIndex(container.apptNote, index)),
          freeChassis: Helper.getBool(Helper.getIndex(container.freeChassis, index)),
          apptDone: Helper.getBool(Helper.getIndex(container.apptDone, index)),
          apptReq: Helper.getBool(Helper.getIndex(container.apptReq, index)),
          hold: Helper.getBool(Helper.getIndex(container.hold, index)),
          emptyReadyDate: Helper.getDateTime(emptyReadyDateString[0], emptyReadyDateString[1], deliverOrderObj.branch),
          lastModifiedBy: currentUser.email,
          lastModifiedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          lastEdit: "operation"
        };

        containerObj.state = Helper.getContainerState(containerObj);
        containerObj.shortDetail = Helper.getShortDetail(deliverOrderObj, containerObj, false, index);
        containerObj.cIndex = index;
        var containerObjRef = firestore.collection("containers" + branchLabel).doc(element);
        batch.update(containerObjRef, containerObj);

        if (Helper.getIndex(container.apptTime, index) !== "" && Helper.getIndex(container.apptDate, index) === "") {
          needToCommit = false;
        }

      });
      deliverOrderObj.state = Helper.getDeliveryOrderState(deliverOrderObj);
      batch.update(doRef, deliverOrderObj);
    } catch (error) {
      checkVersion = false;
      console.log("Failed to update Delivery order to the database: ", error);
      setServerMessage("Failed To Update Delivery Order");
      setServerMessageOpen(true);
      handleLoadingClose();
    }

    if (!checkVersion) {
      // already have msg, dont do anything and go to close
    } else if (needToCommit && containerCheck) {
      Helper.commitbatch(batch, function () {

        if (auditDiff !== "" && auditDiff.length > 0 ){
          let aduitObj = { id: deliverOrderObj.cid, at: deliverOrderObj.lastModifiedAt, by: deliverOrderObj.lastModifiedBy, content: auditDiff};

        Helper.addQueryBuilder('aduit', aduitObj,
        function (doc) {
        },
        function (dbErr) {
        });
        }


        dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "lastModifiedAt", deliveryOrderInfo: deliverOrderObj.lastModifiedAt.toDate() });
        dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "lastModifiedBy", deliveryOrderInfo: deliverOrderObj.lastModifiedBy });
        dispatchDeliveryOrder({ type: "UPDATE_DELIVERY_INFO", deliveryOrderField: "mod", deliveryOrderInfo: deliverOrderObj.mod });
        console.log("Delivery Order Updated");
        setServerMessage("Delivery Order Saved");
        setServerMessageOpen(true);
        dispatchSearchResult({ type: "CLEAR_SEARCH_RESULT" });
        handleLoadingClose();
      }, function (dbErr) {
        console.log("Failed to update Delivery order to the database::container: ", dbErr);
        setServerMessage("Failed To Update Delivery Order::container");
        setServerMessageOpen(true);
        handleLoadingClose();
      });
    } else {
      let message = "Failed To Update Delivery Order: "
      if (!needToCommit) {
        message += " missing Appointment date";
      }
      if (!containerCheck) {
        message += " missing Container Info";
      }
      setServerMessage(message);
      setServerMessageOpen(true);
      handleLoadingClose();
    }

  }
}

export default withStyles(styles)(Operation);

//Styles for modal
function getModalStyle() {
  return {
    position: "relative",
    backgroundColor: "#8db4e2",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 0
  };
}
