import React, { Component, createContext } from "react";
import { firestore } from "../firebase";

export const UsersContext = createContext();

class UsersProvider extends Component {
  state = { users: [] };

  unsubscribe = null;

  componentDidMount = () => {
    this.unsubscribe = firestore.collection("users").onSnapshot(snapshot => {
      const users = snapshot.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });

      this.setState({ users });
    });
  };

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  render() {
    const { users } = this.state;
    const { children } = this.props;

    return <UsersContext.Provider value={users}>{children}</UsersContext.Provider>;
  }
}

export default UsersProvider;
