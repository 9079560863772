import React, { useContext, useState, useCallback } from "react";

//HOC CSS
import styles from "./styles";

//material ui
import { withStyles, Container, Typography,
  Grid, Button } from "@material-ui/core";

// import MaterialTable from 'material-table';
import MaterialTable from 'material-table';

//Reach Router
import { Link } from "@reach/router";

//firebase
import firebase from "firebase/app";
import { firestore } from "../../firebase";

// searchResult context
import { SearchResultContext } from "../../contexts/searchResultProvider";
import { CurrentUserContext } from "../../contexts/currentUserProvider";
import { ResultFieldSettingContext } from "../../contexts/resultFieldSettingProvider";

import Helper from '../helper.js';

function SearchResult(props) {
  const classes = props.classes;

  const { resultFieldSetting } = useContext(ResultFieldSettingContext);
  const currentUser = useContext(CurrentUserContext);
  const { searchResult, dispatchSearchResult } = useContext(SearchResultContext);
  const [serverMessage, setServerMessage] = useState(false);

  const onChangeFn = useCallback((e, props, setServerMessage) => {
    if (e.target.value === undefined || e.target.value === "") {
      setServerMessage("");
      setServerMessage("Cannot remove date in report page");
      return false;
    }
    props.onChange(e.target.value);
  }, []);

  function filterCols(fieldSettingObj, headCellArray) {
    let filteredCell = [];
    const columnSetting = resultFieldSetting.columnSetting;
    if (columnSetting !== undefined && columnSetting !== "") {
      headCellArray.sort(function (a, b) {
        let aIndex = columnSetting.indexOf(a.field) !== -1 ? columnSetting.indexOf(a.field) : 99;
        let bIndex = columnSetting.indexOf(b.field) !== -1 ? columnSetting.indexOf(b.field) : 99;
        return aIndex - bIndex;
      })
    }

    headCellArray.forEach(cell => {
      const cellName = cell.field;
      cell.hiddenByColumnsButton = true;
      cell.hidden = fieldSettingObj[cellName] !== true;
     // cell.export = fieldSettingObj[cellName] === true;
      filteredCell.push(cell);
    });
    return headCellArray;
  }

  function updateCols() {
    let resultFieldObj;
    const col = this.columns;
    let columns = col.sort(function (a, b) {
      return a.tableData.columnOrder - b.tableData.columnOrder;
    }).map(col => {
      return col.field;
    });
    resultFieldObj = { columnSetting: columns, cid: true };
    Helper.handleUpdate("resultFields", `${resultFieldSetting.docRef}`, resultFieldObj,
      function () {
      },
      function (dbErr) {
      });
  }

  function onChangeColumn(column, hidden) {
    let resultFieldObj;
    resultFieldObj = { [`${column.field}`]: !hidden, cid: true };
    Helper.updateQueryBuilder("resultFields", `${resultFieldSetting.docRef}`, resultFieldObj,
      function () {
      },
      function (dbErr) {
      });
  }

  const columns = [
    {
      field: "cid", editable: 'never', title: "Ref #",
      render: rowData =>
        <Link to={"/operation/" + rowData.docRef}>
          {rowData.cid}
        </Link>
    },
    { field: "branch", editable: 'never', title: "Branch" },
    { field: "containerType", editable: 'never', title: "Cont. Type" },
    { field: "customer", editable: 'never', title: "Customer" },
    { field: "container", editable: 'never', title: "Container #" },
    { field: "carrier", editable: 'never', title: "Carrier" },
    { field: "deliveryTo", editable: 'never', title: "Delivery To" },
    { field: "masterBL", editable: 'never', title: "Master B/L" },
    { field: "location", editable: 'never', title: "Location" },
    {
      field: "eta", editable: 'onUpdate', type: 'string', title: "ETA",
      editComponent: props => (
        <input
          className={classes.field}
          type="date"
          value={props.value}
          onChange={(e) => onChangeFn(e, props, setServerMessage)}
        />
      )
    },
    {
      field: "pickUpDate", editable: 'onUpdate', type: 'string', title: "Pickup Date",
      editComponent: props => (
        <input
          className={classes.field}
          type="date"
          value={props.value}
          onChange={e => onChangeFn(e, props, setServerMessage)}
        />
      )
    },
    { field: "pickupDriver", editable: 'never', type: 'string', title: "PickUp Driver" },
    { field: "pickUpNumber", editable: 'never', title: "Pickup #" },
    { field: "portOfOrigin", editable: 'never', title: "Port Of Origin" },
    {
      field: "deliveryDate", editable: 'onUpdate', type: 'string', title: "Delivery Date",
      editComponent: props => (
        <input
          className={classes.field}
          type="date"
          value={props.value}
          onChange={e => onChangeFn(e, props, setServerMessage)}
        />
      )
    },
    { field: "chassisInfo", editable: 'never', title: "Chassis Info" },
    { field: "driver", editable: 'never', title: "Delivery Driver" },
    { field: "toDoDay", editable: 'never', title: "ToDo Day" },
    {
      field: "lastFreeDay", editable: 'onUpdate', type: 'string', title: "LFD",
      editComponent: props => (
        <input
          className={classes.field}
          type="date"
          value={props.value}
          onChange={e => onChangeFn(e, props, setServerMessage)}
        />
      )
    },
    {
      field: "returnDate", editable: 'onUpdate', type: 'string', title: "Return Date",
      editComponent: props => (
        <input
          className={classes.field}
          type="date"
          value={props.value}
          onChange={e => onChangeFn(e, props, setServerMessage)}
        />
      )
    },
    { field: "returnDriver", editable: 'never', type: 'string', title: "Return Driver" },
    {
      field: "appointmentDate", editable: 'onUpdate', type: 'string', title: "Appt. Date",
      editComponent: props => (
        <input
          className={classes.field}
          type="date"
          value={props.value}
          onChange={e => onChangeFn(e, props, setServerMessage)}
        />
      )
    },
    { field: "appointmentTime", editable: 'never', type: 'string', title: "Appt. Time" },
    { field: "apptDone", editable: 'never', type: 'string', title: "N2Appt. Confirmed" },
    { field: "apptReq", editable: 'never', type: 'string', title: "N2Appt. Requested" },
    { field: "hold", editable: 'never', type: 'string', title: "Hold" },
    { field: "invoiced", editable: 'never', type: 'string', title: "Invoiced" },
    { field: "articale", editable: 'never', type: 'string', title: "Articale" },
    { field: "packages", editable: 'never', type: 'string', title: "Package" },
    { field: "export", editable: 'never', type: 'string', title: "Export" },
    { field: "weight", editable: 'never', type: 'string', title: "Weight" },
    { field: "sealNumber", editable: 'never', type: 'string', title: "Seal Number" },
    { field: "outSource", editable: 'never', type: 'string', title: "Out Source" },
    { field: "prePull", editable: 'never', type: 'string', title: "Pre Pull" },
    { field: "storage", editable: 'never', type: 'string', title: "Stroage" },
    { field: "emptyReadyDate", editable: 'never', type: 'string', title: "Empty Ready Date" },
    { field: "overWeight", editable: 'never', type: 'string', title: "Over Weight" },
    { field: "freeChassis", editable: 'never', type: 'string', title: "Free Chassis" },
    { field: "cLocation", editable: 'never', type: 'string', title: "Container Location" },
    { field: "storageDay", editable: 'never', type: 'string', title: "Storage Day" },
    { field: "chassisDay", editable: 'never', type: 'string', title: "Chassis Day" },
    { field: "bobTail", editable: 'never', type: 'string', title: "BobTail" },
    { field: "chassisSplit", editable: 'never', type: 'string', title: "Chassis Split" },
    { field: "chassisSplitFee", editable: 'never', type: 'string', title: "Chassis Split Day" },
    { field: "containerComment", editable: 'never', type: 'string', title: "Container Comment" },
    { field: "shortDetail", editable: 'never', type: 'string', title: "Description" },
    { field: "invoiced", editable: 'never', type: 'string', title: "Invoiced" },
    { field: "billNote", editable: 'never', type: 'string', title: "Bill Note" },
    { field: "apptNote", editable: 'never', type: 'string', title: "Appointment Note" },
    { field: "createDate", editable: 'never', type: 'string', title: "Create Date" },
    { field: "comment", editable: 'never', title: "Comment" },
  ];

  function rowUpdate(newData, oldData) {
    setServerMessage("");

    return new Promise((resolve, reject) => {
      setTimeout(async () => {

        if (newData.pickUpDate !== undefined && newData.pickUpDate !== "") {
          if (newData.pickupDriver === undefined || newData.pickupDriver === "") {
            setServerMessage("Cannot save pick date in report page without proper pickUp driver, please update from Operation page");
            resolve();
            return false;
          }
        }
        if (newData.deliveryDate !== undefined && newData.deliveryDate !== "") {
          let message = ""
          if (newData.driver === undefined || newData.driver === "") {
            message += "delivery driver";
            resolve();
            return false;
          }
          if (newData.chassisInfo === undefined || newData.chassisInfo === "") {
            message += (message === "" ? "" : ",") + "chassis info";
          }
          if (message !== "") {
            setServerMessage("Cannot save pick date in report page without proper " + message + ", please update from Operation page");
            resolve();
            return false;
          }
        }

        if (newData.returnDate !== undefined && newData.returnDate !== "") {
          if (newData.returnDriver === undefined || newData.returnDriver === "") {
            setServerMessage("Cannot save pick date in report page without proper return driver, please update from Operation page");
            resolve();
            return false;
          }
        }

        // dont update mod and keep same version within report result
        const modVersion = newData.mod;
        let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
        const doRef = firestore.collection("deliverOrders" + branchLabel).doc(oldData.docRef);
        const containerRef = firestore.collection("containers" + branchLabel).doc(oldData.ref);

        let doSnapshot = await doRef.get();
        let containerSnapshot = await containerRef.get();

        if (doSnapshot.exists && containerSnapshot.exists) {
          var batch = firestore.batch();
          const index = doSnapshot.data().c_Ref.indexOf(oldData.ref);
          let containerLength = doSnapshot.data().c_Num.length;

          if (modVersion === undefined || doSnapshot.data().mod === undefined || doSnapshot.data().mod > modVersion) {
            setServerMessage("Failed To Update outdated Delivery Order, please refresh result");
            reject();
          } else {
            let deliverOrderObj = {
              cid: doSnapshot.data().cid,
              lastModifiedBy: currentUser.email,
              lastModifiedAt: firebase.firestore.Timestamp.fromDate(new Date()),
              branch: doSnapshot.data().branch,
              masterBL: doSnapshot.data().masterBL,
              comment: doSnapshot.data().comment,
              packages: doSnapshot.data().packages,
              export: Helper.getBool(doSnapshot.data()),
              articale: doSnapshot.data().articale,
              invoiced: doSnapshot.data().invoiced,
              weight: doSnapshot.data().weight,
              customer: doSnapshot.data().customer,
              deliveryTo: doSnapshot.data().deliveryTo,
              portOfOrigin: doSnapshot.data().portOfOrigin,
              carrier: doSnapshot.data().carrier,
              location: doSnapshot.data().location,
              c_Ref: doSnapshot.data().c_Ref,
              c_Num: doSnapshot.data().c_Num,
              c_Chassis: doSnapshot.data().c_Chassis,
              c_Type: doSnapshot.data().c_Type,
              c_Eta: [
                ...doSnapshot.data().c_Eta.slice(0, index),
                newData.eta,
                ...doSnapshot.data().c_Eta.slice(index + 1)
              ],
              c_PickUpDate: [
                ...doSnapshot.data().c_PickUpDate.slice(0, index),
                newData.pickUpDate,
                ...doSnapshot.data().c_PickUpDate.slice(index + 1)
              ],
              c_DeliveryDate: [
                ...doSnapshot.data().c_DeliveryDate.slice(0, index),
                newData.deliveryDate,
                ...doSnapshot.data().c_DeliveryDate.slice(index + 1)
              ],
              c_LastFree: [
                ...doSnapshot.data().c_LastFree.slice(0, index),
                newData.lastFreeDay,
                ...doSnapshot.data().c_LastFree.slice(index + 1)
              ],
              c_Return: [
                ...doSnapshot.data().c_Return.slice(0, index),
                newData.returnDate,
                ...doSnapshot.data().c_Return.slice(index + 1)
              ],
              c_PickUpNum: doSnapshot.data().c_PickUpNum,
              c_ApptDate: [
                ...doSnapshot.data().c_ApptDate.slice(0, index),
                newData.appointmentDate,
                ...doSnapshot.data().c_ApptDate.slice(index + 1)
              ],
              c_toDoDay: Helper.getArray(doSnapshot.data().c_toDoDay, containerLength),
              c_toDoDone: Helper.getArray(doSnapshot.data().c_toDoDone, containerLength),
              c_ApptTime: doSnapshot.data().c_ApptTime,
              c_Driver: doSnapshot.data().c_Driver,
              c_sealNumber: Helper.getArray(doSnapshot.data().c_sealNumber, containerLength),
              c_outSource: Helper.getArray(doSnapshot.data().c_outSource, containerLength),
              c_overWeight: Helper.getArray(doSnapshot.data().c_overWeight, containerLength),
              c_prePull: Helper.getArray(doSnapshot.data().c_prePull, containerLength),
              c_storage: Helper.getArray(doSnapshot.data().c_storage, containerLength),
              c_pickupDriver: Helper.getArray(doSnapshot.data().c_pickupDriver, containerLength),
              c_returnDriver: Helper.getArray(doSnapshot.data().c_returnDriver, containerLength),
              c_cLocation: Helper.getArray(doSnapshot.data().c_cLocation, containerLength),
              c_storageDay: Helper.getArray(doSnapshot.data().c_storageDay, containerLength),
              c_chassisDay: Helper.getArray(doSnapshot.data().c_chassisDay, containerLength),
              c_bobTail: Helper.getArray(doSnapshot.data().c_bobTail, containerLength),
              c_chassisSplit: Helper.getArray(doSnapshot.data().c_chassisSplit, containerLength),
              c_chassisSplitFee: Helper.getArray(doSnapshot.data().c_chassisSplitFee, containerLength),
              c_containerComment: Helper.getArray(doSnapshot.data().c_containerComment, containerLength),
              c_billNote: Helper.getArray(doSnapshot.data().c_billNote, containerLength),
              c_apptNote: Helper.getArray(doSnapshot.data().c_apptNote, containerLength),
              c_freeChassis: Helper.getArray(doSnapshot.data().c_freeChassis, containerLength),
              c_apptDone: Helper.getArray(doSnapshot.data().c_apptDone, containerLength),
              c_apptReq: Helper.getArray(doSnapshot.data().c_apptReq, containerLength),
              c_hold: Helper.getArray(doSnapshot.data().c_hold, containerLength),
              c_emptyReadyDate: Helper.getArray(doSnapshot.data().c_emptyReadyDate, containerLength),
              mod: modVersion + 1,
              lastEdit: "searchResult"
            };

            const emptyReadyDateString = newData.emptyReadyDate.split("T");
            let containerObj = {
              ref: containerSnapshot.id,
              d_Ref: containerSnapshot.data().d_Ref,
              number: containerSnapshot.data().number,
              eta: Helper.getDateTime(newData.eta, undefined, deliverOrderObj.branch),
              pickUpDate: Helper.getDateTime(newData.pickUpdate, undefined, deliverOrderObj.branch),
              deliveryDate: Helper.getDateTime(newData.deliveryDate, undefined, deliverOrderObj.branch),
              lastFreeDay: Helper.getDateTime(newData.lastFreeDay, undefined, deliverOrderObj.branch),
              toDoDay: Helper.getDateTime(newData.toDoDay, undefined, deliverOrderObj.branch),
              returnDate: Helper.getDateTime(newData.returnDate, undefined, deliverOrderObj.branch),
              apptDate: Helper.getDateTime(newData.appointmentDate, newData.appointmentTime, deliverOrderObj.branch),
              driver: Helper.getContent(newData.driver),
              pickUpNumber: Helper.getContent(newData.pickUpNumber),
              pickupDriver: Helper.getContent(newData.pickupDriver),
              toDoDone: Helper.getBool(newData.toDoDone),
              containerComment: Helper.getContent(newData.containerComment),
              billNote: Helper.getContent(newData.billNote),
              apptNote: Helper.getContent(newData.apptNote),
              freeChassis: Helper.getBool(newData.freeChassis),
              apptDone: Helper.getBool(newData.apptDone),
              apptReq: Helper.getBool(newData.apptReq),
              hold: Helper.getBool(newData.hold),
              emptyReadyDate: Helper.getDateTime(emptyReadyDateString[0], emptyReadyDateString[1], deliverOrderObj.branch),
              lastModifiedBy: currentUser.email,
              lastModifiedAt: firebase.firestore.Timestamp.fromDate(new Date()),
              lastModifiedByFromReport: currentUser.email,
              lastModifiedAtFromReport: firebase.firestore.Timestamp.fromDate(new Date()),
              lastEdit: "searchResult"
            };
            containerObj.state = Helper.getContainerState(containerObj);
            deliverOrderObj.state = Helper.getDeliveryOrderState(deliverOrderObj);
            containerObj.shortDetail = Helper.getShortDetail(deliverOrderObj, containerObj, false, index);
            containerObj.cIndex = index;

            batch.update(doRef, deliverOrderObj);
            batch.update(containerRef, containerObj);

            newData.mod = deliverOrderObj.mod;

            Helper.commitbatch(batch, function () {
              dispatchSearchResult({
                type: "UPDATE_SEARCH_RESULT_ENTRY",
                new: newData,
                old: oldData
              });
              console.log("Delivery Order Updated");
              setServerMessage("Delivery Order Updated");
              resolve();
            }, function (dbErr) {
              console.log("Failed to update Delivery order to the database: ", dbErr);
              setServerMessage("Failed To Update Delivery Order, please refresh result");
              reject();
            });

          }

        } else {
          setServerMessage("Error getting deliverOrders to edit, please refresh result");
          reject();
        }

      }, 1000)
    });
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={1} direction="row" alignItems="flex-start">
        <Grid item>
          <Button
            variant="outlined"
            color="inherit"
            className={classes.button}
            onClick={() => {
              dispatchSearchResult({
                type: "CLEAR_SEARCH_RESULT"
              });
              props.setResult(false);
              setServerMessage(false);
            }}
          >
            New Search
          </Button>
        </Grid>
        <Grid item>

        </Grid>
        <Grid >
          {Error ? (
            <Typography className={classes.errorText} component="h5" variant="h6">
              {serverMessage}
            </Typography>
          ) : null}
        </Grid>
      </Grid>

      <Grid container spacing={0} direction="row" alignItems="flex-start">
        <div className={classes.root}>
          <div className={classes.tableWrapper}>
            <MaterialTable
              style={{
                backgroundColor: '#8db4e2',
                border: "1px solid white"
              }}
              options={{
                padding: 'dense',
                search: false,
                columnResizable: true,
                pageSizeOptions: [10, 20, 30],
                pageSize: 10,
                rowStyle: {
                  whiteSpace: 'nowrap',
                },
                headerStyle: {
                  backgroundColor: '#8db4e2',
                  color: "black",
                  whiteSpace: 'nowrap',
                  fontSize: "16px",
                  fontWeight: "700",
                },
                exportAllData: true,
                exportButton: true,
                columnsButton: true
              }}
              title={(searchResult[searchResult.length - 1] && (searchResult[searchResult.length - 1].docRef === -1)) ? "Only fetch first 1000 delivery order, please refine your filter" : ""}
              columns={filterCols(resultFieldSetting, columns)}
              data={(searchResult[searchResult.length - 1] && (searchResult[searchResult.length - 1].docRef === -1)) ? searchResult.slice(0, -1) : searchResult}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  rowUpdate(newData, oldData),
              }}
              onColumnDragged={updateCols}
              onChangeColumnHidden={onChangeColumn}
            />
          </div>
        </div>
      </Grid>
    </Container>
  );
}
export default withStyles(styles)(SearchResult);
