import React, { useContext } from 'react';
//HOC CSS
import styles from './styles';
//material ui
import { withStyles, Container, Typography,
  Table, TableBody, TableCell, TableHead,
  TableRow, Checkbox, Grid} from '@material-ui/core';

//current user context hook
import { CurrentUserContext } from '../../contexts/currentUserProvider';
import { UsersContext } from '../../contexts/usersProvider';

import Helper from '../helper';

export function AccountIndex(props) {
  const { classes } = props;
  const users = useContext(UsersContext);
  const currentUser = useContext(CurrentUserContext);

  const handleChange = (email, status) => {
    const userObj = { status: statusUpdate(status) };

    Helper.handleUpdate('systemReport', email, userObj,
    function () {},
    function (dbErr) { console.log("Failed to update the systemReport: ", dbErr);});

    Helper.handleUpdate('users', email, userObj,
    function () {},
    function (dbErr) { console.log('Failed to update user to the database: ', dbErr);;});

  };

  const statusUpdate = status => {
    return status ? 'active' :'inactive';
  };

  const isActive = status => {
    return status === 'active';
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container className={classes.containerBox}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="h4" className={classes.tableRowText}>
                    User Name
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h4" className={classes.tableRowText}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h4" className={classes.tableRowText}>
                    Role
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                (user.right !== "admin") ?
                  <TableRow key={user.email}>
                    <TableCell align="center" className={classes.tableCell}>
                      <Typography variant="body1" className={classes.tableCellText1}>{user.email}</Typography>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Checkbox
                          disabled={currentUser !== null && currentUser !== undefined && currentUser.role !== "admin"}
                          checked={isActive(user.status)}
                          onChange={e => handleChange(user.email, e.target.checked)}
                          value={isActive(user.status)}
                        />
                        <Typography variant="body1" className={classes.tableCellText}>{user.status}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography variant="body1" className={classes.tableCellText}>{user.role}</Typography>
                    </TableCell>
                  </TableRow>
                  : undefined
              ))
              }
            </TableBody>
          </Table>
        </Grid>
      </Container>
    </div>
  );
}

export default withStyles(styles)(AccountIndex);
