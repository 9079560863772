import React, { useEffect, useContext, useState } from "react";
// css
import styles from "./styles";
//material ui
import { withStyles, Container, Typography,
  Grid,  Checkbox} from "@material-ui/core";

//current user context hook
import { CurrentUserContext } from "../../contexts/currentUserProvider";
import { ResultFieldSettingContext } from "../../contexts/resultFieldSettingProvider";

//firebase
import { firestore } from "../../firebase";

import Helper from '../helper.js';

function ResultFieldSetting(props) {
  const currentUser = useContext(CurrentUserContext);
  const { resultFieldSetting, dispatchResultFieldSetting } = useContext(ResultFieldSettingContext);
  const [open, setOpen] = useState(true);

  const fields = Object.keys(resultFieldSetting).map(function (key) {
    return [key, resultFieldSetting.label[key]];
  });

  const excludeFields = ["cid", "docRef", "label" ,"view", "columnSetting"];

  useEffect(() => {
    if (currentUser) {
      const email = currentUser.email;
      setOpen(false);
      firestore.collection("resultFields").doc(`${email}`)
        .get().then(doc => {
          if (!doc.exists) {
            console.log("No matching document!");
          } else {
            dispatchResultFieldSetting({
              type: "ADD_NEW_RESULTFIELD",
              view : doc.data().view,
              columnSetting: doc.data().columnSetting,
              docRef: email,
              appointmentDate: doc.data().appointmentDate,
              appointmentTime: doc.data().appointmentTime,
              branch: doc.data().branch,
              carrier: doc.data().carrier,
              container: doc.data().container,
              containerType: doc.data().containerType,
              chassisInfo: doc.data().chassisInfo,
              customer: doc.data().customer,
              driver: doc.data().driver,
              deliveryTo: doc.data().deliveryTo,
              deliveryDate: doc.data().deliveryDate,
              eta: doc.data().eta,
              lastFreeDay: doc.data().lastFreeDay,
              toDoDay: doc.data().toDoDay,
              location: doc.data().location,
              masterBL: doc.data().masterBL,
              pickUpDate: doc.data().pickUpDate,
              pickUpNumber: doc.data().pickUpNumber,
              portOfOrigin: doc.data().portOfOrigin,
              returnDate: doc.data().returnDate,
              createDate: doc.data().createDate,
              comment: doc.data().comment,
              packages: doc.data().packages,
              export: Helper.getBool(doc.data().export),
              articale: doc.data().articale,
              invoiced: doc.data().invoiced,
              weight: doc.data().weight,
              sealNumber: doc.data().sealNumber,
              outSource: doc.data().outSource,
              overWeight: doc.data().overWeight,
              prePull: doc.data().prePull,
              storage: doc.data().storage,
              pickupDriver: doc.data().pickupDriver,
              returnDriver: doc.data().returnDriver,
              storageDay: doc.data().storageDay,
              chassisDay: doc.data().chassisDay,
              bobTail: doc.data().bobTail,
              chassisSplit: doc.data().chassisSplit,
              chassisSplitFee: doc.data().chassisSplitFee,
              containerComment: doc.data().containerComment,
              cLocation: doc.data().cLocation,
              shortDetail: doc.data().shortDetail,
              billNote: doc.data().billNote,
              apptNote: doc.data().apptNote,
              freeChassis: doc.data().freeChassis,
              apptDone: doc.data().apptDone,
              apptReq: doc.data().apptReq,
              hold: doc.data().hold,
              emptyReadyDate: doc.data().emptyReadyDate,
            })
          }
        });

        return () => {
        }
    }
  }, [currentUser, dispatchResultFieldSetting, setOpen]);

  const handleChange = (ref, status, field) => {
    let resultFieldObj;
    resultFieldObj = { [`${field}`]: !status, cid: true };

    Helper.handleUpdate("resultFields", `${ref}`, resultFieldObj,
      function () {
        dispatchResultFieldSetting({ type: "UPDATE_RESULTFIELD", resultField: field, resultFieldInfo: !status });
      },
      function (dbErr) {
          // console.log("Failed to update the fields: ", dbErr);
          // setServerMessage('Failed To fields');
      });
  };

  return (
    <Container>
      <Grid container direction="row" alignItems="center">
        {fields.map((option) => (
          (!excludeFields.includes(option[0])) ?
            <Grid item key={"resultFieldSetting_" + option[0]} >
              <Grid item xs={1} style={{ minWidth: "150px" }}>
                <Checkbox
                  disabled={open}
                  checked={!!resultFieldSetting[option[0]]}
                  onChange={() => handleChange(resultFieldSetting.docRef, resultFieldSetting[option[0]], option[0])}
                  value={resultFieldSetting[option[0]]}
                />
                <Typography variant="caption">{option[1]}</Typography>
              </Grid>
            </Grid>
            : ""
        ))}
      </Grid>
    </Container>
  );
}
export default withStyles(styles)(ResultFieldSetting);
