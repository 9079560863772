import React, { Component, createContext } from "react";
import { firestore } from "../firebase";

export const PLANITEM_RETURN_CONTEXT = createContext();

class PlanItemRETURNProvider extends Component {
  state = { return_planItems: undefined };

  unsubscribe = null;

  componentDidMount = () => {
    this.unsubscribe = firestore.collection("deliverOrders_ny")
    .where("state", "==", "r")
    .onSnapshot(snapshot => {
      const containerObjs = snapshot.docs.map(doc => {
        return { id: doc.id, routingContainerState: "r", ...doc.data()};
      });

      let notInvoicedList = containerObjs.filter(function (obj) {
        return obj.invoiced !== true;
      });

      notInvoicedList.sort(
        (a, b) => a.cid.localeCompare(b.cid)
      );

      this.setState({ return_planItems : notInvoicedList });
    });
  };

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  render() {
    const { return_planItems } = this.state;
    const { children } = this.props;

    return <PLANITEM_RETURN_CONTEXT.Provider value={return_planItems}>{children}</PLANITEM_RETURN_CONTEXT.Provider>;
  }
}

export default PlanItemRETURNProvider;
