import React, { useContext} from "react";
//HOC CSS
import styles from "./styles";
//material ui
import { withStyles, AppBar, Typography,
  Toolbar,  createTheme, responsiveFontSizes,
  ThemeProvider, IconButton, Tooltip} from "@material-ui/core";
//Reach Router
import { Link } from "@reach/router";

import HelpIcon from '@material-ui/icons/Help';


import { CurrentUserContext } from "../../contexts/currentUserProvider";
import { ResultFieldSettingContext } from "../../contexts/resultFieldSettingProvider";

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          color: isCurrent ? "#0070C0" : "black"
        }
      };
    }}
  />
);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 500,
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(16),
    //border: '1px solid #dadde9',
  },
}))(Tooltip);

export function PlanningHeader(props) {
  const { classes } = props;
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const currentUser = useContext(CurrentUserContext);
  const { resultFieldSetting } = useContext(ResultFieldSettingContext);
  let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <AppBar position="sticky" color="transparent" className={classes.appbar}>

          <Toolbar variant="dense">
            {
              currentUser.TODO !== false ?
                <NavLink to={"/planning/todo"+branchLabel} onClick={ e => {currentUser.path = "/planning/todo"}} className={classes.navLink}>
                  <Typography variant="subtitle1" className={classes.navLinkText}>
                    To Do
                  </Typography>
                </NavLink>
                : null
            }
            {
              currentUser.ETA !== false ?
                <NavLink to={"/planning/eta"+branchLabel} onClick={ e => {currentUser.path = "/planning/eta"}} className={classes.navLink}>
                  <Typography variant="subtitle1" className={classes.navLinkText}>
                    ETA
                  </Typography>
                </NavLink>
                : null
            }
            {
              currentUser.LFD !== false ?
                <NavLink to={"/planning/lfd"+branchLabel} onClick={ e => {currentUser.path = "/planning/lfd"}} className={classes.navLink}>
                  <Typography variant="subtitle1" className={classes.navLinkText}>
                    Last Free
                  </Typography>
                </NavLink>
                : null
            }
            {
              currentUser.STORE !== false ?
                <NavLink to={"/planning/pickup"+branchLabel}onClick={ e => {currentUser.path = "/planning/pickup"}}  className={classes.navLink}>
                  <Typography variant="subtitle1" className={classes.navLinkText}>
                    Store
                  </Typography>
                </NavLink>
                : null
            }
            {
              currentUser.N2A !== false ?
                <NavLink to={"/planning/n2a"+branchLabel} onClick={ e => {currentUser.path = "/planning/n2a"}} className={classes.navLink}>
                  <Typography variant="subtitle1" className={classes.navLinkText}>
                    N2A
                  </Typography>
                </NavLink>
                : null
            }

            {
              currentUser.ROUTING !== false ?
                <NavLink to={"/planning/routing"+branchLabel} onClick={ e => {currentUser.path = "/planning/routing"}} className={classes.navLink}>
                  <Typography variant="subtitle1" className={classes.navLinkText}>
                    Routing
                  </Typography>
                </NavLink>
                : null
            }
            {
              currentUser.DELIVERY !== false ?
                <NavLink to={"/planning/routingHistory"+branchLabel} onClick={ e => {currentUser.path = "/planning/routingHistory"}} className={classes.navLink}>
                  <Typography variant="subtitle1" className={classes.navLinkText}>
                    History
                  </Typography>
                </NavLink>
                : null
            }
            {
              currentUser.EMPTY !== false ?
                <NavLink to={"/planning/delivery"+branchLabel} onClick={ e => {currentUser.path = "/planning/delivery"}} className={classes.navLink}>
                  <Typography variant="subtitle1" className={classes.navLinkText}>
                    Empty
                  </Typography>
                </NavLink>
                : null}
            {
              currentUser.INVOICE !== false ?
                <NavLink to={"/planning/return"+branchLabel}  onClick={ e => {currentUser.path = "/planning/return"}}className={classes.navLink}>
                  <Typography variant="subtitle1" className={classes.navLinkText}>
                    Invoice
                  </Typography>
                </NavLink>
                : null
            }

            <IconButton
          size="small"
          color="inherit"
        >

          <HtmlTooltip
            title={
              <React.Fragment>
                <div className={classes.listItemText_WHITE + " " + classes.listItemText_BLUE} > <em>**REF#12345</em> {"delivery order is an export order "} <br></br></div>
                <div className={classes.listItemText_WHITE + " " + classes.listItemText_YELLOW} > <em>REF#12345</em> <b>brown:</b> {"container is on hold "} <br></br></div>
                <div className={classes.listItemText_WHITE + " " + classes.listItemText_GREEN} > <em>REF#12345</em> <b>green:</b> {"delivery requested but not confirmed "}<br></br></div>
                <div className={classes.listItemText_WHITE + " " + classes.listItemText_ORANGE} > <em>REF#12345</em> <b>orange:</b> {"not requested for delivery yet "}<br></br></div>
                <div className={classes.listItemText_BERGENDY + " " + classes.listItemText_BLUE} > <em>REF#12345</em> <b>bergendy font:</b> {"container is pulling or already pulled out "}<br></br></div>
                <div className={classes.listItemText_WHITE + " " + classes.listItemText_BLUE} > <em>REF#12345</em> <b>white front:</b> {"container is not pulled yet, but schedule confirmed "}<br></br></div>
                <div className={classes.listItemText_BLACK + " " + classes.listItemText_HIGHLIGHT} > <em>REF#12345</em> <b>highlight:</b> {"container is assigned to system outsource (pickup driver) "} <br></br></div>
              </React.Fragment>
            }
          >
            <HelpIcon />
          </HtmlTooltip>
        </IconButton>
          </Toolbar>

        </AppBar>
      </ThemeProvider>
    </div>
  );
}

export default withStyles(styles)(PlanningHeader);
