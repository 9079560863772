import React, { Component, createContext } from "react";
import { firestore } from "../firebase";

export const PLANITEM_DELIVERY_CONTEXT = createContext();

class PlanItemDELIVERYProvider extends Component {
  state = { delivery_planItems: undefined };

  unsubscribe = null;

  componentDidMount = () => {
    this.unsubscribe = firestore.collection("containers_ny")
    .where("state", "==", "d")
    .onSnapshot(snapshot => {
      const containerObjs = snapshot.docs.map(doc => {
        return { id: doc.id, routingContainerState: "d", ...doc.data()};
      });

      let notInvoicedList = containerObjs.filter(function (obj) {
        return obj.invoiced !== true;
      });

      notInvoicedList.sort(
        (a, b) => {
          if (a.pickUpDate === null) return -1;
          if (b.pickUpDate === null) return 0;
          return a.pickUpDate._compareTo(b.pickUpDate)
        }
      );

      this.setState({ delivery_planItems : notInvoicedList });
    });
  };

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  render() {
    const { delivery_planItems } = this.state;
    const { children } = this.props;

    return <PLANITEM_DELIVERY_CONTEXT.Provider value={delivery_planItems}>{children}</PLANITEM_DELIVERY_CONTEXT.Provider>;
  }
}

export default PlanItemDELIVERYProvider;
