
import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-big-calendar'
import * as dates from 'date-arithmetic'
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import styles from "./styles";
import withStyles from "@material-ui/core/styles/withStyles";

import Scheduler, { SchedulerData, ViewTypes, CellUnits, DATE_FORMAT, DATETIME_FORMAT } from 'react-big-scheduler'
import 'react-big-scheduler/lib/css/style.css'
import moment from 'moment'

//moment.tz.setDefault("America/New_York");



CustomWeekView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
}

CustomWeekView.range = (date) => {
  return [dates.startOf(date, 'day')];
}

CustomWeekView.navigate = (date, action) => {
  switch (action) {
    case Navigate.PREVIOUS:
      {
        let result = new Date(date);
        result.setDate(result.getDate() - 1);
        return result;
      }

    case Navigate.NEXT:
      {
        let result = new Date(date);
        result.setDate(result.getDate() + 1);
        return result;
      }

    default:
      return date;
  }
}

CustomWeekView.title = (date, { localizer }) => {
  return localizer.format(date, 'dayHeaderFormat');
}

CustomWeekView.prevClick = (schedulerData) => {
  schedulerData.prev();
  //schedulerData.setEvents(DemoData.events);
}

CustomWeekView.nextClick = (schedulerData) => {
  schedulerData.next();
  //schedulerData.setEvents(DemoData.events);
}

CustomWeekView.onViewChange = (schedulerData, view) => {
  schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
}

CustomWeekView.onSelectDate = (schedulerData, date) => {
  schedulerData.setDate(date);
}

CustomWeekView.isNonWorkingTime = (schedulerData, time) => {
  return false;
}


CustomWeekView.getCustomDate = (schedulerData, num, date = undefined) => {
  let selectDate = schedulerData.startDate;
  if (date !== undefined)
    selectDate = date;

  let startDate = num === 0 ? schedulerData.localeMoment(selectDate).format(DATE_FORMAT) :
    schedulerData.localeMoment(selectDate).add(1 * num, 'days').format(DATE_FORMAT),
    endDate = schedulerData.localeMoment(startDate).add(0, 'days').format(DATE_FORMAT),
    cellUnit = CellUnits.Day;

  return {
    startDate,
    endDate,
    cellUnit
  };
}

//2. create the view model, put it in the props obj
let schedulerData = new SchedulerData(new moment().format(DATE_FORMAT), ViewTypes.Custom, false, false, {
  schedulerWidth: "90%",
  customResourceTableWidth: "20%",
  customCellWidth: "80%",
  creatable: false,
  movable:false,
  views: [],
  resourceName: "Driver",
  schedulerMaxHeight: 500,
  calendarPopoverEnabled: false,
  eventItemPopoverEnabled:false,
  headerEnabled: false,
}, {
  getCustomDateFunc: CustomWeekView.getCustomDate,
  isNonWorkingTimeFunc: CustomWeekView.isNonWorkingTime,
  getSummaryFunc: CustomWeekView.getSummary,
}, moment);

CustomWeekView.eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {
  let borderWidth = isStart ? '4' : '0';
  let borderColor = 'rgba(0,139,236,1)', backgroundColor = '#80C5F6', color = "white", fontWeight = "normal";
  let titleText = schedulerData.behaviors.getEventTextFunc(schedulerData, event);
  if (!!event.type) {
    borderColor = event.type === 1 ? 'rgba(0,139,236,1)' : (event.type === 3 ? 'rgba(245,60,43,1)' : '#999');
    backgroundColor = event.type === 1 ? '#80C5F6' : (event.type === 3 ? '#FA9E95' : '#D9D9D9');
  }

  if(event && event.title.indexOf("Vacation") !== -1){
    backgroundColor = "#889199";
  }else {
    if (event && event.data && event.data.apptDone) {
      backgroundColor = "#74baf7";
      if (event.data.hold) {
        backgroundColor = "#7F6000";
      }
    } else {
      if (event.data.hold) {
        backgroundColor = "#7F6000";
      }
      else if (event.data.apptReq) {
        backgroundColor = "#a0dba9";
      } else {
        backgroundColor = "#FF8300";
      }
    }
}

  if (event && event.data && event.data.pickUpDate) {
    color = "#8B0000";
    fontWeight = "bold";
  }

  if (event && event.data && event.data.driver === "OUTSOURCE_SYSTEM"){
    color = "black";
    backgroundColor = "#e7f525";
  }

  let divStyle = { borderLeft: borderWidth + 'px solid ' + borderColor, backgroundColor: backgroundColor, color: color, fontWeight: fontWeight, height: mustBeHeight };
  if (!!agendaMaxEventWidth)
    divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };

    let timeString = event.start.split(' ')[1];
    let displayTime = timeString === "00:00:01" ? "" : (timeString.substring(0,5));

  return <div key={event.id} className={mustAddCssClass} style={divStyle}>
    <span style={{ marginLeft: '4px', lineHeight: `${mustBeHeight}px` }}> {displayTime} {titleText}</span>
  </div>;
}
export function CustomWeekView({
  date,
  localizer,
  resources,
  events,
  onSelectEvent,
  onSelectSlot,
  ...props
}) {
  let day_event = events.filter(function (obj) {
    return date => obj.start && date <= obj.end;
  }).map(obj => {
    let startString = moment(obj.start).add(1, 'seconds').format(DATETIME_FORMAT);
    let endString = moment(obj.end).add(1, 'seconds').format(DATETIME_FORMAT);
    if (obj.start === obj.end) {
      endString = moment(obj.end).add(2, 'seconds').format(DATETIME_FORMAT)
    }
    return {
      id: obj.id,
      data: obj.data,
      start: startString,
      end: endString,
      resourceId: obj.resourceId,
      title: obj.title,
    }
  });

  schedulerData.setDate(date);
  schedulerData.setResources(resources);
  schedulerData.setEvents(day_event);
  return (
    <div>
      {/* <h6 >Export
      </h6> */}
      <DragDropContextProvider backend={HTML5Backend}>
        <Scheduler schedulerData={schedulerData}
          prevClick={CustomWeekView.prevClick}
          nextClick={CustomWeekView.nextClick}
          onSelectDate={CustomWeekView.onSelectDate}
          onViewChange={CustomWeekView.onViewChange}
          eventItemClick={onSelectEvent}
          slotClickedFunc={(schedulerData, item) => {
            schedulerData.slotInfo = item;
            onSelectSlot(schedulerData);
          }}
          eventItemTemplateResolver={CustomWeekView.eventItemTemplateResolver}
        />
      </DragDropContextProvider>
    </div>

  )
}

export default withStyles(styles)(CustomWeekView);