import React, { useState, useContext, lazy, Suspense } from "react";

// reach router
import { navigate } from '@reach/router';

//HOC CSS
import styles from "./styles";

import {
  withStyles, Container, Modal, CircularProgress,
  Backdrop, ImageList, ImageListItem, ListItem, ListItemText,
  Typography,
} from '@material-ui/core';

import { VariableSizeGrid } from 'react-window';

//firebase
import { firestore } from "../../firebase";

import { ResultFieldSettingContext } from "../../contexts/resultFieldSettingProvider";
import { PLANITEM_ETA_CONTEXT } from "../../contexts/planItem_eta_Provider";
import { PLANITEM_LFD_CONTEXT } from "../../contexts/planItem_lfd_Provider";
import { PLANITEM_PICKUP_CONTEXT } from "../../contexts/planItem_pickup_Provider";
import { PLANITEM_RETURN_CONTEXT } from "../../contexts/planItem_return_Provider";

import { PLANITEM_ETA_CONTEXT_CHI } from "../../contexts/planItem_eta_Provider_chi";
import { PLANITEM_LFD_CONTEXT_CHI } from "../../contexts/planItem_lfd_Provider_chi";
import { PLANITEM_PICKUP_CONTEXT_CHI } from "../../contexts/planItem_pickup_Provider_chi";
import { PLANITEM_RETURN_CONTEXT_CHI } from "../../contexts/planItem_return_Provider_chi";

const PlanItemExport = lazy(() => import('./planItem_export'));

const DisplayForm = lazy(() => import('../displayForm/displayForm'));

const panelHeight = 600;

function RoutingPanelItem(props) {
  const classes = props.classes;
  const { resultFieldSetting } = useContext(ResultFieldSettingContext);
  const containerList_all = props.planItems;

  const isInvoice = props.routingContainerState === "r";

  const containerList = containerList_all !== undefined ? containerList_all.filter(function (obj) {
    let titleList = obj.shortDetail.split("-");
    let isCHI = titleList[0].indexOf("CHI") !== -1;
    return obj.invoiced !== true && (resultFieldSetting.view === "CHI" ? isCHI : !isCHI);
  }) : undefined;


  const handleClick = (routingContainerState, item, props) => {
    switch (routingContainerState) {
      case 'l':
      case 'p':
        fetchOrder(routingContainerState, item.d_Ref, item.cIndex, props, item.shortDetail);
        break;
      case 'e':
        fetchOrder(routingContainerState, item.d_Ref, item.id, props, item.shortDetail);
        break;
      case 'r':
        fetchOrder(routingContainerState, item.id, undefined, props, item.shortDetail);
        break;
      default:
        navigate("/operation/" + item.d_Ref);
    }
  }

  async function fetchOrder(routingContainerState, ref, itemIndex, props, shortDetail) {
    props.handleToggle();
    let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
    await firestore
      .collection("deliverOrders" + branchLabel)
      .doc(ref)
      .get()
      .then(deliveryOrderSnapshot => {
        if (deliveryOrderSnapshot.exists) {
          var deliverOrderDoc = deliveryOrderSnapshot.data();
          deliverOrderDoc.ref = ref;
          switch (routingContainerState) {
            case 'r':
            case 'l':
            case 'e':
            case 'p':
              props.handleClose();
              props.setFormType("editDeiveryOrder");
              props.setCollectionItem(deliverOrderDoc);
              props.setCollectionItemIndex(itemIndex);
              props.setCollectionItemType(routingContainerState);
              props.setCollectionItemShortDetail(shortDetail);
              props.setOpen(true);
              break;
            default:
              break;
          }
        }
        props.handleClose();
      })
      .catch(err => {
        console.log("Error getting deliverOrders", err);
        props.handleClose();
      });
  }

  function getRowHeight(index) {
    if (containerList[index].shortDetail === undefined) return 50;
    const linesize = isInvoice ? 120 : 120;
    let size = containerList[index].shortDetail.length;
    let line = Math.floor(size / linesize) + ((size % linesize) > 0 ? 1 : 0);
    return line * 25;
  }

  function render(props) {
    const { rowIndex, index, data, style } = props;
    const parentProps = data.props;
    const itemIndex = index === undefined ? rowIndex : index;
    const routingContainerState = parentProps.routingContainerState;
    const deliveryColCustomer = (data.containerList[itemIndex].customer ? data.containerList[itemIndex].customer.label : "NA");

    return (
      <div>
        {routingContainerState === "r" ? (
          <div >
            <ListItem button
                onClick={e => handleClick(routingContainerState, data.containerList[itemIndex], parentProps)}
                style={style}
                key={data.containerList[itemIndex].id}
                className={itemIndex % 2 === 0 ? classes.listItem : classes.listItem1}
              >
                <ListItemText primary={`${data.containerList[itemIndex].shortDetail} (${deliveryColCustomer})`}  />
                {/* <ListItemText secondary={`${deliveryColCustomer}`} className={classes.listItemText1} /> */}
              </ListItem>
          </div>
        ) :
          <ListItem button
            onClick={e => handleClick(routingContainerState, data.containerList[itemIndex], parentProps)}
            style={style}
            key={data.containerList[itemIndex].id}
            className={itemIndex % 2 === 0 ? classes.listItem : classes.listItem1}
          >
            <ListItemText primary={`${data.containerList[itemIndex].shortDetail}`} />
          </ListItem>

        }
      </div>
    );

  }

  const panelWidth = window.innerWidth;
  return (
    <div className={classes.root}>
      <ImageList className={classes.gridList} cols={1} rowHeight={panelHeight}>
        <ImageListItem className={classes.gridTile2}>
        {<RenderHeader routingContainerState={props.routingContainerState} panelWidth={panelWidth} className={classes.panel} ></RenderHeader>}
          {
            containerList === undefined ?
              <CircularProgress></CircularProgress> :
              (containerList.length !== 0 ?
                <VariableSizeGrid
                  className={classes.panel}
                  height={panelHeight}
                  columnCount={1}
                  columnWidth={() => panelWidth}
                  rowHeight={getRowHeight}
                  rowCount={containerList.length}
                  itemData={{ containerList: containerList, props: props }}
                  width={panelWidth}
                  classes={classes}
                >
                  {render}
                </VariableSizeGrid> :
                <Typography className={classes.tableRowText} >EMPTY</Typography>)
          }
        </ImageListItem>
      </ImageList>
    </div>
  );

  function RenderHeader(props) {
    const routingContainerState = props.routingContainerState;
    return (
      <div style={{ maxWidth: props.panelWidth }}>
        {
          (routingContainerState === "r") ? (
            <div >
              <ListItem component="div" dense={true}>
                <ListItemText component="div" secondary={"Description"} className={classes.listHeaderText} />
                {/* <ListItemText component="div" secondary={"Customer"} className={classes.listItemText1} /> */}
              </ListItem>
            </div>
          ) : null
        }
      </div>
    );
  }
}

function PlanItem(props) {
  // classes porps for styling
  const { resultFieldSetting } = useContext(ResultFieldSettingContext);
  let isCHI = resultFieldSetting.view === "CHI";

  const { classes } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [formType, setFormType] = useState();
  const [collectionItem, setCollectionItem] = useState();
  const [collectionItemIndex, setCollectionItemIndex] = useState();
  const [collectionItemType, setCollectionItemType] = useState();
  const [collectionItemShortDetail, setCollectionItemShortDetail] = useState();

  const eta_planItems = useContext(isCHI ? PLANITEM_ETA_CONTEXT_CHI : PLANITEM_ETA_CONTEXT);
  const lfd_planItems = useContext(isCHI ? PLANITEM_LFD_CONTEXT_CHI : PLANITEM_LFD_CONTEXT);
  const pickup_planItems = useContext(isCHI ? PLANITEM_PICKUP_CONTEXT_CHI : PLANITEM_PICKUP_CONTEXT);
  const return_planItems = useContext(isCHI ? PLANITEM_RETURN_CONTEXT_CHI : PLANITEM_RETURN_CONTEXT);

  const [openBusy, setOpenBusy] = React.useState(false);

  const handleClose = () => {
    setOpenBusy(false);
  };
  const handleToggle = () => {
    setOpenBusy(!openBusy);
  };


  const displayFormProps = {
    classes,
    formType,
    setFormType,
    setOpen,
    collectionItem,
    collectionItemIndex,
    collectionItemType,
    collectionItemShortDetail,
  };

  return (
    <div className={classes.root}>
      <Container maxWidth={false}>
        <Suspense fallback={<div>Loading...</div>}>
          <PlanItemExport
            name={
              props.routingContainerState === "eta" ? "ETA" :
                props.routingContainerState === "lfd" ? "LastFree" :
                  props.routingContainerState === "pickup" ? "Store" :
                    props.routingContainerState === "return" ? "Inovice" : "Unknown"
            }
            planItems={
              props.routingContainerState === "eta" ? (eta_planItems) :
                props.routingContainerState === "lfd" ? (lfd_planItems) :
                  props.routingContainerState === "pickup" ? (lfd_planItems) :
                    props.routingContainerState === "return" ? (return_planItems) : []
            }
          />
        </Suspense>
        <RoutingPanelItem
          classes={classes}
          collection={"containers"}
          planItems={
            props.routingContainerState === "eta" ? (eta_planItems) :
              props.routingContainerState === "lfd" ? (lfd_planItems) :
                props.routingContainerState === "pickup" ? (pickup_planItems) :
                  props.routingContainerState === "return" ? (return_planItems) : []
          }
          routingContainerState={props.routingContainerState[0]}
          handleToggle={handleToggle}
          handleClose={handleClose}
          setFormType={setFormType}
          setOpen={setOpen}
          setCollectionItem={setCollectionItem}
          setCollectionItemIndex={setCollectionItemIndex}
          setCollectionItemType={setCollectionItemType}
          setCollectionItemShortDetail={setCollectionItemShortDetail}
        />

      </Container>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <div style={modalStyle} className={classes.paper}>
            <DisplayForm {...displayFormProps} />
          </div>
        </Suspense>
      </Modal>

      <Backdrop className={classes.backdrop} open={openBusy} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default withStyles(styles)(PlanItem);

//Styles for modal
function getModalStyle() {
  return {
    backgroundColor: "#8db4e2",
    width: "80%",
    //right: "100%",

    position: "relative",
    left: "10%",
    top: "25%",
    transform: `translate(0%, 0%)`,
    outline: 0
  };
}

