import React, { Component, createContext } from "react";

import { firestore, auth } from "../firebase";

export const CurrentUserContext = createContext({ currentUser: null });

class CurrentUserProvider extends Component {
  state = { currentUser: null };

  componentDidMount = async () => {
    this.unsubscribeFromAuth = auth.onIdTokenChanged(async user => {
      if (user) {
        const userDocument = await firestore
          .collection("users")
          .doc(user.email)
          .get();
        return this.setState({ currentUser: userDocument.data() });
      }
      this.setState({ currentUser: null });
    }, error => {console.log('Error update user state: ', error)});
  };

  componentWillUnmount = () => {
    this.unsubscribeFromAuth();
  };

  render() {
    const { children } = this.props;
    const { currentUser } = this.state;

    return <CurrentUserContext.Provider value={currentUser}>{children}</CurrentUserContext.Provider>;
  }
}

export default CurrentUserProvider;
