const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  gretting: {
    marginTop: '50px',

    [theme.breakpoints.up('md')]: {
      marginTop: '100px',
      marginLeft: '48px',
    },
  },
  grettingText: {
    color: '#0070C0',
    fontWeight: '500'
  },
  companyName: {
    marginTop: '50px',
    [theme.breakpoints.up('md')]: {
      marginTop: '40px',
      marginLeft: '124px',
    },
  },
  companyNameText: {
    fontWeight: '900',
  },
  button: {
    margin: theme.spacing(1),
    background: '#bab6b6',
    color: '#FFFFFF',
    fontWeight: '700',
    marginTop: '40px',
  },
  logInButtonDiv: {
    marginTop: '64px',
  },
  contactButtonDiv: {
    marginTop: '64px',
  },
  link: {
    textDecoration: 'none',
  },
});

export default styles;
