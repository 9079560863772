import React, { useContext } from "react";

//material ui
import { withStyles, Grid, Typography } from '@material-ui/core';

//HOC styling
import styles from "./styles";
// reach router
import { navigate } from "@reach/router";
//current user context hook
import { CurrentUserContext } from "../../contexts/currentUserProvider";
import { CacheContext } from "../../contexts/cacheProvider";

export function Dashboard(props) {
  const currentUser = useContext(CurrentUserContext);
  const cache = useContext(CacheContext);

  const { classes } = props;

  if (currentUser) {
    if (currentUser.status === "active"){
      navigate("/operation");
      return null;
    }else {
      return (
        <React.Fragment>
          <Grid item xs={12} className={classes.gretting}>
            <Typography component="h2" variant="h2" className={classes.grettingText}>
              Hello, {currentUser.email}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.text}>
            <Typography component="h4" variant="h4">
              Please kindly be patient and relax; your account are being activated.
            </Typography>
          </Grid>
        </React.Fragment>
      );
    }
  }
  if (cache && cache.login) {
    return (
      <React.Fragment>
        <Grid item xs={12} className={classes.text}>
          <Typography component="h4" variant="h4">
            Loading page ...
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }
  return null;
}

export default withStyles(styles)(Dashboard);
