const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  container: {
    minHeight: '100vh',
  },
  field: {
    borderRadius: '4px',
    marginTop: '8px',
    width: '400px',
  },
  button: {
    fontWeight: '700',
    backgroundColor: '#bab6b6',
    color: '#0070C0',
    marginTop: '20px',
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
  },
});

export default styles;
