import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// currentUser provider
import CurrentUserProvider from "./contexts/currentUserProvider";
import { SearchResultProvider } from "./contexts/searchResultProvider";
import { ResultFieldSettingProvider } from "./contexts/resultFieldSettingProvider";

ReactDOM.render(
  <CurrentUserProvider>
    <SearchResultProvider>
      <ResultFieldSettingProvider>
      <App />
      </ResultFieldSettingProvider>
    </SearchResultProvider>
  </CurrentUserProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
