import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD48BhiZ53QxS8n6SYBo1mZ_F6fGrMIIrA",
  authDomain: "wws-intl.firebaseapp.com",
  databaseURL: "https://wws-intl.firebaseio.com",
  projectId: "wws-intl",
  storageBucket: "wws-intl.appspot.com",
  messagingSenderId: "1045599078576",
  appId: "1:1045599078576:web:142208751c865d0c785375",
  measurementId: "G-T9P8LRLF31"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyD3EK06tsQNeepsZnWa3uUTtQ39nuriEbI",
//   authDomain: "wws-inl-app.firebaseapp.com",
//   databaseURL: "https://wws-inl-app.firebaseio.com",
//   projectId: "wws-inl-app",
//   storageBucket: "wws-inl-app.appspot.com",
//   messagingSenderId: "408561218461",
//   appId: "1:408561218461:web:5624400192a28fb16df574",
//   measurementId: "G-KXDZYY9LYV"
// };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.firestore().settings({ experimentalForceLongPolling: true, merge:true });

//firestore.settings({ timestampsInsnapshots: true });

//for developing purpose to debug in console
window.firebase = firebase;

export const createUserDocument = async (user, additionalData) => {
  // If there is no user, let's not do this.
  if (!user) return;

  // Get a reference to the location in the Firestore where the user
  // document may or may not exist.
  const userRef = firestore.doc(`users/${user.email}`);

  // Go and fetch a document from that location.
  const snapshot = await userRef.get();

  // If there isn't a document for that user. Let's use information
  // that we got from either Google or our sign up form.
  if (!snapshot.exists) {
    const { email } = user;
    const role = "";
    const createdAt = new Date();
    try {
      await userRef.set({
        email,
        role,
        createdAt,
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating user", console.error);
    }
  }

  // Get the document and return it, since that's what we're
  // likely to want to do next.
  await console.log("createUserDocument");
  return getUserDocument(user.email);
};

export const getUserDocument = async uid => {
  if (!uid) return null;
  try {
    const userDocument = await firestore
      .collection("users")
      .doc(uid)
      .get();

    return { uid, ...userDocument.data() };
  } catch (error) {
    console.error("Error fetching user", error.message);
  }
};

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const signOut = () => auth.signOut();

export default firebase;
