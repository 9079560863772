import React, { useState, useContext, useEffect } from 'react';
//HOC CSS
import styles from './styles';
// reach router
import { navigate } from '@reach/router';
//material ui
import { withStyles, Container, Typography,
  Button, Grid, TextField, Checkbox } from '@material-ui/core';
//firebase
// eslint-disable-next-line no-unused-vars
import firebase from "firebase/app";
// eslint-disable-next-line no-unused-vars
import { firestore, auth } from '../../firebase';

//current user context hook
import { CurrentUserContext } from '../../contexts/currentUserProvider';
import Helper from '../helper';

function Account(props) {
  const { classes } = props;
  const currentUser = useContext(CurrentUserContext);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [address, setAddress] = useState('');
  const [serverMessage, setServerMessage] = useState(false);
  const [ALL, setALL] = useState('');
  const [TODO, setTODO] = useState('');
  const [ETA, setETA] = useState('');
  const [LFD, setLFD] = useState('');
  const [STORE, setSTORE] = useState('');
  const [ROUTING, setROUTING] = useState('');
  const [DELIVERY, setDELIVERY] = useState('');
  const [N2A, setN2A] = useState('');
  const [EMPTY, setEMPTY] = useState('');
  const [INVOICE, setINVOICE] = useState('');

  const disable = currentUser.email === "tommy_tsay@hotmail.com";

  useEffect(() => {
    if (!currentUser) {
      navigate('/');
    } else {
      setEmail(currentUser.email);
      setPhone(currentUser.phone);
      setAddress(currentUser.address);
      setALL(currentUser.ALL !== undefined ? currentUser.ALL : true);
      setTODO(currentUser.TODO !== undefined ? currentUser.TODO : true);
      setETA(currentUser.ETA !== undefined ? currentUser.ETA : true);
      setLFD(currentUser.LFD !== undefined ? currentUser.LFD : true);
      setSTORE(currentUser.STORE !== undefined ? currentUser.STORE : true);
      setROUTING(currentUser.ROUTING !== undefined ? currentUser.ROUTING : true);
      setDELIVERY(currentUser.DELIVERY !== undefined ? currentUser.DELIVERY : true);
      setN2A(currentUser.N2A !== undefined ? currentUser.N2A : true);
      setEMPTY(currentUser.EMPTY !== undefined ? currentUser.EMPTY : true);
      setINVOICE(currentUser.INVOICE !== undefined ? currentUser.INVOICE : true);
    }
  }, [currentUser]);

  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={6}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-email"
                  label="User Name (Email)"
                  type="text"
                  checked={email}
                  disabled={true}
                  className={classes.field}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  checked={password}
                  onChange={e => setPassword(e.target.value)}
                  className={classes.field}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-passwordConfirmation"
                  label="Confirm Password"
                  type="password"
                  checked={passwordConfirmation}
                  onChange={e => setPasswordConfirmation(e.target.value)}
                  className={classes.field}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-phone"
                  label="Phone Number"
                  type="text"
                  autoComplete="email"
                  checked={phone}
                  onChange={e => setPhone(e.target.value)}
                  className={classes.field}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  minRows="4"
                  id="outlined-address"
                  label="Note"
                  type="text"
                  autoComplete="email"
                  checked={address}
                  onChange={e => setAddress(e.target.value)}
                  className={classes.field}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <div className={classes.field1}>
                  <Typography variant="h6"> Planning Tabs
                  </Typography>
                  {/* <Checkbox
                    onChange={e => setALL(e.target.checked)}
                    checked={ALL === true}
                  />
                  <Typography variant="caption">Overview</Typography> */}
                  <Checkbox
                    onChange={e => setTODO(e.target.checked)}
                    checked={TODO === true}
                  />
                  <Typography variant="caption">To Do</Typography>
                  <Checkbox
                    onChange={e => setETA(e.target.checked)}
                    checked={ETA === true}
                  />
                  <Typography variant="caption">ETA</Typography>
                  <Checkbox
                    onChange={e => setLFD(e.target.checked)}
                    checked={LFD === true}
                  />
                  <Typography variant="caption">LastFree</Typography>
                  <Checkbox
                    onChange={e => setSTORE(e.target.checked)}
                    checked={STORE === true}
                  />
                  <Typography variant="caption">Store</Typography>
                  <Checkbox
                    onChange={e => setN2A(e.target.checked)}
                    checked={N2A === true}
                  />
                  <Typography variant="caption">N2A</Typography>
                  <Checkbox
                    onChange={e => setROUTING(e.target.checked)}
                    checked={ROUTING === true}
                  />
                  <Typography variant="caption">Routing</Typography>
                  <Checkbox
                    onChange={e => setDELIVERY(e.target.checked)}
                    checked={DELIVERY === true}
                  />
                  <Typography variant="caption">History</Typography>

                  <Checkbox
                    onChange={e => setEMPTY(e.target.checked)}
                    checked={EMPTY === true}
                  />
                  <Typography variant="caption">Empty</Typography>
                  <Checkbox
                    onChange={e => setINVOICE(e.target.checked)}
                    checked={INVOICE === true}
                  />
                  <Typography variant="caption">Invoice</Typography>

                </div>
              </Grid>

              <Grid container spacing={5} direction="row" alignItems="flex-end" justifyContent="flex-end">
                <Grid item>
                  {Error ? (
                    <Typography className={classes.errorText} component="h5" variant="h6">
                      {serverMessage}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item>
                  <Button type="submit" variant="outlined" size="large" className={classes.button} onClick={submitUpdate}>
                    Update
                  </Button>
                </Grid>
                <Grid item>
                  <Button disabled={!disable} type="submit" variant="outlined" size="large" className={classes.button} onClick={cleanData}>
                    Archive Data
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );

  function formIsValid() {
    if (password === passwordConfirmation) {
      return true;
    }
  }

  async function cleanData() {
  }

  async function submitUpdate() {
    const userObj = { phone, address, ALL, TODO, ETA, LFD, STORE, ROUTING, DELIVERY, N2A, EMPTY, INVOICE };
    let passwordFailedToUpdate = false;
    if (password !== "") {
      if (!formIsValid()) {
        setServerMessage('Passwords do not match');
        return;
      }
      await auth.currentUser.updatePassword(passwordConfirmation).then(function () {
      }).catch(function (error) {
        setServerMessage('' + error);
        passwordFailedToUpdate = true;
      });
    }

    if (!passwordFailedToUpdate) {
      Helper.handleUpdate('users', currentUser.email, userObj,
        function () {
          setServerMessage('User Updated');
          navigate('/user');
          console.log('user updated');
        },
        function (dbErr) {
          console.log('Failed to add user to the database: ', dbErr);
          setServerMessage('Failed To Update User');
        });
    }
  }
}
export default withStyles(styles)(Account);
