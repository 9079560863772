import React from "react";

let initialState = null;

const SearchResultContext = React.createContext(null);

function reducer(state, action) {
  switch (action.type) {
    case "UPDATE_SEARCH_RESULT":
      return action.searchResult;

    case "CLEAR_SEARCH_RESULT":
      return initialState;

    case "UPDATE_SEARCH_RESULT_ENTRY":
      const newData = action.new;
      const oldData = action.old;
      const index = state.indexOf(oldData);
      return [
        ...state.slice(0, index), 
        newData,
        ...state.slice(index + 1)
      ];
    default:
      return state;
  }
}

function SearchResultProvider(props) {
  let [searchResult, dispatchSearchResult] = React.useReducer(reducer, initialState);
  let value = { searchResult, dispatchSearchResult };

  return <SearchResultContext.Provider value={value}>{props.children}</SearchResultContext.Provider>;
}

let SearchResultConsumer = SearchResultContext.Consumer;

export { SearchResultContext, SearchResultProvider, SearchResultConsumer };
