import React, { useState, useEffect, useContext } from "react";

//HOC CSS
import styles from "./styles";

//material ui
import { withStyles, AppBar, Typography,
  Tabs,  Tab, Box, Container, TextField,
  Button, Grid, Backdrop, CircularProgress} from "@material-ui/core";

import PropTypes from "prop-types";

//firebase
//import { firestore, auth, createUserDocument } from "../../firebase";
import { firestore } from "../../firebase";

//components
import SearchResult from "../searchResult/searchResult";
import ResultFieldSetting from "../resultFieldSetting/resultFieldSetting";

//current searchResult context hook
import { SearchResultContext } from "../../contexts/searchResultProvider";
import { CurrentUserContext } from '../../contexts/currentUserProvider';
import { ResultFieldSettingContext } from "../../contexts/resultFieldSettingProvider";

import Helper from '../helper.js';

function ReportSearchForm(props) {
  const classes = props.classes;
  const currentUser = useContext(CurrentUserContext);
  const { resultFieldSetting } = useContext(ResultFieldSettingContext);

  // hook for deliver order info
  const [cid, setCid] = useState("");
  const [branch, setBranch] = useState("");
  const [customer, setCustomer] = useState("");
  const [delivery, setDelivery] = useState("");
  const [portOfOrigin, setPortOfOrigin] = useState("");
  const [location, setLocation] = useState("");

  const [masterBL, setMasterBL] = useState("");
  const [containerNumber, setContainerNumber] = useState("");
  const [containerType, setContainerType] = useState("");
  const [driver, setDriver] = useState("");
  const [chassisInfo, setChassisInfo] = useState("");
  const [eta, setEta] = useState("");
  const [pickUpDate, setPickUpDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [apptDate, setApptDate] = useState("");
  const [lastFreeDay, setLastFreeDay] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [toDoDay, setToDoDay] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [etaEnd, setEtaEnd] = useState("");
  const [pickUpDateEnd, setPickUpDateEnd] = useState("");
  const [deliveryDateEnd, setDeliveryDateEnd] = useState("");
  const [apptDateEnd, setApptDateEnd] = useState("");
  const [lastFreeDayEnd, setLastFreeDayEnd] = useState("");
  const [returnDateEnd, setReturnDateEnd] = useState("");
  const [Error, setError] = useState(false);
  const [branchSuggestions, setBranchSuggestions] = useState([]);
  const [customerSuggestions, setCustomerSuggestions] = useState([]);
  const [deliverySuggestions, setDeliverySuggestions] = useState([]);
  const [portSuggestions, setPortSuggestions] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [driverSuggestions, setDriverSuggestions] = useState([]);

  const now = new Date();
  const today = new Date(now.getFullYear() + "/" + (now.getMonth() + 1) + "/" + now.getDate())
  const newStartDate = new Date();
  const newEndDate = new Date();
  newStartDate.setDate(today.getDate() - (30 * 6));
  newEndDate.setDate(today.getDate() + 1);

  const [createDate, setCreateDate] = useState(newStartDate.toISOString().split('T')[0]);
  const [createDateEnd, setCreateDateEnd] = useState(newEndDate.toISOString().split('T')[0]);
  const [open, setOpen] = React.useState(false);

  const { dispatchSearchResult } = useContext(SearchResultContext);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };



  return (
    <Container maxWidth={false} >
      <Grid container spacing={0} direction="row" alignItems="flex-start" justifyContent="flex-start"></Grid>
      <Grid item >
        <ResultFieldSetting id="resultFieldSetting" />
      </Grid>
      <Grid item >
        <Typography className={classes.reportHeaderText} variant="h6">
          Please enter more than one field other than the date field when using date range search.
      </Typography>
      </Grid>
      <div className={classes.reportForm}>
        <Grid container spacing={1} direction="row" justifyContent="flex-end">
          {/* right section */}
          <Grid item xs={12} md={6} className={classes.reportFormSection}>
            <TextField
              id="report-cid-input"
              label="Reference #"
              type="text"
              value={cid}
              onChange={e => setCid(e.target.value)}
              className={classes.field}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submitDeliverOrderSearch();
                }
              }}
              variant="outlined"
            />

            <TextField
              id="report-branch-input"
              label="Branch"
              type="text"
              value={branch}
              onClick={e => {
                Helper.handleInfoQuery("branch", setBranchSuggestions, null, resultFieldSetting.view);
              }}
              onChange={e => setBranch(e.target.value)}
              className={classes.field}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submitDeliverOrderSearch();
                }
              }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <datalist id="branch">
                    {branchSuggestions.map(suggest => (
                      <option key={suggest[1]}>{suggest[0]}</option>
                    ))}
                  </datalist>
                ),
                inputProps: {
                  list: "branch"
                }
              }}
            />

            <TextField
              id="report-customer-input"
              label="Customer"
              type="text"
              value={customer}
              onClick={e => {
                Helper.handleInfoQuery("customerAddress", setCustomerSuggestions, null, resultFieldSetting.view);
              }}
              onChange={e => setCustomer(e.target.value)}
              className={classes.field}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submitDeliverOrderSearch();
                }
              }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <datalist id="custumer">
                    {customerSuggestions.map(suggest => (
                      <option key={suggest[1]}>{suggest[0]}</option>
                    ))}
                  </datalist>
                ),
                inputProps: {
                  list: "custumer"
                }
              }}
            />

            <TextField
              id="report-delivery-input"
              label="Delivery To"
              type="text"
              value={delivery}
              onClick={e => {
                Helper.handleInfoQuery("deliveryAddress", setDeliverySuggestions, null, resultFieldSetting.view);
              }}
              onChange={e => setDelivery(e.target.value)}
              className={classes.field}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submitDeliverOrderSearch();
                }
              }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <datalist id="delivery">
                    {deliverySuggestions.map(suggest => (
                      <option key={suggest[1]}>{suggest[0]}</option>
                    ))}
                  </datalist>
                ),
                inputProps: {
                  list: "delivery"
                }
              }}
            />

            <TextField
              id="report-portOfOrgin-input"
              label="Port Of Orgin"
              type="text"
              value={portOfOrigin}
              onClick={e => {
                Helper.handleInfoQuery("portAddress", setPortSuggestions, null, resultFieldSetting.view);
              }}
              onChange={e => setPortOfOrigin(e.target.value)}
              className={classes.field}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submitDeliverOrderSearch();
                }
              }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <datalist id="portOfOrigin">
                    {portSuggestions.map(suggest => (
                      <option key={suggest[1]}>{suggest[0]}</option>
                    ))}
                  </datalist>
                ),
                inputProps: {
                  list: "portOfOrigin"
                }
              }}
            />

            <TextField
              id="report-location-input"
              label="Location"
              type="text"
              value={location}
              onClick={e => {
                Helper.handleInfoQuery("locationAddress", setLocationSuggestions, null, resultFieldSetting.view);
              }}
              onChange={e => setLocation(e.target.value)}
              className={classes.field}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submitDeliverOrderSearch();
                }
              }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <datalist id="location">
                    {locationSuggestions.map(suggest => (
                      <option key={suggest[1]}>{suggest[0]}</option>
                    ))}
                  </datalist>
                ),
                inputProps: {
                  list: "location"
                }
              }}
            />

            <TextField
              id="report-masterBL-input"
              label="Master BL"
              type="text"
              value={masterBL}
              onChange={e => setMasterBL(e.target.value)}
              className={classes.field}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submitDeliverOrderSearch();
                }
              }}
              variant="outlined"
            />

            <Grid container spacing={0} direction="row" alignItems="flex-end">
              <Grid item xs={6} md={6}>
                <TextField
                  id="report-createDate-input"
                  label="Create Date"
                  type="date"
                  value={createDate}
                  onChange={e => setCreateDate(e.target.value)}
                  className={classes.fieldDate}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      submitDeliverOrderSearch();
                    }
                  }}
                  InputLabelProps={{ shrink: true}}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  id="report-createDateEnd-input"
                  type="date"
                  value={createDateEnd}
                  onChange={e => setCreateDateEnd(e.target.value)}
                  className={classes.fieldDate}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      submitDeliverOrderSearch();
                    }
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          {/* left section */}
          <Grid item xs={12} md={6} className={classes.reportFormSection} >
            <Grid item  >
              <TextField
                id="report-containerNumber-input"
                label="Container #"
                type="text"
                value={containerNumber}
                onChange={e => setContainerNumber(e.target.value)}
                className={classes.field}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    submitDeliverOrderSearch();
                  }
                }}
                variant="outlined"
              />

              <TextField
                id="report-containerType-input"
                label="Container Type"
                type="text"
                value={containerType}
                onChange={e => setContainerType(e.target.value)}
                className={classes.field}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    submitDeliverOrderSearch();
                  }
                }}
                variant="outlined"
              />

              <TextField
                id="report-chassisInfo-input"
                label="Chassis Info"
                type="text"
                value={chassisInfo}
                onChange={e => setChassisInfo(e.target.value)}
                className={classes.field}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    submitDeliverOrderSearch();
                  }
                }}
                variant="outlined"
              />

              <TextField
                id="report-containerDriver-input"
                label="Delivery Driver"
                type="text"
                value={driver}
                onClick={e => {
                  Helper.handleInfoQuery("drivers", setDriverSuggestions, true, resultFieldSetting.view);
                }}
                onChange={e => setDriver(e.target.value)}
                className={classes.field}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    submitDeliverOrderSearch();
                  }
                }}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <datalist id="driver">
                      {driverSuggestions.map(suggest => (
                        <option key={suggest[1]}>{suggest[0]}</option>
                      ))}
                    </datalist>
                  ),
                  inputProps: {
                    list: "driver"
                  }
                }}
              />
            </Grid>
            <Grid item >
              <Grid container spacing={0} direction="row" alignItems="flex-end">
                <Grid item xs={6} md={6}>
                  <TextField
                    id="report-eta-input"
                    label="ETA"
                    type="date"
                    value={eta}
                    onChange={e => setEta(e.target.value)}
                    className={classes.fieldDate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submitDeliverOrderSearch();
                      }
                    }}
                    InputLabelProps={{ shrink: true}}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    id="report-etaEnd-input"
                    type="date"
                    value={etaEnd}
                    onChange={e => setEtaEnd(e.target.value)}
                    className={classes.fieldDate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submitDeliverOrderSearch();
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={0} direction="row" alignItems="flex-end">
                <Grid item xs={6} md={6}>
                  <TextField
                    id="report-lastFreeDay-input"
                    label="Last Free Day"
                    type="date"
                    value={lastFreeDay}
                    onChange={e => setLastFreeDay(e.target.value)}
                    className={classes.fieldDate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submitDeliverOrderSearch();
                      }
                    }}
                    InputLabelProps={{ shrink: true}}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    id="report-lastFreeDayEnd-input"
                    type="date"
                    value={lastFreeDayEnd}
                    onChange={e => setLastFreeDayEnd(e.target.value)}
                    className={classes.fieldDate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submitDeliverOrderSearch();
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={0} direction="row" alignItems="flex-end">
                <Grid item xs={6} md={6}>
                  <TextField
                    id="report-pickUpDate-input"
                    label="Pick up Date"
                    type="date"
                    value={pickUpDate}
                    onChange={e => setPickUpDate(e.target.value)}
                    className={classes.fieldDate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submitDeliverOrderSearch();
                      }
                    }}
                    InputLabelProps={{ shrink: true}}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    id="report-pickUpDateEnd-input"
                    type="date"
                    value={pickUpDateEnd}
                    onChange={e => setPickUpDateEnd(e.target.value)}
                    className={classes.fieldDate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submitDeliverOrderSearch();
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={0} direction="row" alignItems="flex-end">
                <Grid item xs={6} md={6}>
                  <TextField
                    id="report-appointmentDate-input"
                    label="Appointment Date"
                    type="date"
                    value={apptDate}
                    onChange={e => setApptDate(e.target.value)}
                    className={classes.fieldDate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submitDeliverOrderSearch();
                      }
                    }}
                    InputLabelProps={{ shrink: true}}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    id="report-apptDateEnd-input"
                    type="date"
                    value={apptDateEnd}
                    onChange={e => setApptDateEnd(e.target.value)}
                    className={classes.fieldDate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submitDeliverOrderSearch();
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={0} direction="row" alignItems="flex-end">
                <Grid item xs={6} md={6}>
                  <TextField
                    id="report-deliveryDate-input"
                    label="Delivery Date"
                    type="date"
                    value={deliveryDate}
                    onChange={e => setDeliveryDate(e.target.value)}
                    className={classes.fieldDate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submitDeliverOrderSearch();
                      }
                    }}
                    InputLabelProps={{ shrink: true}}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    id="report-deliveryDateEnd-input"
                    type="date"
                    value={deliveryDateEnd}
                    onChange={e => setDeliveryDateEnd(e.target.value)}
                    className={classes.fieldDate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submitDeliverOrderSearch();
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={0} direction="row" alignItems="flex-end">
                <Grid item xs={6} md={6}>
                  <TextField
                    id="report-returnDate-input"
                    label="Return Date"
                    type="date"
                    value={returnDate}
                    onChange={e => setReturnDate(e.target.value)}
                    className={classes.fieldDate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submitDeliverOrderSearch();
                      }
                    }}
                    InputLabelProps={{ shrink: true}}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    id="report-returnDateEnd-input"
                    type="date"
                    value={returnDateEnd}
                    onChange={e => setReturnDateEnd(e.target.value)}
                    className={classes.fieldDate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submitDeliverOrderSearch();
                      }
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>

          {/* end of left panel */}
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="inherit"
              className={classes.button}
              onClick={submitDeliverOrderSearch}
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={4}>
            {Error ? (
              <Typography className={classes.errorText} component="h5" variant="h6">
                {Error}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </div>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );

  function cleanSearchObj(obj) {
    for (var propName in obj) {
      if (obj[propName] === "" || obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }
  async function submitDeliverOrderSearch() {
    var searchObj = {
      cid: cid?.trim(),
      branch: branch,
      c_Eta: eta,
      customer: customer?.trim(),
      deliveryTo: delivery?.trim(),
      portOfOrigin: portOfOrigin?.trim(),
      location: location?.trim(),
      masterBL: masterBL?.trim(),
      c_Num: containerNumber?.trim(),
      c_Type: containerType?.trim(),
      c_Chassis: chassisInfo?.trim(),
      c_Driver: driver?.trim(),
      c_PickUpDate: pickUpDate,
      c_DeliveryDate: deliveryDate,
      c_ApptDate: apptDate,
      c_LastFree: lastFreeDay,
      c_toDoDay: toDoDay,
      c_Return: returnDate
    };
    setError(false);
    cleanSearchObj(searchObj);

    if (!cid && !branch && !eta && !customer && !masterBL && !delivery && !portOfOrigin && !location && !containerNumber && !containerType && !driver && !chassisInfo && !pickUpDate && !apptDate && !deliveryDate && !lastFreeDay && !returnDate && !createDate) {
      setError("Search Field is Empty");
    } else {
      let rangeKey = "";
      let ingoreKey = "";
      let conatinerRange = [];
      if (etaEnd || pickUpDateEnd || apptDateEnd || deliveryDateEnd || lastFreeDayEnd || returnDateEnd) {
        var startDate = "";
        var endDate = "";
        if (etaEnd) {
          if (eta) {
            if (startDate || endDate) {
              setError("Only one date range search is allowed");
              return;
            }
            startDate = eta;
            endDate = etaEnd;
            rangeKey = "eta";
            ingoreKey = "c_Eta";
          } else {
            setError("Missing eta start date");
            return;
          }
        }
        if (pickUpDateEnd) {
          if (pickUpDate) {
            if (startDate || endDate) {
              setError("Only one date range search is allowed");
              return;
            }
            startDate = pickUpDate;
            endDate = pickUpDateEnd;
            rangeKey = "pickUpDate";
            ingoreKey = "c_PickUpDate";
          } else {
            setError("Missing pick up start date");
            return;
          }
        }
        if (apptDateEnd) {
          if (apptDate) {
            if (startDate || endDate) {
              setError("Only one date range search is allowed");
              return;
            }
            startDate = apptDate;
            endDate = apptDateEnd;
            rangeKey = "apptDate";
            ingoreKey = "c_ApptDate";
          } else {
            setError("Missing appointment start date");
            return;
          }
        }
        if (deliveryDateEnd) {
          if (deliveryDate) {
            if (startDate || endDate) {
              setError("Only one date range search is allowed");
              return;
            }
            startDate = deliveryDate;
            endDate = deliveryDateEnd;
            rangeKey = "deliveryDate";
            ingoreKey = "c_DeliveryDate";
          } else {
            setError("Missing delivery start date");
            return;
          }
        }
        if (lastFreeDayEnd) {
          if (lastFreeDay) {
            if (startDate || endDate) {
              setError("Only one date range search is allowed");
              return;
            }
            startDate = lastFreeDay;
            endDate = lastFreeDayEnd;
            rangeKey = "lastFreeDay";
            ingoreKey = "c_LastFree";
          } else {
            setError("Missing last Free start date");
            return;
          }
        }
        if (returnDateEnd) {
          if (returnDate) {
            if (startDate || endDate) {
              setError("Only one date range search is allowed");
              return;
            }
            startDate = returnDate;
            endDate = returnDateEnd;
            rangeKey = "returnDate";
            ingoreKey = "c_Return";
          } else {
            setError("Missing return start date");
            return;
          }
        }

        if (endDate < startDate) {
          setError("Invalid date range entered: end date is before start date");
          return;
        }
      }

      if ((createDateEnd === "" && createDate !== "") || (createDate === "" && createDateEnd !== "")) {
        setError("Invalid date range entered: Missing createDate");
        return;
      }
      if (createDateEnd < createDate) {
        setError("Invalid date range entered: createDate end date is before start date");
        return;
      }

      var result = [];
      let validFilter = false;
      let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
      var deliverOrdersRef = firestore.collection("deliverOrders" + branchLabel);

      for (var key in searchObj) {
        if (searchObj.hasOwnProperty(key)) {
          if (key === "masterBL" || key === "cid") {
            deliverOrdersRef = deliverOrdersRef.where(`${key}`, "==", `${searchObj[key]}`);
            validFilter = true;
          } else if (key === "branch" || key === "customer" || key === "deliveryTo" || key === "location" || key === "portOfOrigin" || key === "masterBL") {
            deliverOrdersRef = deliverOrdersRef.where(`${key}.label`, "==", `${searchObj[key]}`);
            validFilter = true;
          } else {
            if (key !== ingoreKey) {
              deliverOrdersRef = deliverOrdersRef.where(`${key}`, "array-contains", `${searchObj[key]}`);
              validFilter = true;
            }
          }
        }
      }

      if (!validFilter) {
        setError("Invalid date range search: Need more field other than date range");
        return;
      } else if (rangeKey) {
        var containerRangeRef = firestore.collection("containers" + branchLabel);
        let newEndDate = new Date(endDate.replace("-","/"));
        newEndDate.setDate(newEndDate.getDate() + 1);
        newEndDate.setSeconds(newEndDate.getSeconds() - 10);
        containerRangeRef = containerRangeRef
          .orderBy(rangeKey)
          .startAt(new Date(startDate.replace("-","/")))
          .endAt(new Date(newEndDate));
        await containerRangeRef.get().then(function (snapshot) {
          console.log("Got query results: " + snapshot.size + " documents");
          snapshot.forEach(function (doc) {
            conatinerRange.push(doc.id);
          });
        });
      }

      handleToggle();
      var hasLimit = false;
      if ((currentUser.role !== "admin" && currentUser.role !== "manager" && createDateEnd === "" && createDate === "")) {
        deliverOrdersRef = deliverOrdersRef.limit(1000);
        hasLimit = true;
      }

      deliverOrdersRef
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
          } else {
            if (hasLimit){
              hasLimit = snapshot.size === 1000;
            }
            snapshot.forEach(doc => {
              let container = doc.data().c_Num;
              if (container === undefined) {
                if (rangeKey === "") {
                  // dont push the empty container on range search
                  let orderCreatedAtDate = doc.data().createdAt.toDate();
                  let orderCreatedDate = orderCreatedAtDate.toJSON().substring(0, 10)
                  if ((createDateEnd === "" && createDate === "") ||
                    (orderCreatedDate >= createDate && orderCreatedDate <= createDateEnd)) {
                    result.push({
                      docRef: doc.id,
                      cid: doc.data().cid,
                      branch: Helper.getLabel(doc.data().branch),
                      customer: Helper.getLabel(doc.data().customer),
                      deliveryTo: Helper.getLabel(doc.data().deliveryTo),
                      carrier: Helper.getLabel(doc.data().carrier),
                      driver: Helper.getLabel(doc.data().driver),
                      masterBL: doc.data().masterBL,
                      location: Helper.getLabel(doc.data().location),
                      createDate: getDate(doc.data().createdAt)
                    });
                  }
                }
              } else {
                for (let index = 0; index < container.length; index++) {
                  let filterContainer = true;
                  if (containerNumber !== "" && containerNumber !== doc.data().c_Num[index]) {
                    filterContainer = filterContainer && false;
                  }
                  if (containerType !== "" && containerType !== doc.data().c_Type[index]) {
                    filterContainer = filterContainer && false;
                  }
                  if (chassisInfo !== "" && chassisInfo !== doc.data().c_Chassis[index]) {
                    filterContainer = filterContainer && false;
                  }
                  if (driver !== "" && driver !== doc.data().c_Driver[index]) {
                    filterContainer = filterContainer && false;
                  }
                  if (rangeKey) {
                    filterContainer = filterContainer && conatinerRange.indexOf(doc.data().c_Ref[index]) !== -1;
                  }

                  if (filterContainer) {
                    let orderCreatedAtDate = doc.data().createdAt.toDate();
                    let orderCreatedDate = orderCreatedAtDate.toJSON().substring(0, 10)
                    if ((createDateEnd === "" && createDate === "") ||
                      (orderCreatedDate >= createDate && orderCreatedDate <= createDateEnd)) {
                      let obj = {
                        ref: doc.data().c_Ref[index],
                        docRef: doc.id,
                        cid: doc.data().cid,
                        branch: Helper.getLabel(doc.data().branch),
                        customer: Helper.getLabel(doc.data().customer),
                        deliveryTo: Helper.getLabel(doc.data().deliveryTo),
                        portOfOrigin: Helper.getLabel(doc.data().portOfOrigin),
                        carrier: Helper.getLabel(doc.data().carrier),
                        masterBL: doc.data().masterBL,
                        location: Helper.getLabel(doc.data().location),
                        comment: doc.data().comment,
                        createDate: getDate(doc.data().createdAt),
                        container: doc.data().c_Num[index],
                        chassisInfo: doc.data().c_Chassis ? doc.data().c_Chassis[index] : "",
                        containerType: doc.data().c_Type ? doc.data().c_Type[index] : "",
                        pickUpNumber: doc.data().c_PickUpNum ? doc.data().c_PickUpNum[index] : "",
                        driver: doc.data().c_Driver ? doc.data().c_Driver[index] : "",
                        eta: doc.data().c_Eta ? doc.data().c_Eta[index] : "",
                        appointmentDate: doc.data().c_ApptDate ? doc.data().c_ApptDate[index] : "",
                        appointmentTime: doc.data().c_ApptTime ? doc.data().c_ApptTime[index] : "",
                        pickUpDate: doc.data().c_PickUpDate ? doc.data().c_PickUpDate[index] : "",
                        deliveryDate: doc.data().c_DeliveryDate ? doc.data().c_DeliveryDate[index] : "",
                        lastFreeDay: doc.data().c_LastFree ? doc.data().c_LastFree[index] : "",
                        toDoDay: doc.data().c_toDoDay ? doc.data().c_toDoDay[index] : "",
                        returnDate: doc.data().c_Return ? doc.data().c_Return[index] : "",
                        weight: doc.data().weight ? doc.data().weight : "",
                        packages: doc.data().packages ? doc.data().packages : "",
                        export: doc.data().export ? doc.data().export : "",
                        articale: doc.data().articale ? doc.data().articale : "",
                        invoiced: doc.data().invoiced ? doc.data().invoiced : "",
                        overWeight: doc.data().c_overWeight ? ""+ doc.data().c_overWeight[index] : "",
                        sealNumber: doc.data().c_sealNumber ? doc.data().c_sealNumber[index] : "",
                        outSource: doc.data().c_outSource? doc.data().c_outSource[index] : "",
                        prePull: doc.data().c_prePull ? ""+ doc.data().c_prePull[index] : "",
                        storage: doc.data().c_storage ? ""+ doc.data().c_storage[index] : "",
                        pickupDriver: doc.data().c_pickupDriver ? doc.data().c_pickupDriver[index] : "",
                        returnDriver: doc.data().c_returnDriver ? doc.data().c_returnDriver[index] : "",
                        storageDay: doc.data().c_storageDay ? doc.data().c_storageDay[index] : "",
                        chassisDay: doc.data().c_chassisDay ? doc.data().c_chassisDay[index] : "",
                        bobTail: doc.data().c_bobTail ? doc.data().c_bobTail[index] : "",
                        chassisSplit: doc.data().c_chassisSplit ? doc.data().c_chassisSplit[index] : "",
                        chassisSplitFee: doc.data().c_chassisSplitFee ? doc.data().c_chassisSplitFee[index] : "",
                        containerComment: doc.data().c_containerComment ? doc.data().c_containerComment[index] : "",
                        cLocation: doc.data().c_cLocation ? doc.data().c_cLocation[index] : "",
                        billNote: doc.data().c_billNote ? doc.data().c_billNote[index] : "",
                        apptNote: doc.data().c_apptNote ? doc.data().c_apptNote[index] : "",
                        freeChassis: doc.data().c_freeChassis ? doc.data().c_freeChassis[index] : "",
                        apptDone: doc.data().c_apptDone ? doc.data().c_apptDone[index] : "",
                        apptReq: doc.data().c_apptReq ? doc.data().c_apptReq[index] : "",
                        hold: doc.data().c_hold ? doc.data().c_hold[index] : "",
                        emptyReadyDate: doc.data().c_emptyReadyDate ? doc.data().c_emptyReadyDate[index] : "",
                        mod: doc.data().mod ? doc.data().mod : 0
                      }

                      let contObj = obj;
                      contObj.number = doc.data().c_Num[index];
                      contObj.state = doc.data().state;
                      contObj.shortDetail = Helper.getShortDetail(doc.data(),contObj, false, index);
                      let shortDetail = contObj.shortDetail;
                      const itemInfo = shortDetail.replace("CHI-", "").split("-");
                      const item = itemInfo[1].split(",");
                      obj.shortDetail = item[0] + ", " + item[1];
                      result.push(obj);
                    }
                  }
                }
              }
            });
          }

          let sortResult = result.sort(function(a, b) {
            var nameA = a.cid.toUpperCase(); // ignore upper and lowercase
            var nameB = b.cid.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });


          if (hasLimit){
            let obj = { docRef: -1}
            sortResult.push(obj);
          }
          dispatchSearchResult({
            type: "UPDATE_SEARCH_RESULT",
            searchResult: sortResult
          });
        })
        .catch(err => {
          setError("Error getting documents");
          console.log("Error getting documents", err);
          handleClose();
        });
      //}
    }
  }
}

function getDate(field) {
  if (field) {
    return field.toDate().toDateString();
  }
  return "";
}
// Operation components
function Report(props) {
  // classes porps for styling
  const { classes } = props;

  const [result, setResult] = useState(false);
  const [value, setValue] = React.useState(0);

  // searchResult context
  const { searchResult } = useContext(SearchResultContext);

  useEffect(() => {
    searchResult ? setResult(true) : setResult(false);
  }, [searchResult]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div className={classes.root}>
      <Container maxWidth={false} >
      <AppBar position="static" color="transparent">
        <Tabs value={value} onChange={handleChange} className={classes.tab} >
          <Tab label="Delivery Order" {...a11yProps(0)} className={classes.tabLabel} />
        </Tabs>
      </AppBar>

        <TabPanel value={value} index={0} className={classes.tabContent}>
          {result && searchResult ? (
              <SearchResult setResult={setResult} />
          ) : (
              <ReportSearchForm classes={classes} />
            )}
        </TabPanel>
      </Container>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default withStyles(styles)(Report);
