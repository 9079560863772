import React, { Component, createContext } from "react";
import { firestore } from "../firebase";

export const PLANITEM_HISTORY_CONTEXT_CHI = createContext();

class PlanItemHISTORYProvider_chi extends Component {
  state = { history_planItems: undefined };

  unsubscribe = null;

  componentDidMount = () => {
    const minus_six_month = new Date(new Date().setDate(new Date().getDate() - (31 * 6)));
    this.unsubscribe = firestore.collection("containers_chi")
    .where('deliveryDate', '>', minus_six_month)
    .where("state", "in", [ "d", "r"])
    .onSnapshot(snapshot => {
      const containerObjs = snapshot.docs.map(doc => {
        return { id: doc.id, routingContainerState: "h", ...doc.data()};
      });

      let notInvoicedList = containerObjs.filter(function (obj) {
        return obj.deliveryDate !== null;
      });

      notInvoicedList.sort(
        (a, b) => {
          return a.deliveryDate._compareTo(b.deliveryDate)
        }
      );

      this.setState({ history_planItems : notInvoicedList });
    });
  };

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  render() {
    const { history_planItems } = this.state;
    const { children } = this.props;

    return <PLANITEM_HISTORY_CONTEXT_CHI.Provider value={history_planItems}>{children}</PLANITEM_HISTORY_CONTEXT_CHI.Provider>;
  }
}

export default PlanItemHISTORYProvider_chi;
