import React, { useContext } from "react";
//Reach Router
import { Router } from "@reach/router";
import { createBrowserHistory } from "history";
//CSS
import "./App.css";
//compoenents
import AppLogout from "./components/autologout";
import Account from "./components/account/account";
import AccountIndex from "./components/accountIndex/accountIndex";
import Dashboard from "./components/dashboard/dashboard";
import Login from "./components/login/login";
import Landing from "./components/landing/landing";
import Report from "./components/report/report";
// import Planning from "./components/planning/planning";
import Operation from "./components/operation/operation";
import Signup from "./components/signup/signup";
import Header from "./components/header/header";
import PlanningHeader from "./components/planningheader/planningheader";
import PlanItem from "./components/planItem/planItem";
import PlanItem2 from "./components/planItem/planItem2";
import RoutingCalender from "./components/routingCalender/routingCalender";

//current user context hook
import { CurrentUserContext } from './contexts/currentUserProvider';

// context provider
import UsersProvider from "./contexts/usersProvider";
import { ContainerProvider } from "./contexts/containerProvider";
import { DeliveryOrderProvider } from "./contexts/deliveryOrderProvider";
import PlanItemETAProvider from "./contexts/planItem_eta_Provider";
import PlanItemLFDProvider from "./contexts/planItem_lfd_Provider";
import PlanItemToDoProvider from "./contexts/planItem_todo_Provider";
import PlanItemPICKUPProvider from "./contexts/planItem_pickup_Provider";
import PlanItemAPPProvider from "./contexts/planItem_app_Provider";
import PlanItemN2AProvider from "./contexts/planItem_n2a_Provider";
import PlanItemHISTORYProvider from "./contexts/planItem_history_Provider";
import PlanItemDELIVERYProvider from "./contexts/planItem_delivery_Provider";
import PlanItemRETURNProvider from "./contexts/planItem_return_Provider";
import PlanItemETAProviderCHI from "./contexts/planItem_eta_Provider_chi";
import PlanItemLFDProviderCHI from "./contexts/planItem_lfd_Provider_chi";
import PlanItemToDoProviderCHI from "./contexts/planItem_todo_Provider_chi";
import PlanItemPICKUPProviderCHI from "./contexts/planItem_pickup_Provider_chi";
import PlanItemAPPProviderCHI from "./contexts/planItem_app_Provider_chi";
import PlanItemN2AProviderCHI from "./contexts/planItem_n2a_Provider_chi";
import PlanItemHISTORYProviderCHI from "./contexts/planItem_history_Provider_chi";
import PlanItemDELIVERYProviderCHI from "./contexts/planItem_delivery_Provider_chi";
import PlanItemRETURNProviderCHI from "./contexts/planItem_return_Provider_chi";


function DisplayAccountIndex() {
  return (
    <UsersProvider>
      <AccountIndex />
    </UsersProvider>
  );
}

function DisplayAccount() {
  return (
    <UsersProvider>
      <Account />
    </UsersProvider>
  );
}

function DisplayOperation(props) {
  return (
    <DeliveryOrderProvider>
      <ContainerProvider>
        <Operation docRef={props.docRef} />
      </ContainerProvider>
    </DeliveryOrderProvider>
  );
}

function DisplayReport(props) {
  return (
    <DeliveryOrderProvider>
      <ContainerProvider>
        <Report />
      </ContainerProvider>
    </DeliveryOrderProvider>
  );
}

function DisplayPlanningItemEmpty(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
          <PlanItemDELIVERYProvider>
              <PlanItem2 routingContainerState="delivery" />
          </PlanItemDELIVERYProvider>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayPlanningItemETA(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
          <PlanItemETAProvider>
              <PlanItem routingContainerState="eta" />
          </PlanItemETAProvider>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayPlanningItemLFD(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
          <PlanItemLFDProvider>
              <PlanItem routingContainerState="lfd" />
          </PlanItemLFDProvider>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayPlanningItemPICKUP(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
          <PlanItemPICKUPProvider>
              <PlanItem routingContainerState="pickup" />
          </PlanItemPICKUPProvider>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayPlanningItemToDo(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
          <PlanItemToDoProvider>
              <PlanItem2 routingContainerState="todo" />
          </PlanItemToDoProvider>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayPlanningItemN2A(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
          <PlanItemN2AProvider>
              <PlanItem2 routingContainerState="n2a" />
          </PlanItemN2AProvider>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayRouting(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
          <PlanItemAPPProvider>
              <RoutingCalender routingContainerState={props.routingContainerState} />
          </PlanItemAPPProvider>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayRoutingHistory(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
          <PlanItemHISTORYProvider>
              <RoutingCalender routingContainerState={props.routingContainerState} isHistory={true} />
          </PlanItemHISTORYProvider>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayPlanningItemReturn(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
          <PlanItemRETURNProvider>
              <PlanItem routingContainerState="return" />
          </PlanItemRETURNProvider>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

//CHI

function DisplayPlanningItemEmptyCHI(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
            <PlanItemDELIVERYProviderCHI>
              <PlanItem2 routingContainerState="delivery" />
            </PlanItemDELIVERYProviderCHI>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayPlanningItemETACHI(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
            <PlanItemETAProviderCHI>
              <PlanItem routingContainerState="eta" />
            </PlanItemETAProviderCHI>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayPlanningItemLFDCHI(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
            <PlanItemLFDProviderCHI>
              <PlanItem routingContainerState="lfd" />
            </PlanItemLFDProviderCHI>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayPlanningItemPICKUPCHI(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
            <PlanItemPICKUPProviderCHI>
              <PlanItem routingContainerState="pickup" />
            </PlanItemPICKUPProviderCHI>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayPlanningItemToDoCHI(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
            <PlanItemToDoProviderCHI>
              <PlanItem2 routingContainerState="todo" />
            </PlanItemToDoProviderCHI>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayPlanningItemN2ACHI(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
            <PlanItemN2AProviderCHI>
              <PlanItem2 routingContainerState="n2a" />
            </PlanItemN2AProviderCHI>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayRoutingCHI(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
            <PlanItemAPPProviderCHI>
              <RoutingCalender routingContainerState={props.routingContainerState} />
            </PlanItemAPPProviderCHI>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayRoutingHistoryCHI(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
            <PlanItemHISTORYProviderCHI>
              <RoutingCalender routingContainerState={props.routingContainerState} isHistory={true} />
            </PlanItemHISTORYProviderCHI>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function DisplayPlanningItemReturnCHI(props) {
  return (
    <div>
      <PlanningHeader />
      <DeliveryOrderProvider>
        <ContainerProvider>
            <PlanItemRETURNProviderCHI>
              <PlanItem routingContainerState="return" />
            </PlanItemRETURNProviderCHI>
        </ContainerProvider>
      </DeliveryOrderProvider>
    </div>
  );
}

function App(props) {
  const currentUser = useContext(CurrentUserContext);
  const history = createBrowserHistory();
  return (
    <div className="app">
      <AppLogout>
        <Header />
        <Router history={history}>
          <Landing path="/" />
          <Dashboard path="/dashboard" />
          <DisplayAccountIndex path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager")) ? "/admin" : "/dashboard"} />
          <DisplayOperation path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member") !== -1)) ?
            "/operation" : "/dashboard"} />
          <DisplayOperation path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member") !== -1)) ?
            "/operation/:docRef" : "/dashboard"} />
          <DisplayAccount path={(currentUser !== null && currentUser !== undefined) ?
            "/user" : "/"} />
          <DisplayReport path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member") !== -1)) ?
            "/report" : "/dashboard"} />
          <DisplayPlanningItemETA path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/eta_ny" : "/dashboard"} >
          </DisplayPlanningItemETA>
          <DisplayPlanningItemLFD path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/lfd_ny" : "/dashboard"} >
          </DisplayPlanningItemLFD>
          <DisplayPlanningItemPICKUP path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/pickup_ny" : "/dashboard"} >
          </DisplayPlanningItemPICKUP>
          <DisplayPlanningItemEmpty path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/delivery_ny" : "/dashboard"} >
          </DisplayPlanningItemEmpty>
          <DisplayPlanningItemToDo path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/todo_ny" : "/dashboard"} >
          </DisplayPlanningItemToDo>
          <DisplayPlanningItemN2A path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/n2a_ny" : "/dashboard"} >
          </DisplayPlanningItemN2A>
          <DisplayRouting path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/routing_ny" : "/dashboard"} >
          </DisplayRouting>
          <DisplayRoutingHistory path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/routingHistory_ny" : "/dashboard"} >
          </DisplayRoutingHistory>
          <DisplayPlanningItemReturn path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/return_ny" : "/dashboard"} >
          </DisplayPlanningItemReturn>

          <DisplayPlanningItemETACHI path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/eta_chi" : "/dashboard"} >
          </DisplayPlanningItemETACHI>
          <DisplayPlanningItemLFDCHI path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/lfd_chi" : "/dashboard"} >
          </DisplayPlanningItemLFDCHI>
          <DisplayPlanningItemPICKUPCHI path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/pickup_chi" : "/dashboard"} >
          </DisplayPlanningItemPICKUPCHI>
          <DisplayPlanningItemEmptyCHI path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/delivery_chi" : "/dashboard"} >
          </DisplayPlanningItemEmptyCHI>
          <DisplayPlanningItemToDoCHI path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/todo_chi" : "/dashboard"} >
          </DisplayPlanningItemToDoCHI>
          <DisplayPlanningItemN2ACHI path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/n2a_chi" : "/dashboard"} >
          </DisplayPlanningItemN2ACHI>
          <DisplayRoutingCHI path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/routing_chi" : "/dashboard"} >
          </DisplayRoutingCHI>
          <DisplayRoutingHistoryCHI path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/routingHistory_chi" : "/dashboard"} >
          </DisplayRoutingHistoryCHI>
          <DisplayPlanningItemReturnCHI path={(currentUser !== null && currentUser !== undefined && currentUser.status === "active"
            && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1)) ?
            "/planning/return_chi" : "/dashboard"} >
          </DisplayPlanningItemReturnCHI>

          <Signup path="/signup_tct" />
          <Login path="/login" />
          <Login path="/inactive" />
        </Router>
      </AppLogout>
    </div>
  );
}
export default App;
