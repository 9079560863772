const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
    marginTop: '40px',
    overflowX: 'auto',
  },
  containerBox: {
    marginTop: "10px",
    border: "1px solid #FFFFFF",
    height: "70vh",
    overflow: "auto"
  },
  table: {
    height: "100px",
    minWidth: 650,
  },
  tableRowText: {
    fontSize: '20px',
    fontWeight: '800',
  },
  tableCell: {
    border: 'none',
    padding: "0px"
  },
  container: {
    minHeight: '100vh',
  },
  button: {
    marginTop: '20px',
    fontWeight: '700',
    backgroundColor: '#bab6b6',
    color: '#0070C0',
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
  },
});

export default styles;
