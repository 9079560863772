import React, {useState } from 'react';
//HOC CSS
import styles from './styles';
// reach router
import { navigate} from '@reach/router';
//material ui
import { withStyles, Container, Grid, Typography,
  Button, TextField } from "@material-ui/core";
//firebase
import { auth } from '../../firebase';

function Login(props) {
  const { classes } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [serverError, setServerError] = useState(false);
  const isInActive = props.path==="/inactive";

  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={classes.container}>
          <Grid item xs={12}>
            <TextField
              id="outlined-email"
              label="User Name"
              type="email"
              autoComplete="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className={classes.field}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-password"
              label="Password"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              className={classes.field}
              variant="filled"
            />
          </Grid>

          <Grid container spacing={5} direction="row" alignItems="center" justifyContent="center">
            <Grid item>
              <Button type="submit" variant="outlined" size="large" className={classes.button} onClick={submitLogin}>
                Log In
              </Button>
            </Grid>
            <Grid item>
              {/* <Button size="large" variant="outlined" className={classes.button} href="/signup">
                Sign Up
                </Button> */}
            </Grid>
          </Grid>
          {isInActive ? (
            <Typography className={classes.errorText} component="h5" variant="h6">
              Auto logout from inactive account for 30 minute
            </Typography>
          ) : null}
          {serverError ? (
            <Typography className={classes.errorText} component="h5" variant="h6">
              Incorrect Login Information
            </Typography>
          ) : null}
        </Grid>
      </Container>
    </div>
  );

  async function submitLogin() {
    // dispatchCache({ type: "UPDATE_LOGIN", login: true});
    await auth.signInWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in
      //const user = userCredential.user;
      console.log('log in submitted: ');
      navigate('/dashboard');
      // ...
    })
    .catch((err) => {
      setServerError(true);
      console.log('Error logging in: ', err);
    });
  }
}
export default withStyles(styles)(Login);
