const styles = theme => ({
  root: {
    width: "95%",
    padding: theme.spacing(1),
  },
  reportForm: {
    paddingLeft: "30px",
    paddingBottom: "30px"
  },
  reportFormSection: {
    borderRadius: '4px',
    //border: "1px solid #FFFFFF",
  },
  paper: {
    position: "absolute",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  tab: {
  },
  tabLabel: {
    fontWeight: "700"
  },
  tabContent: {
    height: "80vh",
    border: "1px solid rgba(0,0,0,0.23)",
    overflow: "auto",
  },
  container: {
    minHeight: "100vh",
    overflow: "scroll"
  },
  reportHeaderText: {
    marginTop: "5px",
    paddingLeft: "30px"
  },
  field: {
    borderRadius: '4px',
    margin: "5px",
    width: "95%",
  },
  fieldDate: {
    borderRadius: '4px',
    margin: "5px",
    width: "90%",
  },
  button: {
    marginTop: "20px",
    fontWeight: "700",
    backgroundColor: "#bab6b6",
    color: "#0070C0"
  },
  errorText: {
    color: "red",
    textAlign: "center",
    marginTop: "20px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

export default styles;
