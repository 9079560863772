import React from "react";

let initialState = {
  number: [],
  containerRefernce: [],
  containerNumber: [],
  chassisInfo: [],
  containerType: [],
  eta: [],
  pickUpDate: [],
  deliveryDate: [],
  lastFreeDay: [],
  toDoDay: [],
  toDoDone: [],
  returnDate: [],
  pickUpNumber: [],
  apptDate: [],
  apptTime: [],
  driver: [],
  sealNumber: [],
  outSource: [],
  overWeight: [],
  prePull: [],
  storage: [],
  pickupDriver: [],
  returnDriver: [],
  cLocation: [],
  selected: [],
  storageDay: [],
  chassisDay: [],
  bobTail: [],
  chassisSplit: [],
  chassisSplitFee: [],
  containerComment: [],
  billNote: [],
  apptNote: [],
  freeChassis: [],
  apptDone: [],
  apptReq: [],
  hold: [],
  emptyReadyDate: [],
};

const ContainerContext = React.createContext(null);

function reducer(state, action) {

  //need to backfill
  var numberOfContainer = state.containerRefernce.length;
  if (numberOfContainer !== 0) {

    if (state.containerNumber.length < numberOfContainer) {
      state.containerNumber = state.containerNumber.concat(Array(numberOfContainer-state.containerNumber.length).fill("MISSING DATA"));
    }

    if (state.number.length < numberOfContainer) {
      state.number = state.number.concat(Array(numberOfContainer-state.number.length).fill("MISSING DATA"));
    }

    if (state.sealNumber.length < numberOfContainer) {
      state.sealNumber = state.sealNumber.concat(Array(numberOfContainer-state.sealNumber.length).fill(""));
    }
    if (state.outSource.length < numberOfContainer) {
      state.outSource = state.outSource.concat(Array(numberOfContainer-state.outSource.length).fill(""));
    }
    if (state.overWeight.length < numberOfContainer) {
      state.overWeight = state.overWeight.concat(Array(numberOfContainer-state.overWeight.length).fill(false));
    }
    if (state.prePull.length < numberOfContainer) {
      state.prePull = state.prePull.concat(Array(numberOfContainer-state.prePull.length).fill(false));
    }
    if (state.storage.length < numberOfContainer) {
      state.storage = state.storage.concat(Array(numberOfContainer-state.storage.length).fill(false));
    }
    if (state.pickupDriver.length < numberOfContainer) {
      state.pickupDriver = state.pickupDriver.concat(Array(numberOfContainer-state.pickupDriver.length).fill(""));
    }
    if (state.returnDriver.length < numberOfContainer) {
      state.returnDriver = state.returnDriver.concat(Array(numberOfContainer-state.returnDriver.length).fill(""));
    }
    if (state.cLocation.length < numberOfContainer) {
      state.cLocation = state.cLocation.concat(Array(numberOfContainer-state.cLocation.length).fill(""));
    }
    if (state.storageDay.length < numberOfContainer) {
      state.storageDay = state.storageDay.concat(Array(numberOfContainer-state.storageDay.length).fill(""));
    }
    if (state.chassisDay.length < numberOfContainer) {
      state.chassisDay = state.chassisDay.concat(Array(numberOfContainer-state.chassisDay.length).fill(""));
    }
    if (state.bobTail.length < numberOfContainer) {
      state.bobTail = state.bobTail.concat(Array(numberOfContainer-state.bobTail.length).fill(""));
    }
    if (state.chassisSplit.length < numberOfContainer) {
      state.chassisSplit = state.chassisSplit.concat(Array(numberOfContainer-state.chassisSplit.length).fill(""));
    }
    if (state.chassisSplitFee.length < numberOfContainer) {
      state.chassisSplitFee = state.chassisSplitFee.concat(Array(numberOfContainer-state.chassisSplitFee.length).fill(""));
    }
    if (state.containerComment.length < numberOfContainer) {
      state.containerComment = state.containerComment.concat(Array(numberOfContainer-state.containerComment.length).fill(""));
    }
    if (state.toDoDay.length < numberOfContainer) {
      state.toDoDay = state.toDoDay.concat(Array(numberOfContainer-state.toDoDay.length).fill(""));
    }
    if (state.toDoDone.length < numberOfContainer) {
      state.toDoDone = state.toDoDone.concat(Array(numberOfContainer-state.toDoDone.length).fill(false));
    }
    if (state.billNote.length < numberOfContainer) {
      state.billNote = state.billNote.concat(Array(numberOfContainer-state.billNote.length).fill(""));
    }
    if (state.apptNote.length < numberOfContainer) {
      state.apptNote = state.apptNote.concat(Array(numberOfContainer-state.apptNote.length).fill(""));
    }
    if (state.freeChassis.length < numberOfContainer) {
      state.freeChassis = state.freeChassis.concat(Array(numberOfContainer-state.freeChassis.length).fill(false));
    }
    if (state.apptDone.length < numberOfContainer) {
      state.apptDone = state.apptDone.concat(Array(numberOfContainer-state.apptDone.length).fill(false));
    }
    if (state.apptReq.length < numberOfContainer) {
      state.apptReq = state.apptReq.concat(Array(numberOfContainer-state.apptReq.length).fill(false));
    }
    if (state.hold.length < numberOfContainer) {
      state.hold = state.hold.concat(Array(numberOfContainer-state.hold.length).fill(false));
    }
    if (state.emptyReadyDate.length < numberOfContainer) {
      state.emptyReadyDate = state.emptyReadyDate.concat(Array(numberOfContainer-state.emptyReadyDate.length).fill(""));
    }
    if (state.selected === undefined || state.selected.length !== numberOfContainer) {
      state.selected = Array(numberOfContainer).fill(true);
    }
  }
  const actionIndex = action.index;

  switch (action.type) {
    case "SET_CONTAINER_INFO":
      return {
        number: action.containerNumber,
        containerRefernce: action.containerRefernce,
        containerNumber: action.containerNumber,
        chassisInfo: action.chassisInfo,
        containerType: action.containerType,
        eta: action.eta,
        pickUpDate: action.pickUpDate,
        deliveryDate: action.deliveryDate,
        lastFreeDay: action.lastFreeDay,
        toDoDay: action.toDoDay,
        toDoDone: action.toDoDone,
        returnDate: action.returnDate,
        pickUpNumber: action.pickUpNumber,
        apptDate: action.apptDate,
        apptTime: action.apptTime,
        driver: action.driver,
        sealNumber: action.sealNumber,
        outSource: action.outSource,
        overWeight: action.overWeight,
        prePull: action.prePull,
        storage: action.storage,
        pickupDriver: action.pickupDriver,
        returnDriver: action.returnDriver,
        cLocation: action.cLocation,
        storageDay: action.storageDay,
        chassisDay: action.chassisDay,
        bobTail: action.bobTail,
        chassisSplit: action.chassisSplit,
        chassisSplitFee: action.chassisSplitFee,
        containerComment: action.containerComment,
        billNote: action.billNote,
        apptNote: action.apptNote,
        freeChassis: action.freeChassis,
        apptDone: action.apptDone,
        apptReq: action.apptReq,
        hold: action.hold,
        emptyReadyDate: action.emptyReadyDate,
        selected: action.selected,
      };
    case "UPDATE_CONTAINER_INFO":
      const containerInfo = action.containerInfo;
      const field = action.containerField;

      return {
        ...state,
        [field]: state[field].map((item, index) => {
          // Replace the item at index
          if (index === actionIndex) {
            if (field === "overWeight" || field === "prePull" || field === "storage" ||
                  field === "chassisSplit" || field === "bobTail" ||  field === "freeChassis" || field === "apptDone" ||  field === "apptReq" ||
                  field === "hold" || field === "selected"){
              return getBoolValue(containerInfo);
            }else if (field === "apptNote" || field === "billNote" || field === "containerComment"){
              return getCommentValue(containerInfo);
            }else {
              return getValue(containerInfo);
            }
          }
          // Leave every other item unchanged
          return item;
        })
      };

    case "UPDATE_CONTAINER":
      const container = action.container;
      return {
        ...state,
        number: state.containerNumber.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.number);
          }
          return item;
        }),
        containerRefernce: state.containerRefernce.map((item, index) => {
          if (index === actionIndex) {
            return getNotEmptyValue(container.containerRefernce);
          }
          return item;
        }),
        containerNumber: state.containerNumber.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.number);
          }
          return item;
        }),
        chassisInfo: state.chassisInfo.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.chassisInfo);
          }
          return item;
        }),
        containerType: state.containerType.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.containerType);
          }
          return item;
        }),
        eta: state.eta.map((item, index) => {
          if (index === actionIndex) {
            return getDateValue(container.eta);
          }
          return item;
        }),
        pickUpDate: state.pickUpDate.map((item, index) => {
          if (index === actionIndex) {
            return getDateValue(container.pickUpDate);
          }
          return item;
        }),
        deliveryDate: state.deliveryDate.map((item, index) => {
          if (index === actionIndex) {
            return getDateValue(container.deliveryDate);
          }
          return item;
        }),
        lastFreeDay: state.lastFreeDay.map((item, index) => {
          if (index === actionIndex) {
            return getDateValue(container.lastFreeDay);
          }
          return item;
        }),
        toDoDay: state.toDoDay.map((item, index) => {
          if (index === actionIndex) {
            return getBoolValue(container.toDoDay);
          }
          return item;
        }),
        toDoDone: state.toDoDone.map((item, index) => {
          if (index === actionIndex) {
            return getBoolValue(container.toDoDone);
          }
          return item;
        }),
        returnDate: state.returnDate.map((item, index) => {
          if (index === actionIndex) {
            return getDateValue(container.returnDate);
          }
          return item;
        }),
        pickUpNumber: state.pickUpNumber.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.pickUpNumber);
          }
          return item;
        }),
        apptDate: state.apptDate.map((item, index) => {
          if (index === actionIndex) {
            return getDateValue(container.apptDate);
          }
          return item;
        }),
        apptTime: state.apptTime.map((item, index) => {
          if (index === actionIndex) {
            return ""
            //return getNotEmptyValue(container.apptTime);
          }
          return item;
        }),
        driver: state.driver.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.driver);
          }
          return item;
        }),
        sealNumber: state.sealNumber.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.sealNumber);
          }
          return item;
        }),
        outSource: state.outSource.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.outSource);
          }
          return item;
        }),
        overWeight: state.overWeight.map((item, index) => {
          if (index === actionIndex) {
            return getBoolValue(container.overWeight);
          }
          return item;
        }),
        prePull: state.prePull.map((item, index) => {
          if (index === actionIndex) {
            return getBoolValue(container.prePull);
          }
          return item;
        }),
        storage: state.storage.map((item, index) => {
          if (index === actionIndex) {
            return getBoolValue(container.storage);
          }
          return item;
        }),
        pickupDriver: state.pickupDriver.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.pickupDriver);
          }
          return item;
        }),
        returnDriver: state.returnDriver.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.returnDriver);
          }
          return item;
        }),
        cLocation: state.cLocation.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.cLocation);
          }
          return item;
        }),
        storageDay: state.storageDay.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.storageDay);
          }
          return item;
        }),
        chassisDay: state.chassisDay.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.chassisDay);
          }
          return item;
        }),
        bobTail: state.bobTail.map((item, index) => {
          if (index === actionIndex) {
            return getBoolValue(container.bobTail);
          }
          return item;
        }),
        chassisSplit: state.chassisSplit.map((item, index) => {
          if (index === actionIndex) {
            return getBoolValue(container.chassisSplit);
          }
          return item;
        }),
        chassisSplitFee: state.chassisSplitFee.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.chassisSplitFee);
          }
          return item;
        }),
        containerComment: state.containerComment.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.containerComment);
          }
          return item;
        }),
        billNote: state.billNote.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.billNote);
          }
          return item;
        }),
        apptNote: state.apptNote.map((item, index) => {
          if (index === actionIndex) {
            return getValue(container.apptNote);
          }
          return item;
        }),
        freeChassis: state.freeChassis.map((item, index) => {
          if (index === actionIndex) {
            return getBoolValue(container.freeChassis);
          }
          return item;
        }),
        apptDone: state.apptDone.map((item, index) => {
          if (index === actionIndex) {
            return getBoolValue(container.apptDone);
          }
          return item;
        }),
        apptReq: state.apptReq.map((item, index) => {
          if (index === actionIndex) {
            return getBoolValue(container.apptReq);
          }
          return item;
        }),
        hold: state.hold.map((item, index) => {
          if (index === actionIndex) {
            return getBoolValue(container.hold);
          }
          return item;
        }),
        emptyReadyDate: state.emptyReadyDate.map((item, index) => {
          if (index === actionIndex) {
            return getDateValue(container.emptyReadyDate);
          }
          return item;
        }),
      };

    case "ADD_NEW_CONTAINER":
      //const containerNewInfo = action.containerInfo;
      //const containerField = action.containerField;
      if (numberOfContainer !== 0) {
        return {
          ...state,
          number: [...state.containerNumber, action.containerNumber],
          containerRefernce: [...state.containerRefernce, action.containerRefernce],
          containerNumber: [...state.containerNumber, action.containerNumber],
          chassisInfo: [...state.chassisInfo, action.chassisInfo],
          containerType: [...state.containerType, action.containerType],
          eta: [...state.eta, action.eta],
          pickUpDate: [...state.pickUpDate, action.pickUpDate],
          deliveryDate: [...state.deliveryDate, action.deliveryDate],
          lastFreeDay: [...state.lastFreeDay, action.lastFreeDay],
          toDoDay: [...state.toDoDay, action.toDoDay],
          toDoDone: [...state.toDoDone, action.toDoDone],
          returnDate: [...state.returnDate, action.returnDate],
          pickUpNumber: [...state.pickUpNumber, action.pickUpNumber],
          apptDate: [...state.apptDate, action.apptDate],
          apptTime: [...state.apptTime, action.apptTime],
          driver: [...state.driver, action.driver],
          sealNumber: [...state.sealNumber, action.sealNumber],
          outSource: [...state.outSource, action.outSource],
          overWeight: [...state.overWeight, action.overWeight],
          prePull: [...state.prePull, action.prePull],
          storage: [...state.storage, action.storage],
          pickupDriver: [...state.pickupDriver, action.pickupDriver],
          returnDriver: [...state.returnDriver, action.returnDriver],
          cLocation: [...state.cLocation, action.cLocation],
          storageDay: [...state.storageDay, action.storageDay],
          chassisDay: [...state.chassisDay, action.chassisDay],
          bobTail: [...state.bobTail, action.bobTail],
          chassisSplit: [...state.chassisSplit, action.chassisSplit],
          chassisSplitFee: [...state.chassisSplitFee, action.chassisSplitFee],
          containerComment: [...state.containerComment, action.containerComment],
          billNote: [...state.billNote, action.billNote],
          apptNote: [...state.apptNote, action.apptNote],
          freeChassis: [...state.freeChassis, action.freeChassis],
          apptDone: [...state.apptDone, action.apptDone],
          apptReq: [...state.apptReq, action.apptReq],
          hold: [...state.hold, action.hold],
          emptyReadyDate: [...state.emptyReadyDate, action.emptyReadyDate],
          selected: [...state.selected, action.selected]
        };
      } else {
        return {
          number: [action.containerNumber],
          containerRefernce: [action.containerRefernce],
          containerNumber: [action.containerNumber],
          chassisInfo: [action.chassisInfo],
          containerType: [action.containerType],
          eta: [action.eta],
          pickUpDate: [action.pickUpDate],
          deliveryDate: [action.deliveryDate],
          lastFreeDay: [action.lastFreeDay],
          toDoDay: [action.toDoDay],
          toDoDone: [action.toDoDone],
          returnDate: [action.returnDate],
          pickUpNumber: [action.pickUpNumber],
          apptDate: [action.apptDate],
          apptTime: [action.apptTime],
          driver: [action.driver],
          sealNumber: [action.sealNumber],
          outSource: [action.outSource],
          overWeight: [action.overWeight],
          prePull: [action.prePull],
          storage: [action.storage],
          pickupDriver: [action.pickupDriver],
          returnDriver: [action.returnDriver],
          cLocation: [action.cLocation],
          storageDay: [action.storageDay],
          chassisDay: [action.chassisDay],
          bobTail: [action.bobTail],
          chassisSplit: [action.chassisSplit],
          chassisSplitFee: [action.chassisSplitFee],
          containerComment: [action.containerComment],
          billNote: [action.billNote],
          apptNote: [action.apptNote],
          freeChassis: [action.freeChassis],
          apptDone: [action.apptDone],
          apptReq: [action.apptReq],
          hold: [action.hold],
          emptyReadyDate: [action.emptyReadyDate],
          selected: [action.selected]
        };
      }

    case "CONTAINER_RESET":
      return {
        number: [],
        containerRefernce: [],
        containerNumber: [],
        chassisInfo: [],
        containerType: [],
        eta: [],
        pickUpDate: [],
        deliveryDate: [],
        lastFreeDay: [],
        toDoDay: [],
        toDoDone: [],
        returnDate: [],
        pickUpNumber: [],
        apptDate: [],
        apptTime: [],
        driver: [],
        sealNumber: [],
        outSource: [],
        overWeight: [],
        prePull: [],
        storage: [],
        pickupDriver: [],
        returnDriver: [],
        cLocation: [],
        storageDay: [],
        chassisDay: [],
        bobTail: [],
        chassisSplit: [],
        chassisSplitFee: [],
        containerComment: [],
        billNote: [],
        apptNote: [],
        freeChassis: [],
        apptDone: [],
        apptReq: [],
        hold: [],
        emptyReadyDate: [],
        selected: []
      };
    default:
      return state;
  }
}

function getValue(value){
  return (value !== undefined && value !== null) ? value.replace(/^\s+|\s+$/gm,'') : "";
}

function getCommentValue(value){
  return (value !== undefined && value !== null) ? value : "";
}

function getNotEmptyValue(value){
  return (value !== undefined && value !== null) ? value : "";
}

function getDateValue(value){
  if (value !== undefined && value !== null){
    let dateValue = value.toDate();
    return dateValue.getFullYear() + "-" + (dateValue.getMonth() + 1) + "-" + dateValue.getDate();;
  }
  return "";
}

function getBoolValue(value){
  return (value !== undefined && value !== null) ? value : false;
}

function ContainerProvider(props) {
  let [container, dispatchContainer] = React.useReducer(reducer, initialState);
  let value = { container, dispatchContainer };

  return <ContainerContext.Provider value={value}>{props.children}</ContainerContext.Provider>;
}

let ContainerConsumer = ContainerContext.Consumer;

export { ContainerContext, ContainerProvider, ContainerConsumer };
