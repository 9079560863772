import React from 'react';
//material ui
import { withStyles, Container, Grid, Typography,
  createTheme, responsiveFontSizes, ThemeProvider } from "@material-ui/core";

//HOC CSS
import styles from './styles';

function Landing(props) {
  const { classes } = props;
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl">
          <Grid item xs={12} className={classes.gretting}>
            <Typography component="h1" variant="h2" className={classes.grettingText}>
              Hello, we are
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.companyName}>
            <Typography component="h1" variant="h2" className={classes.companyNameText}>
              WorldWide Solutions International
            </Typography>
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default withStyles(styles)(Landing);
