const styles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
  logo: {
    width: '100%',
  },
  logoIcon: {
    width: '10%',
  },
  headerBar: {
    paddingRight: '50px',
  },
  appbar: {
    alignItems: 'center',
  },
  navHeader: {
    marginTop: '40px',
  },
  header: {
    borderBottom: '1px solid #FFFFFF',
  },
  title: {
    flexGrow: "1",
  },
  headerLine: {
    height: '5px',
    borderTop: '1px solid #FFFFFF',
  },
  headerLineSignup: {
    height: '5px',
    borderTop: '1px solid #FFFFFF',
    marginTop: '20px',
  },
  gretting: {
    marginTop: '100px',
    marginLeft: '48px',
  },
  grettingText: {
    color: '#0070C0',
    fontWeight: 'bold'
  },
  signupText: {
    paddingTop: '40px',
    paddingLeft: '20px',
    color: '#0070C0',
    fontWeight: '700',
  },
  viewSelector: {
    width: '70px !important',
  },
  navLink: {
    textDecoration: 'none',
  },
  navLinkText: {
    fontWeight: '700',
    marginLeft: '10px',
    paddingRight: '20px',
    [theme.breakpoints.up('md')]: {
      marginLeft: '20px',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '20px',
    },
  },

  button: {
    margin: theme.spacing(1),
    background: '#bab6b6',
    color: '#FFFFFF',
    fontWeight: '700',
    marginTop: '40px',
  },
  logInButtonDiv: {
    marginTop: '64px',
  },
  contactButtonDiv: {
    marginTop: '64px',
  },
  link: {
    textDecoration: 'none',
  },
  listItem1:{
    backgroundColor: "#bab6b6",
  },
  listItemText_crossOut:{
    width:"60%",
    maxWidth: "800px !important",
    textDecoration: "line-through"
  },
  listItemText:{
    width:"60%",
    maxWidth: "800px !important",
  },
  listHeaderText:{
    width:"80%",
    maxWidth: "800px !important",
  },
  listItemText_GREEN:{
    backgroundColor: "#a0dba9 !important",
  },
  listItemText_YELLOW:{
    backgroundColor: "#7F6000 !important",
  },
  listItemText_HIGHLIGHT:{
    backgroundColor: "#e7f525 !important",
  },
  listItemText_BLUE:{
    backgroundColor: "#74baf7 !important",
  },
  listItemText_ORANGE:{
    backgroundColor: "#FF8300 !important",
  },
  listItemText_WHITE:{
    color : "white"
  },
  listItemText_BERGENDY:{
      color : "#8B0000"
  },
  listItemText_B:{
    width:"60%",
    maxWidth: "800px !important",
    "& .MuiListItemText-primary": {
      fontWeight: "bold !important",
      color : "#8B0000 !important"
    }
  },
  listItemText2: {
    width:"20%",
    maxWidth: "300px !important",
    textAlign: 'right',
  },
  listItemText1: {
    width:"20%",
    maxWidth: "300px !important",
    textAlign: 'left',
    marginLeft: "20px",
  },
});

export default styles;
