import React, { useContext, useEffect } from "react";
//HOC CSS
import styles from "./styles";
// reach router
import { navigate } from "@reach/router";

//material ui
import { withStyles, createTheme, responsiveFontSizes,
  ThemeProvider,Select, Button, Dialog,
  DialogActions, DialogContent, DialogContentText,
  DialogTitle, Typography, AppBar, Toolbar} from "@material-ui/core";

//Logo
import logo from "../../assets/img/logo/final2.png";
//Reach Router
import { Router, Link } from "@reach/router";
//current user context hook
import { CurrentUserContext } from "../../contexts/currentUserProvider";
import { ResultFieldSettingContext } from "../../contexts/resultFieldSettingProvider";
import { SearchResultContext } from "../../contexts/searchResultProvider";

//Firebase
import { signOut } from "../../firebase";

import Helper from '../helper';

function LandingHeader(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <ThemeProvider theme={props.theme}>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <img src={logo} alt="logo" className={classes.logoIcon} />
            <Typography variant="h5" className={classes.title}>
              Worldwide Solutions International
            </Typography>

            <NavLink to="/login" className={classes.navLink}>
              <Typography variant="h6" className={classes.navLinkText}>
                Log In
              </Typography>
            </NavLink>

          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </div>
  );
}

function SignupHeader(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <ThemeProvider theme={props.theme}>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <img src={logo} alt="logo" className={classes.logoIcon} />
            <Typography variant="h5" className={classes.title}>
              Worldwide Solutions International - Sign Up
            </Typography>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </div>
  );
}

function LoginHeader(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <ThemeProvider theme={props.theme}>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <img src={logo} alt="logo" className={classes.logoIcon} />
            <Typography variant="h5" className={classes.title}>
              Worldwide Solutions International
            </Typography>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </div>
  );
}

function NoHeaderHeader(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <ThemeProvider theme={props.theme}>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <img src={logo} alt="logo" className={classes.logoIcon} />
            <Typography variant="h5" className={classes.title}>
              Worldwide Solutions International - 404
            </Typography>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </div>
  );
}


const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        style: {
          color: isCurrent ? "#0070C0" : "black"
        }
      };
    }}
  />
);



function DashboardHeader(props) {
  const { classes } = props;
  const currentUser = useContext(CurrentUserContext);
  const { resultFieldSetting, dispatchResultFieldSetting } = useContext(ResultFieldSettingContext);
  const { dispatchSearchResult } = useContext(SearchResultContext);

  const [open, setOpen] = React.useState(false);
  const [currentBranch, setCurrentBranch] = React.useState(resultFieldSetting.view);

  let field = "view";
  let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    let value = (currentBranch === "NY" ? "NY" : "CHI");
    let resultFieldObj = { [`${field}`]: value, cid: true };
    const email = currentUser.email;

    Helper.handleUpdate('resultFields', `${email}`, resultFieldObj,
      function () {
        setOpen(false);
        dispatchResultFieldSetting({ type: "UPDATE_RESULTFIELD", resultField: field, resultFieldInfo: value });

        let path = window.location.pathname;
        if (path.indexOf("/planning") !== -1 ){
          let new_branchLabel = currentBranch === "CHI" ? "_chi" : "_ny";
          path = path.replace(branchLabel, new_branchLabel);
        }else if (path.indexOf("/operation") !== -1 ){
          path = "/operation";
        }else if (path.indexOf("/report") !== -1){
          dispatchSearchResult({
            type: "CLEAR_SEARCH_RESULT"
          });
        }
        navigate(path);
      },
      function (dbErr) {
        // console.log("Failed to update the fields: ", dbErr);
        // setServerMessage('Failed To fields');
      });

  };

  useEffect(() => {
    if (currentUser) {
      const email = currentUser.email;

      Helper.handleFetchByReference('resultFields', `${email}`,
        function (doc) {
          if (!doc.exists) {
            console.log("No matching document::resultFields");
          } else {
            let value = (doc.data().view === "NY" ? "NY" : "CHI");
            dispatchResultFieldSetting({ type: "UPDATE_RESULTFIELD", resultField: field, resultFieldInfo: value });
          }
        },
        function (dbErr) {
        });

      return () => {
      }
    }
  }, [currentUser, dispatchResultFieldSetting, field]);

  return (
    <div className={classes.root}>
      <ThemeProvider theme={props.theme}>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <img src={logo} alt="logo" className={classes.logoIcon} />
            <Typography variant="h5" className={classes.title}>
              Worldwide Solutions International
            </Typography>

            <NavLink to="#" onClick={signOut} className={classes.navLink}>
              <Typography variant="h6" className={classes.navLinkText}>
                LogOut
              </Typography>
            </NavLink>

            <Typography variant="subtitle1" className={classes.navLinkText}>
              <Select
                native
                className={classes.navLinkText}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={resultFieldSetting.view}
                onChange={(e) => {
                  setOpen(true);
                  setCurrentBranch(e.target.value);
                 }}
              >
                {(currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role === "member1") ?
                  <option value={"NY"}>NY</option> : undefined}
                {(currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role === "member1") ?
                  <option value={"CHI"}>CHI</option> : undefined}
              </Select>
            </Typography>

            {(currentUser.role === "admin" || currentUser.role === "manager") && (
              <NavLink to="/admin" onClick={e => { currentUser.path = "/admin" }} className={classes.navLink}>
                <Typography variant="h6" className={classes.navLinkText}>
                  Admin
                </Typography>
              </NavLink>
            )}

            <NavLink to="/user" onClick={e => { currentUser.path = "/user" }} className={classes.navLink} >
              <Typography variant="h6" className={classes.navLinkText}>
                User
              </Typography>
            </NavLink>

            {currentUser.status === "active" && (
              <NavLink to="/operation" onClick={e => { currentUser.path = "/operation" }} className={classes.navLink}>
                <Typography variant="h6" className={classes.navLinkText}>
                  Operation
                </Typography>
              </NavLink>
            )}

            {currentUser.status === "active" && (currentUser.role === "admin" || currentUser.role === "manager" || currentUser.role.indexOf("member1") !== -1) && (
              <NavLink to={"/planning/todo" + branchLabel} onClick={e => { currentUser.path = "/planning/todo" }} className={classes.navLink}>
                <Typography variant="h6" className={classes.navLinkText}>
                  Planning
                </Typography>
              </NavLink>
            )}

            {currentUser.status === "active" && (
              <NavLink to="/report" onClick={e => { currentUser.path = "/report" }} className={classes.navLink}>
                <Typography variant="h6" className={classes.navLinkText}>
                  Report
                </Typography>
              </NavLink>
            )}
          </Toolbar>

          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">WARNING !!!</DialogTitle>
            <DialogContent>
              <DialogContentText>Switching branch will discard all your change. Are you sure?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleChange}>OK</Button>
              <Button onClick={handleClose}>CANCEL</Button>
            </DialogActions>
          </Dialog>

        </AppBar>
      </ThemeProvider>
    </div>
  );
}

export function Header(props) {
  const { classes } = props;
  const currentUser = useContext(CurrentUserContext);
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  return (
    <div>
      {currentUser ? (
        <DashboardHeader path="/" classes={classes} theme={theme} />
      ) : (
        <Router >
          <LandingHeader path="/" classes={classes} theme={theme} />
          <SignupHeader path="/signup" classes={classes} theme={theme} />
          <LoginHeader path="/login" classes={classes} theme={theme} />
          <LoginHeader path="/dashboard" classes={classes} theme={theme} />
          <NoHeaderHeader path="*" classes={classes} theme={theme} />
        </Router>
      )}
    </div>
  );
}

export default withStyles(styles)(Header);
