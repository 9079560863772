import React, { useState, useContext, useEffect, lazy, Suspense } from "react";

// reach router

//HOC CSS
import styles from "./styles";
import {
  withStyles, Container, Modal, CircularProgress,
  Backdrop, ImageList, ImageListItem
} from '@material-ui/core';

//firebase
import { firestore } from "../../firebase";

//calender
import { momentLocalizer } from 'react-big-calendar';

import {
  getTimeAsDate
} from './utils/dataUtils';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment-timezone'
import Month2 from './month2'
import Day2 from './day2'
import Day3 from './day3'

import { ResultFieldSettingContext } from "../../contexts/resultFieldSettingProvider";
import { PLANITEM_APP_CONTEXT } from "../../contexts/planItem_app_Provider";
import { PLANITEM_HISTORY_CONTEXT } from "../../contexts/planItem_history_Provider";
import { PLANITEM_APP_CONTEXT_CHI } from "../../contexts/planItem_app_Provider_chi";
import { PLANITEM_HISTORY_CONTEXT_CHI } from "../../contexts/planItem_history_Provider_chi";

const RoutingCalenderHeaderLayout = lazy(() => import('./routingCalenderHeaderLayout'));
const Calendar = lazy(() => import('./calender2'));
const DisplayForm = lazy(() => import('../displayForm/displayForm'));


function RoutingCalender(props) {
  // classes porps for styling
  const { classes } = props;
  const { resultFieldSetting } = useContext(ResultFieldSettingContext);
  let isCHI = resultFieldSetting.view === "CHI";
  const app_planItems = useContext(props.isHistory ?
    (isCHI ? PLANITEM_HISTORY_CONTEXT_CHI : PLANITEM_HISTORY_CONTEXT) : (isCHI ? PLANITEM_APP_CONTEXT_CHI : PLANITEM_APP_CONTEXT));
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [formType, setFormType] = useState();
  const [addressObject, setAddressObject] = useState();
  const [collectionItem, setCollectionItem] = useState();
  const [collectionItemIndex, setCollectionItemIndex] = useState();
  const [collectionItemType, setCollectionItemType] = useState();
  const [collectionItemShortDetail, setCollectionItemShortDetail] = useState();

  const [openBusy, setOpenBusy] = React.useState(false);
  //moment.tz.setDefault(isCHI ? "America/Chicago" : "Etc/GMT+5");
  //moment.tz.setDefault("Etc/UTC");
  const localizer = momentLocalizer(moment) // or globalizeLocalizer
  localizer.formats.yearHeaderFormat = 'YYYY'
  localizer.formats.eventTimeRangeFormat = localizer.formats.eventTimeRangeStartFormat;

  const handleClose = () => {
    setOpenBusy(false);
  };

  const handleToggle = () => {
    setOpenBusy(!openBusy);
  };

  async function fetchOrder(routingContainerState, ref, itemIndex, shortDetail) {
    handleToggle();
    let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
    await firestore
      .collection("deliverOrders" + branchLabel)
      .doc(ref)
      .get()
      .then(deliveryOrderSnapshot => {
        if (deliveryOrderSnapshot.exists) {
          var deliverOrderDoc = deliveryOrderSnapshot.data();

          const numberOfContainer = deliverOrderDoc.c_Ref.length;
          if (deliverOrderDoc.c_apptReq === undefined) {
            deliverOrderDoc.c_apptReq = Array(numberOfContainer).fill(false);
          } else if (deliverOrderDoc.c_apptReq.length < numberOfContainer) {
            deliverOrderDoc.c_apptReq = deliverOrderDoc.c_apptReq.concat(Array(numberOfContainer - deliverOrderDoc.c_apptReq.length).fill(false));
          }

          if (deliverOrderDoc.c_hold === undefined) {
            deliverOrderDoc.c_hold = Array(numberOfContainer).fill(false);
          } else if (deliverOrderDoc.c_hold.length < numberOfContainer) {
            deliverOrderDoc.c_hold = deliverOrderDoc.c_hold.concat(Array(numberOfContainer - deliverOrderDoc.c_hold.length).fill(false));
          }

          deliverOrderDoc.ref = ref;
          switch (routingContainerState) {
            case 'a':
            case 'r':
              handleClose();
              setFormType("editDeiveryOrder");
              setCollectionItem(deliverOrderDoc);
              setCollectionItemIndex(itemIndex);
              setCollectionItemType(routingContainerState);
              setCollectionItemShortDetail(shortDetail);
              setOpen(true);
              break;
            default:
              break;
          }
        }
        handleClose();
      })
      .catch(err => {
        console.log("Error getting deliverOrders", err);
        handleClose();
      });
  };

  async function fetchDriver(driverCode) {
    handleToggle();
    let branchLabel = resultFieldSetting.view === "CHI" ? "_chi" : "_ny";
    await firestore
      .collection(`drivers` + branchLabel)
      .where("name", "==", driverCode).limit(1)
      .get()
      .then(dirverSnapshot => {
        if (!dirverSnapshot.empty) {
          setFormType("editAddressFromRouting");
          let obj = dirverSnapshot.docs[0].data();
          obj.id = dirverSnapshot.docs[0].id;
          setAddressObject(obj);
          setOpen(true);
        }
        handleClose();
      })
      .catch(err => {
        console.log("Error getting deliverOrders", err);
        handleClose();
      });
  };

  const userBranch = resultFieldSetting.view;

  const list = app_planItems !== undefined ? app_planItems.filter(function (obj) {
    let titleList = obj.shortDetail.split("-");
    let isCHI = titleList[0].indexOf("CHI") !== -1;
    return obj.invoiced !== true && (userBranch === "CHI" ? isCHI : !isCHI);
  }) : [];

  const [driverList, setDriverList] = useState([]);

  useEffect(() => {
    let branchLabel = userBranch === "CHI" ? "_chi" : "_ny";
    firestore.collection(`drivers` + branchLabel).get().then((querySnapshot) => {
      var infoMap = new Map();
      querySnapshot.forEach((doc) => {
        let obj = doc.data();
        if (obj.addressHide !== true) {
          infoMap.set(obj.name, { id: doc.id, branch: obj.branch, isOwn: obj.isOwn, isOutSource: obj.isOutSource, dateRow: obj.dateRow });
        }

      });
      setDriverList(infoMap);
    })
  }, [userBranch]);

  let resourceMap = [];
  let isTodayDST = moment(new Date(Date.now())).isDST();

  let containerObjs = list !== undefined ? list.map(item => {
    let titleList = item.shortDetail.split("-");
    let titleB4State = item.shortDetail.split(",");
    let refNumber = titleList[0];
    let address = titleList[1];
    let idWCity = refNumber + "-" + address;
    let containerNumber = item.number;
    if (idWCity !== titleB4State[0]) {
      address = item.shortDetail.substring(item.shortDetail.indexOf("-") + 1, item.shortDetail.indexOf(",") + 3)
    }
    let start = props.isHistory ? item.deliveryDate.toDate() : item.apptDate.toDate();
    let startTimeZoneString = start.toLocaleString("en-US", { timeZone: userBranch === "CHI" ? "America/Chicago" : "America/New_York" });
    let startTimeZone = new Date(startTimeZoneString);
    if (!isTodayDST && moment(startTimeZone).isDST()) {
      // move back to respect the timezone in view
      // comment out if we are not DST and looking forward
     // startTimeZone.setHours(startTimeZone.getHours() - 1);
    } else if (isTodayDST && !moment(startTimeZone).isDST()) {
      // move back to respect the timezone in view
      // comment out if we are not DST and looking forward
      //startTimeZone.setHours(startTimeZone.getHours() + 1);
    }

    let resourceId = "Unassigned";
    if (item.driver !== undefined && item.driver !== "") {
      if (driverList.length !== 0 && driverList.get(item.driver) === undefined) {
        return { id: item.id, title: (`[BAD DRIVER NAME :: ${item.driver}]${refNumber}(${containerNumber})- ${address}`), start: start, end: start, allDay: true, resourceId: resourceId, data: item };
      }
      resourceId = item.driver;
    }
    return { id: item.id, title: (`${refNumber}(${containerNumber})- ${address}`), start: startTimeZone, end: startTimeZone, allDay: true, resourceId: resourceId, data: item };
  }) : [];

  resourceMap = [];
  driverList.forEach((item, key) => {
    if (item && item.dateRow !== undefined) {
      let dateRowSet = item.dateRow;
      dateRowSet.forEach(timeOffDate => {

        if (timeOffDate.hide !== true) {
          let startTimeZoneString = timeOffDate.dateFrom.toDate().toLocaleDateString("en-US", { timeZone: userBranch === "CHI" ? "America/Chicago" : "America/New_York" });

          let endTimeZoneString = timeOffDate.dateTo.toDate().toLocaleDateString("en-US", { timeZone: userBranch === "CHI" ? "America/Chicago" : "America/New_York" });

          let startTimeZone = new Date(startTimeZoneString);
          let endTimeZone = new Date(endTimeZoneString);

          containerObjs.push({ id: item.id.concat("_", timeOffDate.id), title: (`Vacation from ${timeOffDate.dateFrom.toDate().toLocaleDateString()} to ${timeOffDate.dateTo.toDate().toLocaleDateString()}`), start: startTimeZone, end: endTimeZone, resourceId: key, data: item.dateRow })
        }
      })
    }
    if (!item.addressHide) {
      let isCHI = item.branch === "CHI";
      //we can remove this later
      if (userBranch === "CHI" ? isCHI : !isCHI) {
        resourceMap.push({ resourceId: key, alwaysShow: true, id: key, name: key, dateRow: item.dateRow, isOwn: item.isOwn, isOutSource: item.isOutSource });
      }
    }
  });


  let driverIsOwn = resourceMap.filter(function (obj) {
    return obj.isOwn === true && obj.isOutSource !== true;
  });

  let driverHouse = resourceMap.filter(function (obj) {
    return obj.isOwn !== true && obj.isOutSource !== true;
  });

  let driverOutSource = resourceMap.filter(function (obj) {
    return obj.isOutSource === true;
  });

  driverIsOwn
    .sort(
      (a, b) => a.resourceId.localeCompare(b.resourceId)
    )

  driverHouse
    .sort(
      (a, b) => a.resourceId.localeCompare(b.resourceId)
    )

  driverOutSource
    .sort(
      (a, b) => a.resourceId.localeCompare(b.resourceId)
    )

  containerObjs.sort(
    (a, b) => a.title.localeCompare(b.title) && (a.start > b.start)
  )

  resourceMap = [{ resourceId: "Unassigned", alwaysShow: true, id: 'Unassigned', name: "Unassigned" }].concat(driverHouse).concat(driverIsOwn).concat(driverOutSource);

  const handleSelect = ({ start, end, data, title, resourceId }) => {
    if (title && title.startsWith("Vacation")) {
      fetchDriver(resourceId);
    } else {
      fetchOrder("a", data.d_Ref, data.cIndex, data.shortDetail);
    }
  }

  const displayFormProps = {
    classes,
    formType,
    addressObject,
    setFormType,
    setAddressObject,
    setOpen,
    collectionItem,
    collectionItemIndex,
    collectionItemType,
    collectionItemShortDetail,
  };

  function EventLayout({ event }) {
    let timeString = event.start.toLocaleTimeString();
    let displayTime = timeString === "12:00:00 AM" ? "" : (timeString.replaceAll(":00 ", "") + " ");
    return (
      <span onClick={e => handleSelect(event, e)}>
        {displayTime}
        {event.title}
        {event.desc}
      </span>
    )
  }

  function EventStyleGetter(event, start, end, selected) {
    var style = {};
    style.borderColor = "black";
    style.color = "white";
    if (event && event.title.indexOf("Vacation") !== -1) {
      style.backgroundColor = "#889199";
    } else {
      if (event && event.data && event.data.apptDone) {
        style.backgroundColor = "#74baf7";
        if (event.data.hold) {
          style.backgroundColor = "#7F6000";
        }
      } else {
        if (event.data.hold) {
          style.backgroundColor = "#7F6000";
        }
        else if (event.data.apptReq) {
          style.backgroundColor = "#a0dba9";
        } else {
          style.backgroundColor = "#FF8300";
        }
      }
    }

    if (event && event.data && event.data.pickUpDate) {
      style.color = "#8B0000";
      style.fontWeight = "bold";
    }

    if (event && event.data && event.data.driver === "OUTSOURCE_SYSTEM"){
      style.color = "black";
      style.backgroundColor = "#e7f525";
    }

    return {
      style: style
    };
  }

  return (
    <div className={classes.root}>
      <Container maxWidth={false}>
        <ImageList className={classes.gridList} cols={1} rowHeight={600}>
          <ImageListItem className={classes.gridTile2}>
            <Suspense fallback={<div>Loading...</div>}>
              <div>
                <Calendar
                  selectable
                  localizer={localizer}
                  events={containerObjs}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 600 }}
                  views={{ month: Month2, day: Day3, week: Day2 }}
                  messages={{ year: 'Year', month: 'Month', week: 'Driver View' }}
                  onSelectEvent={(event, eventDataForScheduler) => {
                    if (eventDataForScheduler.type) {
                      // big-calender
                      handleSelect(event)
                    } else {
                      // big-scheduler
                      handleSelect(eventDataForScheduler)
                    }
                  }}
                  onSelectSlot={(slot) => {
                    if (slot.slotInfo) {
                      // big-scheduler
                      fetchDriver(slot.slotInfo.slotId)
                    }
                  }}
                  resources={resourceMap}
                  resourceIdAccessor="resourceId"
                  resourceTitleAccessor="resourceId"
                  eventPropGetter={EventStyleGetter}
                  components={{
                    day: {
                      event: EventLayout,
                    },
                    timeGutterHeader: RoutingCalenderHeaderLayout,
                    ResourceHeaderComponent: RoutingCalenderHeaderLayout
                  }}
                  min={getTimeAsDate(0, moment)}
                  max={getTimeAsDate(23, moment)}
                />
              </div>
            </Suspense>
          </ImageListItem>
        </ImageList>
      </Container>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <div style={modalStyle} className={classes.paper}>
            <DisplayForm {...displayFormProps} />
          </div>
        </Suspense>
      </Modal>

      <Backdrop className={classes.backdrop} open={openBusy} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default withStyles(styles)(RoutingCalender);

//Styles for modal
function getModalStyle() {
  return {
    backgroundColor: "#8db4e2",
    width: "80%",
    //right: "100%",

    position: "relative",
    left: "10%",
    top: "10%",
    transform: `translate(0%, 0%)`,
    outline: 0
  };
}
