const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
    marginTop: '40px',
  },
  container: {
    minHeight: '100vh',
  },
  label: {
    color: 'black',
    fontSize: '20px',
    fontWeight: '700',
    marginTop: '25px',
  },
  field: {
    borderRadius: '4px',
    marginTop: '25px',
  },
  button: {
    marginTop: '20px',
    fontWeight: '700',
    backgroundColor: '#bab6b6',
    color: '#0070C0',
  },
  signUpLink: {
    textDecoration: 'none',
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
  },
  field1: {
      border: '1px solid rgba(0,0,0,0.23)',
      borderRadius: '4px',
      width: '100%',
      marginTop: '25px',
      paddingLeft: '5px',
      display: 'inline-block',
      background: "#8db4e2",
    }
});

export default styles;
