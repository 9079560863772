import React, { Component, createContext } from "react";
import { firestore } from "../firebase";

export const PLANITEM_ETA_CONTEXT_CHI = createContext();

class PlanItemETAProvider_chi extends Component {
  state = { eta_planItems: undefined };

  unsubscribe = null;

  componentDidMount = () => {
    this.unsubscribe = firestore.collection("containers_chi")
    .where("state", "==", "e")
    .onSnapshot(snapshot => {
      const containerObjs = snapshot.docs.map(doc => {
        return { id: doc.id, routingContainerState: "e", ...doc.data()};
      });

      let notInvoicedList = containerObjs.filter(function (obj) {
        return obj.invoiced !== true;
      });

      notInvoicedList.sort(
        (a, b) => a.eta._compareTo(b.eta)
      );

      this.setState({ eta_planItems : notInvoicedList });
    });
  };

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  render() {
    const { eta_planItems } = this.state;
    const { children } = this.props;

    return <PLANITEM_ETA_CONTEXT_CHI.Provider value={eta_planItems}>{children}</PLANITEM_ETA_CONTEXT_CHI.Provider>;
  }
}

export default PlanItemETAProvider_chi;
