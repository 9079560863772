import React, { Component, createContext,  } from "react";
import { firestore } from "../firebase";

export const PLANITEM_TODO_CONTEXT = createContext();

class PlanItemToDoProvider extends Component {
  state = { todo_planItems: undefined };


  unsubscribe = null;

  componentDidMount = () => {
    const now = new Date();
    const today = new Date(now.getFullYear() + "/" + (now.getMonth() + 1) + "/" + now.getDate())
    let newStartDate = new Date();
    let newEndDate = new Date();
    newStartDate.setDate(today.getDate() - 2);
    newEndDate.setDate(today.getDate() + 1);
    newEndDate.setSeconds(newEndDate.getSeconds() - 10);

    this.unsubscribe = firestore.collection("containers_ny")
    .orderBy("toDoDay")
    .startAt(new Date(newStartDate))
    .endAt(new Date(newEndDate))
    .onSnapshot(snapshot => {
      const containerObjs = snapshot.docs.map(doc => {
        return { id: doc.id, routingContainerState: "todo", ...doc.data()};
      });

      let toDoList = containerObjs.filter(function (obj) {
        return obj.toDoDone !== true || obj.toDoDay.toDate() >=  today;
      });

      toDoList.sort(
        (a, b) => a.toDoDay._compareTo(b.toDoDay)
      );

      this.setState({ todo_planItems : toDoList });
    });
  };

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  render() {
    const { todo_planItems } = this.state;
    const { children } = this.props;

    return <PLANITEM_TODO_CONTEXT.Provider value={todo_planItems}>{children}</PLANITEM_TODO_CONTEXT.Provider>;
  }
}

export default PlanItemToDoProvider;
