import React from "react";

let initialState = {
  login: false
};

const CacheContext = React.createContext(null);

function reducer(state, action) {
  switch (action.type) {
    case "CLEAR_SEARCH_RESULT":
      return initialState;

      case "UPDATE_LOGIN":
        const login = action.login;

        return {
          ...state,
          login: login
        };
    default:
      return state;
  }
}

function CacheProvider(props) {
  let [cache, dispatchCache] = React.useReducer(reducer, initialState);
  let value = { cache, dispatchCache };

  return <CacheContext.Provider value={value}>{props.children}</CacheContext.Provider>;
}

let CacheConsumer = CacheContext.Consumer;

export { CacheContext, CacheProvider, CacheConsumer };
